import {createReducer} from "redux-act";
import {
	ISquad,
	fetchSquads,
	fetchSquadsSuccess,
	fetchSquadsFailed,
	IPlayer,
	fetchPlayersSuccess,
	fetchPlayerStatsSuccess,
	IPlayerDisplayStats,
	fetchNotificationBarSuccess,
	fetchPlayersPreseasonSuccess,
} from "modules/actions";
import {RequestState} from "modules/enums";
import {positionsByStringKey} from "modules/helpers";

export interface INotificationBar {
	isEnabled: boolean;
	message: string;
}

interface IJSON {
	squads: ISquad[];
	squadRequestState: RequestState;
	players: IPlayer[];
	playersPreseason: IPlayer[];
	playersStatus: Record<string, IPlayerDisplayStats>;
	notification_bar: INotificationBar;
}

const initial: IJSON = {
	squads: [],
	squadRequestState: RequestState.IDLE,
	players: [],
	playersPreseason: [],
	playersStatus: {},
	notification_bar: {
		isEnabled: false,
		message: "",
	},
};

export const jsons = createReducer({}, initial)
	.on(fetchSquads, (state) => ({
		...state,
		squadRequestState: RequestState.Requested,
	}))
	.on(fetchSquadsSuccess, (state, payload) => ({
		...state,
		squadRequestState: RequestState.Received,
		squads: payload.sort((a, b) => a.name.localeCompare(b.name)),
	}))
	.on(fetchSquadsFailed, (state) => ({
		...state,
		squadRequestState: RequestState.Received,
	}))
	.on(fetchPlayersSuccess, (state, payload) => {
		const players = payload.map((player) => {
			const playerPosStr = player.position;
			const position = positionsByStringKey[playerPosStr];
			return {
				...player,
				positions: [position],
			};
		});
		return {
			...state,
			players: players,
		};
	})
	.on(fetchPlayersPreseasonSuccess, (state, payload) => {
		const playersPreseason = payload.map((player) => {
			const playerPosStr = player.position;
			const position = positionsByStringKey[playerPosStr];
			return {
				...player,
				positions: [position],
			};
		});
		return {
			...state,
			playersPreseason: playersPreseason,
		};
	})
	.on(fetchPlayerStatsSuccess, (state, payload) => {
		return {
			...state,
			playersStatus: payload,
		};
	})
	.on(fetchNotificationBarSuccess, (state, payload) => ({
		...state,
		notification_bar: payload,
	}));
