export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
	League = "league",
}

export enum RequestState {
	IDLE,
	Requested,
	Received,
}

export enum PrivacyType {
	Private = "private",
	Public = "public",
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum SortType {
	Average = "avgPoints",
	Total = "totalPoints",
	Round = "roundPoints",
	Selected = "percentSelections",
	Score = "totalPoints",
	T = "T",
	TS = "TS",
	G = "G",
	FG = "FG",
	TA = "TA",
	LB = "LB",
	LBA = "LBA",
	TCK = "TCK",
	TB = "TB",
	MT = "MT",
	OFH = "OFH",
	OFG = "OFG",
	ER = "ER",
	FTF = "FTF",
	MG = "MG",
	KM = "KM",
	KD = "KD",
	PC = "PC",
	SB = "SB",
	SO = "SO",
	TOG = "TOG",
	FDO = "FDO",
	TO = "TO",
	SAI = "SAI",
	EFIG = "EFIG",
}

export enum LeagueStatus {
	Scheduled = "scheduled",
	Active = "playing",
	Complete = "complete",
	Cancelled = "cancelled",
}

export enum MatchStatus {
	Scheduled = "scheduled",
	Active = "playing",
	Complete = "complete",
}

export enum LeaguePrivacy {
	Public = "public",
	Private = "private",
}

export enum LeagueType {
	Overall = "overall",
	Regular = "regular",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Active = "playing",
	Complete = "complete",
}

export enum GenderType {
	MALE = "MALE",
	FEMALE = "FEMALE",
}

export enum PlayerPositions {
	HOK = 1,
	MID = 2,
	EDG = 3,
	HLF = 4,
	CTR = 5,
	WFB = 6,
}
