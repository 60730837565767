import {createReducer} from "redux-act";
import {
	markUserInfoAsUpdated,
	requestCurrentUser,
	requestCurrentUserFailed,
	requestCurrentUserSuccess,
	setGodMode,
	unMarkUserInfoAsUpdated,
	userAuthCodeSuccess,
	userClearError,
	resetUserRequestState,
	userDeactivate,
	userDeactivateFailed,
	userDeactivateSuccess,
	userInfoUpdate,
	userLogin,
	userLoginFailed,
	userLoginSuccess,
	userLogout,
	userLogoutFailed,
	userLogoutSuccess,
	userRegister,
	userRegisterFailed,
	userRegisterSuccess,
	userUpdate,
	userUpdateFailed,
	userUpdateSuccess,
	userPreregister,
	userPreregisterSuccess,
	userPreregisterFailed,
	userAuthCodeLogin,
	userAuthCodeFailed,
} from "modules/actions/user";
import {RequestState} from "modules/enums";
import {ApiError} from "modules/utils";
import {reactivateCurrentUserFailed} from "modules/actions";

export interface IUser {
	id: number;
	email: string;
	teamName: string;
	isNotificationsEnabled: boolean;
	createdAt: string;
	firstname: string;
	lastname: string;
	optIn: boolean;
	nrlTerms: boolean;
	terms: boolean;
	isRecovered?: boolean;
	recoveredAt?: string;
	seasonsPlayed?: number[];
}

export interface IUserReducer {
	user?: IUser;
	error?: string;
	requestState: RequestState;
	sessionCheckedState: RequestState;
	logoutWasCalled: boolean;
	passwordUpdated: boolean;
	userInfoUpdated: boolean;
	passwordReset: {
		requestState: RequestState;
		error?: string;
	};
	godMode?: boolean;
	preregisterSuccess?: boolean;
}

const isGodModeActive = localStorage.getItem("GOD_MODE") === "true";

const defaultUser: IUser = {
	id: 0,
	email: "",
	teamName: "",
	isNotificationsEnabled: false,
	createdAt: "",
	firstname: "",
	lastname: "",
	optIn: false,
	nrlTerms: false,
	terms: false,
	isRecovered: false,
	recoveredAt: "",
	seasonsPlayed: [],
};

const defaultState: IUserReducer = {
	user: defaultUser,
	requestState: RequestState.IDLE,
	sessionCheckedState: RequestState.IDLE,
	logoutWasCalled: false,
	passwordUpdated: false,
	userInfoUpdated: false,
	passwordReset: {
		requestState: RequestState.IDLE,
	},
	godMode: isGodModeActive,
	preregisterSuccess: false,
};

const onError = (state: IUserReducer, error: ApiError) => ({
	...state,
	error: error.message,
	requestState: RequestState.Received,
});

const onAuthRequest = (state: IUserReducer) => ({
	...state,
	requestState: RequestState.Requested,
	logoutWasCalled: false,
});

const onReceiveUser = (state: IUserReducer, user: IUser) => ({
	...state,
	user,
	error: undefined,
	requestState: RequestState.Received,
});

const onUpdatedUser = (state: IUserReducer, user: IUser) => {
	const updatedUser = {...state.user, teamName: user.teamName} as IUser;
	return {
		...state,
		user: updatedUser,
		error: undefined,
		requestState: RequestState.Received,
	};
};

const onPerformRequest = (state: IUserReducer) => ({
	...state,
	requestState: RequestState.Requested,
});

export const user = createReducer<IUserReducer>({}, defaultState)
	/**
	 * Login
	 */
	.on(userLogin, onAuthRequest)
	.on(userLoginSuccess, (state, user) => ({
		...state,
		user,
		isAuthorized: true,
		error: undefined,
		requestState: RequestState.Received,
	}))
	.on(userLoginFailed, onError)
	/**
	 * AuthCode
	 */
	.on(userAuthCodeLogin, onAuthRequest)
	.on(userAuthCodeSuccess, onReceiveUser)
	.on(userAuthCodeFailed, onError)
	/**
	 * Update
	 */
	.on(userUpdate, onPerformRequest)
	.on(userUpdateSuccess, onUpdatedUser)
	.on(userUpdateFailed, onError)
	.on(userInfoUpdate, onPerformRequest)
	.on(markUserInfoAsUpdated, (state) => ({
		...state,
		userInfoUpdated: true,
	}))
	.on(unMarkUserInfoAsUpdated, (state) => ({
		...state,
		userInfoUpdated: false,
	}))
	/**
	 * Logout
	 */
	.on(userLogout, (state) => ({
		...state,
		requestState: RequestState.Requested,
		logoutWasCalled: true,
	}))
	.on(userLogoutSuccess, (state) => ({
		...state,
		user: undefined,
		requestState: RequestState.Received,
	}))
	.on(userLogoutFailed, onError)
	/**
	 * Register
	 */
	.on(userRegister, onAuthRequest)
	.on(userRegisterSuccess, onReceiveUser)
	.on(userRegisterFailed, onError)
	.on(userPreregister, onAuthRequest)
	.on(userPreregisterSuccess, (state) => ({
		...state,
		preregisterSuccess: true,
	}))
	.on(userPreregisterFailed, onError)
	/**
	 * Get current user
	 */
	.on(requestCurrentUser, (state) => ({
		...state,
		sessionCheckedState: RequestState.Requested,
		logoutWasCalled: false,
	}))
	.on(requestCurrentUserSuccess, (state, user) => ({
		...state,
		user,
		error: undefined,
		sessionCheckedState: RequestState.Received,
	}))
	.on(requestCurrentUserFailed, (state) => ({
		...state,
		sessionCheckedState: RequestState.Received,
	}))
	/**
	 * Common
	 */
	.on(userClearError, (state) => ({
		...state,
		error: undefined,
	}))
	.on(resetUserRequestState, (state) => ({
		...state,
		requestState: RequestState.IDLE,
	}))
	.on(setGodMode, (state) => {
		localStorage.setItem("GOD_MODE", "true");
		return {
			...state,
			godMode: true,
		};
	})
	/**
	 * Deactivate account
	 */
	.on(userDeactivate, onPerformRequest)
	.on(userDeactivateFailed, onError)
	.on(userDeactivateSuccess, () => ({
		...defaultState,
		sessionCheckedState: RequestState.Received,
	}))
	/**
	 * Reactivate account
	 */
	.on(reactivateCurrentUserFailed, (state) => ({
		...state,
		sessionCheckedState: RequestState.Received,
	}));
