import media from "assets/css/media";
import React from "react";
import styled from "styled-components";

const ChildStyle = `
:first-child,
:last-child {
	padding-left: 20px;
}
:last-child {
	text-align: right;
	padding-right: 20px;
}`;

export const TDSVG = styled.td`
	${ChildStyle}
	display: initial;
	button {
		float: right;
	}
	svg {
		cursor: pointer;
		margin: 0 34px 0 0;
	}
`;

const Table = styled.table<{isDense: boolean}>`
	background-color: #fff;
	font-family: var(--fontFamilyGotham);
	font-size: 14px;
	font-weight: 500;
	width: 100%;

	td {
		height: ${({isDense}) => (isDense ? "47px" : "59px")};
		line-height: ${({isDense}) => (isDense ? "47px" : "59px")};
	}
	${TDSVG} {
		button {
			height: ${({isDense}) => (isDense ? "47px" : "59px")};
		}
	}
`;
const THead = styled.thead`
	background-color: var(--background);
	color: var(--textColor2);
	text-align: left;
`;
const TBody = styled.tbody``;

export const TH = styled.th`
	height: 47px;
	line-height: 47px;
	${ChildStyle}

	@media (max-width: ${media.small_mobile}) {
		:last-child {
			padding-left: 0px;
			padding-right: 10px;
		}
	}
`;
export const TD = styled.td`
	${ChildStyle}
`;

export const TR = styled.tr`
	border: 1px solid #e6e6e6;
`;
export const TRActive = styled.tr`
	background: var(--primaryYellow);
`;

interface ITable {
	tableHead: React.ReactNode;
	tableRows: React.ReactNode;
	isDense: boolean;
}

export const TableWrap: React.FC<ITable> = ({tableHead, tableRows, isDense}) => {
	return (
		<Table isDense={isDense}>
			<THead>{tableHead}</THead>
			<TBody>{tableRows}</TBody>
		</Table>
	);
};

export const EmptyTable = () => (
	<TableWrap
		isDense={true}
		tableHead={
			<TR>
				<TH />
			</TR>
		}
		tableRows={
			<TR>
				<TD />
			</TR>
		}></TableWrap>
);
