import React, {useState} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {hidePlayerModal, IPlayer, IRound} from "modules/actions";
import {
	getPlayersByID,
	getModalPlayerID,
	getSquadsByID,
	getViewedRound,
	// getActualAndCompletedRounds,
	getRoundsArray,
	getLastActiveElement,
	getPlayersPreseasonByID,
} from "modules/selectors";
import {WideModal} from "components/Modals";
import {
	getPlayerPositionName,
	getSquadLogo,
	getSquadColor,
	PLAYER_IMAGES_URL,
	GenderType,
	getSquadMascot,
	formatDecimal,
	getTeamLogo,
	// MatchStatus,
	RoundStatus,
} from "modules";
import {get} from "lodash";
import {ReactComponent as ExternalLink} from "assets/img/icons/externalLink.svg";
import {useMedia} from "react-use";
import media from "assets/css/media";
import emptyMan from "assets/img/emptyMan.png";
import emptyWoman from "assets/img/emptyWoman.png";
import {Exist} from "components/Exist";

const TopBlock = styled.div<{
	teamID: number;
}>`
	height: 360px;
	width: 800px;
	position: relative;
	${({teamID}) =>
		teamID &&
		`
        background-image: url(${getSquadLogo(teamID)});
        background-position-x: 30%;
        background-position-y: -80%;
        background-color: ${getSquadColor(teamID)};
        &::after{
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            width: 0;
            height: 100%;
            border-left: 500px solid transparent;
            border-right: 500px solid black;
            border-top: 500px solid transparent;
            z-index: 0;
            opacity: 0.1;
            @media (max-width: ${media.mobile}) {
                border-left: 100px solid transparent;
                border-right: 100px solid black;
                border-top: 100px solid transparent;
            }
        }
        
    `}
	@media (max-width: ${media.desktop}) {
		width: 100%;
		// height: 200px;
	}
	@media (max-width: ${media.mobile}) {
		width: 100%;
		height: 200px;
	}
`;

const PlayerImageSection = styled.div`
	position: absolute;
	bottom: 0;
	right: 18px;
	display: flex;
	z-index: 1;
	img,
	object {
		height: 360px;
		width: auto;
	}
	@media (max-width: ${media.mobile}) {
		right: 5px;

		object,
		img {
			height: 200px;
		}
	}
`;

const BottomGeneralBlock = styled.div`
	height: 150px;
	width: 800px;
	background: white;
	display: flex;
	justify-content: space-between;
	padding: 20px 100px;
	@media (max-width: ${media.mobile}) {
		padding: 10px;
		width: 100%;
		height: 115px;
	}
`;

const BottomFixtuesBlock = styled.div`
	width: 800px;
	background: white;
	@media (max-width: ${media.desktop}) {
		width: 100%;
	}
`;

const MiddleBlock = styled.div`
	height: 57px;
	width: 800px;
	background: black;
	display: flex;
	@media (max-width: ${media.desktop}) {
		width: 100%;
		height: 55px;
	}
`;

interface IIsActiveTabProps {
	status: boolean;
}

const MiddleBlockItem = styled.button<IIsActiveTabProps>`
	width: 50%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-weight: 700;
	color: white;
	position: relative;
	background: black;
	border: none;
	&::after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: ${(props) => (props.status ? "100%" : "0")};
		border-bottom: 4px solid var(--primaryYellow);
	}
`;

const PlayerDetailsBlock = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: absolute;
	left: 40px;
	top: 60px;
	@media (max-width: ${media.mobile}) {
		left: 10px;
		top: 20px;
	}
`;

const PlayerName = styled.h2`
	font-family: var(--fontFamilyGotham);
	color: white;
	font-size: 36px;
	font-weight: 400;
	@media (max-width: ${media.mobile}) {
		font-size: 16px;
		line-height: 18px;
	}
`;

const PlayerTeamRow = styled.p`
	font-family: var(--fontFamilyBase);
	font-weight: 700;
	font-size: 18px;
	color: white;
	margin-top: 20px;
	@media (max-width: ${media.mobile}) {
		font-size: 12px;
	}
`;

const PlayerStatItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const PlayerStatTitle = styled.h2`
	font-family: var(--fontFamilyBase);
	color: #4d4d4d;
	font-weight: 400;
	font-size: 12px;
	margin-bottom: 15px;
	@media (max-width: ${media.mobile}) {
		margin: 0;
	}
`;

const PlayerStatValue = styled.p`
	font-family: var(--fontFamilyBase);
	font-weight: 900;
	color: #000;
	font-size: 30px;
`;

const PlayerProfileLink = styled.a`
	position: absolute;
	left: 40px;
	bottom: 55px;
	background: #000;
	color: #fdda25;
	font-family: var(--fontFamilyBase);
	font-weight: 500;
	letter-spacing: 3.5px;
	text-decoration: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 50px;
	width: 315px;
	border-radius: 4px;
	font-size: 18px;
	z-index: 1;
	svg {
		height: 24px;
		width: 24px;
		margin-left: 6px;
	}
	@media (max-width: ${media.mobile}) {
		font-size: 14px;
		height: 40px;
		width: 200px;
		left: 10px;
		bottom: 10px;
		z-index: 2;
		svg {
			height: 16px;
			width: 16px;
		}
	}
`;

const Table = styled.table`
	width: 100%;
`;

const TableBody = styled.tbody`
	width: 100%;
	max-width: 233px;
`;

const TableHeader = styled.thead`
	background: var(--backgroundPopUpHeader);
	color: var(--PopUpHeader);
	font-size: 12px;
	padding: 12px 20px 0 20px;
	display: flex;
	justify-content: space-between;
`;

const TableHeaderCell = styled.th`
	width: 100px;
	height: 36px;
	background: var(--backgroundPopUpHeader);
	text-align: center;
	line-height: 36px;

	@media (max-width: ${media.mobile}) {
		width: 50px;
		font-size: 8px;
		white-space: pre;
	}
`;

const TableRow = styled.tr`
	border-bottom: 1px solid #ededed;
	background-color: white;
	color: black;
	height: 36px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
`;

const TableBodyCell = styled.td`
	width: 100px;
	height: 36px;
	text-align: center;
	line-height: 36px;
	font-size: 16px;
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: ${media.mobile}) {
		width: 50px;
	}
`;

const TeamLogo = styled.img`
	width: 32px;
	height: 32px;
	display: block;
`;

const PLAYER_STATS = (round: number) => [
	{
		prop: "stats.percentSelections",
		title: "SELECTED %",
	},
	{
		prop: "stats.avgPoints",
		title: "AVERAGE PTS",
	},
	{
		prop: "stats.totalPoints",
		title: "TOTAL PTS",
	},
	{
		prop: `stats.roundScores[${round}]`,
		title: "ROUND PTS",
	},
];

const getRoundOpponents = (round: number, player: IPlayer) => {
	const RoundId = round.toString();
	if (!(player.stats?.roundOpponents && player.stats?.roundOpponents[RoundId])) {
		return "-";
	} else {
		return (
			<TeamLogo
				src={getTeamLogo(player.stats?.roundOpponents[RoundId])}
				alt="team logo"></TeamLogo>
		);
	}
};

const getRoundMinsPlayed = (round: number, player: IPlayer) => {
	const RoundId = round.toString();
	return (player.stats?.roundMinsPlayed && player.stats?.roundMinsPlayed[RoundId]) || 0;
};

const getRoundScores = (round: number, player: IPlayer) => {
	const RoundId = round.toString();
	return (player.stats?.roundScores && player.stats?.roundScores[RoundId]) || 0;
};

const getRoundsIdArrayGender = (gender: GenderType, roundsIdArray: number[]) => {
	// male players only have 7 rounds
	// return gender === GenderType.MALE ? roundsIdArray.slice(0, 7) : roundsIdArray;
	return roundsIdArray;
};

const getRoundAvgScores = (rounds: IRound[], round: number, player: IPlayer) => {
	const pastRoundArray = rounds.filter((item) => item.id <= round).map((item) => item.id);
	let roundtotalScore = 0;
	let roundindex = 1;
	while (roundindex <= pastRoundArray.length) {
		roundtotalScore = roundtotalScore + (player.stats?.roundScores?.[roundindex] || 0);
		roundindex++;
	}
	const roundStatus = rounds.find((item) => item.id === round)?.status;
	return roundStatus === "complete" ? (roundtotalScore / pastRoundArray.length).toFixed(1) : 0;
};

const getRoundTotalScores = (rounds: IRound[], round: number, player: IPlayer) => {
	const pastRoundArray = rounds.filter((item) => item.id <= round).map((item) => item.id);
	let roundtotalScore = 0;
	let roundindex = 1;
	while (roundindex <= pastRoundArray.length) {
		roundtotalScore = roundtotalScore + (player.stats?.roundScores?.[roundindex] || 0);
		roundindex++;
	}
	const roundStatus = rounds.find((item) => item.id === round)?.status;
	return roundStatus === "complete" ? roundtotalScore : 0;
};

export const checkIsPreseason = (rounds: IRound[]) => {
	// return (
	// 	rounds[0].units[0].status === MatchStatus.Scheduled ||
	// 	rounds[0].units[0].status === MatchStatus.Active
	// );
	return rounds[0].status === RoundStatus.Scheduled;
};

const isShowPreseason = (playerPreseason: IPlayer | undefined, isPreseason: boolean) =>
	playerPreseason && isPreseason;

export const PlayerPopup: React.FC = () => {
	const isMobile = useMedia("(max-width: 768px)");
	const dispatch = useDispatch();
	const playersByID = useSelector(getPlayersByID);
	const playersPreseasonByID = useSelector(getPlayersPreseasonByID);
	const lastActiveElement = useSelector(getLastActiveElement);
	const playerID = useSelector(getModalPlayerID);
	const squadsByID = useSelector(getSquadsByID);
	const round = useSelector(getViewedRound) || {id: 0};
	const rounds = useSelector(getRoundsArray);
	const isPreseason = checkIsPreseason(rounds);
	const roundsIdArray = rounds.map((round) => round.id);
	const player = playersByID[playerID];
	const playerPreseason = playersPreseasonByID[playerID];
	if (isShowPreseason(playerPreseason, isPreseason)) {
		player.stats.avgPoints = playerPreseason?.stats?.avgPoints;
		player.stats.totalPoints = playerPreseason?.stats?.totalPoints;
	}
	const roundsIdArrayGender = getRoundsIdArrayGender(player.gender, roundsIdArray);
	const selected_pct = get(player, "stats.percentSelections", 0);
	const decimalSelectedPct = formatDecimal(selected_pct, 1);
	player.stats.percentSelections = Number(decimalSelectedPct);
	const average = get(player, "stats.avgPoints", 0);
	const decimalAverage = formatDecimal(average, 1);
	player.stats.avgPoints = Number(decimalAverage);
	const squadMascot = getSquadMascot(squadsByID[player.squadId]);
	const positionString = getPlayerPositionName(player.positions);
	const isWoman = player.gender === GenderType.FEMALE;

	const external_link = player?.nrlProfileLink || "";
	const isMale = player.gender === "MALE";

	const [activeTab, setActiveTab] = useState("General");
	const [playerImgSrc, setPlayerImgSrc] = useState(`${PLAYER_IMAGES_URL}${player.id}.png`);

	const handleError = () => {
		setPlayerImgSrc(isWoman ? emptyWoman : emptyMan);
	};

	const playerStatsSection = () => {
		const playerStats = PLAYER_STATS(round.id);

		return playerStats.map((playerStat) => (
			<PlayerStatItem key={`${player.id}-${playerStat.title}`}>
				<PlayerStatTitle>{playerStat.title}</PlayerStatTitle>
				<PlayerStatValue>{get(player, playerStat.prop, 0)}</PlayerStatValue>
			</PlayerStatItem>
		));
	};

	const handleClose = () => {
		dispatch(hidePlayerModal());
		(lastActiveElement as HTMLElement).focus();
	};

	return (
		<WideModal handlerClose={handleClose}>
			<TopBlock teamID={player.squadId}>
				<PlayerDetailsBlock>
					<PlayerName>{player.firstName}</PlayerName>
					<PlayerName>{player.lastName}</PlayerName>
					<PlayerTeamRow>
						{squadMascot} | {positionString}
					</PlayerTeamRow>
				</PlayerDetailsBlock>
				<Exist when={!!external_link}>
					<PlayerProfileLink href={external_link} target="_blank">
						{isMale ? "NRL" : "NRLW"} {isMobile ? "" : "PLAYER"} PROFILE
						<ExternalLink />
					</PlayerProfileLink>
				</Exist>
				<PlayerImageSection>
					<img src={playerImgSrc} onError={handleError} alt="Placeholder" />
				</PlayerImageSection>
			</TopBlock>
			<MiddleBlock>
				<MiddleBlockItem
					status={activeTab === "General"}
					onClick={() => {
						setActiveTab("General");
					}}>
					General Stats
				</MiddleBlockItem>
				<MiddleBlockItem
					status={activeTab === "Fixtures"}
					onClick={() => {
						setActiveTab("Fixtures");
					}}>
					Fixtures
				</MiddleBlockItem>
			</MiddleBlock>
			<Exist when={activeTab === "General"}>
				<BottomGeneralBlock>{playerStatsSection()}</BottomGeneralBlock>
			</Exist>
			<Exist when={activeTab !== "General"}>
				<BottomFixtuesBlock>
					<Table>
						<TableHeader>
							<TableHeaderCell>RND</TableHeaderCell>
							<TableHeaderCell>VS</TableHeaderCell>
							<TableHeaderCell>MINS</TableHeaderCell>
							<TableHeaderCell>SCORE</TableHeaderCell>
							<TableHeaderCell>AVG PTS</TableHeaderCell>
							<TableHeaderCell>TOTAL SCORE</TableHeaderCell>
						</TableHeader>
						<TableBody>
							{roundsIdArrayGender.map((round) => {
								return (
									<TableRow key={round}>
										<TableBodyCell>{round}</TableBodyCell>
										<TableBodyCell>
											{getRoundOpponents(round, player)}
										</TableBodyCell>
										<TableBodyCell>
											{`${getRoundMinsPlayed(round, player)}'`}
										</TableBodyCell>
										<TableBodyCell>
											{getRoundScores(round, player)}
										</TableBodyCell>
										<TableBodyCell>
											{getRoundAvgScores(rounds, round, player) || 0}
										</TableBodyCell>
										<TableBodyCell>
											{getRoundTotalScores(rounds, round, player) || 0}
										</TableBodyCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</BottomFixtuesBlock>
			</Exist>
		</WideModal>
	);
};
