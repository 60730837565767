import * as React from "react";

interface IIconProps {
	width?: number;
	height?: number;
	color?: string;
	onClick?: () => void;
	title?: string;
	className?: string;
}

export const IconCross: React.FC<IIconProps> = ({title, width, height, color, onClick}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
		<path
			fill="#fff"
			fillRule="evenodd"
			d="M15.071 13.303L9.768 8l5.303-5.304L13.303.93 8 6.232 2.697.929.929 2.696 6.232 8 .93 13.303l1.768 1.768L8 9.768l5.303 5.303 1.768-1.768z"
			clipRule="evenodd"
		/>
	</svg>
);

IconCross.defaultProps = {
	title: "Cross",
	width: 24,
	height: 24,
	color: "#010101",
};
