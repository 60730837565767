import React from "react";
import {Navigate, Outlet, RouteProps, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {hasUserExceptedTerms, isLoggedIn} from "modules/selectors";
import {get} from "lodash";
import {allTrue, Storage} from "modules/utils";
import {notOffSeasonMode} from "index";

export const NotAuthOnlyRoute: React.FC<RouteProps> = (props) => {
	const location = useLocation();
	const fromPath = get(location, "state.from.pathname", "") as string;
	const fromSearch = get(location, "state.from.search", "") as string;
	const backURL = fromPath ? fromPath + fromSearch : "/team";
	const userExceptedTerms = useSelector(hasUserExceptedTerms);
	const getisLoggedIn = useSelector(isLoggedIn);
	const checkLeagueJoin = fromPath.includes("leagues/join");
	const checkLeagueCode = fromSearch.includes("code");
	const urlParams = new URLSearchParams(fromSearch);
	const leagueCode = urlParams.get("code");
	const leageCodeFromStorage = Storage.GET("league_code") || "";

	if (notOffSeasonMode) {
		if (allTrue([checkLeagueJoin, checkLeagueCode])) {
			Storage.SET("league_code", leagueCode);
		}
		if (allTrue([getisLoggedIn, userExceptedTerms])) {
			if (allTrue([["/", "team"].includes(location.pathname), leageCodeFromStorage !== ""])) {
				return <Navigate to={`leagues/join?code=${leageCodeFromStorage}`} replace />;
			}

			return <Navigate to={backURL} replace />;
		}
	}

	return <Outlet />;
};
