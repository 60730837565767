import {createReducer} from "redux-act";
import {
	fetchFaq,
	fetchFaqFailed,
	fetchFaqSuccess,
	fetchGameRules,
	fetchGameRulesFailed,
	fetchGameRulesSuccess,
	fetchPrizes,
	fetchPrizesFailed,
	fetchPrizesSuccess,
	fetchSchedule,
	fetchScheduleFailed,
	fetchScheduleSuccess,
	fetchTerms,
	fetchTermsFailed,
	fetchTermsSuccess,
	IFaqItem,
	IHelpResponse,
} from "modules/actions";
import {RequestState} from "modules/enums";

interface IHelpChunk {
	requestState: RequestState;
	value: IHelpResponse | null;
}

interface IFaqChunk {
	requestState: RequestState;
	value: IFaqItem[] | null;
}

interface IHelpReducer {
	faq: IFaqChunk;
	terms: IHelpChunk;
	schedule: IHelpChunk;
	prizes: IHelpChunk;
	gameRules: IHelpChunk;
}

const defaultState: IHelpReducer = {
	gameRules: {
		requestState: RequestState.IDLE,
		value: null,
	},
	prizes: {
		requestState: RequestState.IDLE,
		value: null,
	},
	faq: {
		requestState: RequestState.IDLE,
		value: null,
	},
	terms: {
		requestState: RequestState.IDLE,
		value: null,
	},
	schedule: {
		requestState: RequestState.IDLE,
		value: null,
	},
} as const;

type IReducerFieldName = keyof typeof defaultState;

const setRequestStateFor =
	(fieldName: IReducerFieldName, requestState: RequestState) => (state: IHelpReducer) => ({
		...state,
		[fieldName]: {
			...state[fieldName],
			requestState,
		},
	});

const storeHelpValueFor =
	(fieldName: IReducerFieldName) => (state: IHelpReducer, value: IHelpResponse | IFaqItem[]) => ({
		...state,
		[fieldName]: {
			requestState: RequestState.Received,
			value,
		},
	});

export const help = createReducer<IHelpReducer>({}, defaultState)
	.on(fetchTerms, setRequestStateFor("terms", RequestState.Requested))
	.on(fetchTermsSuccess, storeHelpValueFor("terms"))
	.on(fetchTermsFailed, setRequestStateFor("terms", RequestState.Received))

	.on(fetchSchedule, setRequestStateFor("schedule", RequestState.Requested))
	.on(fetchScheduleSuccess, storeHelpValueFor("schedule"))
	.on(fetchScheduleFailed, setRequestStateFor("schedule", RequestState.Received))

	.on(fetchPrizes, setRequestStateFor("prizes", RequestState.Requested))
	.on(fetchPrizesSuccess, storeHelpValueFor("prizes"))
	.on(fetchPrizesFailed, setRequestStateFor("prizes", RequestState.Received))

	.on(fetchGameRules, setRequestStateFor("gameRules", RequestState.Requested))
	.on(fetchGameRulesSuccess, storeHelpValueFor("gameRules"))
	.on(fetchGameRulesFailed, setRequestStateFor("gameRules", RequestState.Received))

	.on(fetchFaq, setRequestStateFor("faq", RequestState.Requested))
	.on(fetchFaqSuccess, storeHelpValueFor("faq"))
	.on(fetchFaqFailed, setRequestStateFor("faq", RequestState.Received));
