import {INotificationBar} from "modules/reducers";
import {createAction} from "redux-act";

export interface ISquad {
	id: number;
	name: string;
	code: string;
	sport: string;
}

export const fetchSquads = createAction("Fetch Squads");
export const fetchSquadsSuccess = createAction<ISquad[]>("Fetch Squads Success");
export const fetchSquadsFailed = createAction("Fetch Squads Failed");

export const fetchNotificationBar = createAction();
export const fetchNotificationBarSuccess = createAction<INotificationBar>();
export const fetchNotificationBarFailed = createAction<Error | unknown>();
