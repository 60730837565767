import {Api, ApiError} from "modules/utils";
import {call, put, select} from "typed-redux-saga";
import {
	autoFillTeam,
	autoFillTeamFailed,
	autoFillTeamSuccess,
	requestMyTeam,
	requestMyTeamFailed,
	requestMyTeamSuccess,
	saveTeam,
	saveTeamFailed,
	saveTeamSuccess,
	requestShowMySuccess,
} from "modules/actions/team";
import {showGlobalError} from "modules/actions";
import {getCreatedTeamRounds, getViewedTeam, getViewedTeamLineup} from "modules/selectors/team";
import {get, identity, isEmpty, pickBy} from "lodash";

export const showMyTeamSaga = function* ({payload: roundID}: ReturnType<typeof requestMyTeam>) {
	try {
		// need to query by round

		const response = yield* call(Api.FantasyTeam.showMy, roundID);
		yield* put(
			requestShowMySuccess({
				total_registered_users: response.success.total_registered_users,
				teams: response.success.teams,
			})
			// if team exists for round we put flag in team object for that round
		);
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(requestMyTeamFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const saveTeamSaga = function* ({payload: roundID}: ReturnType<typeof saveTeam>) {
	try {
		const team = yield* select(getViewedTeam);
		const createdTeams = yield* select(getCreatedTeamRounds);
		const isTeamCreate = createdTeams.includes(roundID);
		const lineup = team?.lineup;
		const lineupMenCap = get(team, "captain_men", 0);
		const lineupWomenCap = get(team, "captain_women", 0);
		const lineupSend = {
			...lineup,
			captain_men: lineupMenCap,
			captain_women: lineupWomenCap,
		};
		// if this flag on viewed team exists then we update, otherwise we create
		const response = isTeamCreate
			? yield* call(
					Api.FantasyTeam.save,
					pickBy(
						{
							lineup: isEmpty(lineupSend) ? undefined : lineupSend,
							roundID,
						},
						identity
					)
			  )
			: yield* call(
					Api.FantasyTeam.create,
					// need to find way to check if team has previously created
					// then we use save instead and roundID instead of round
					pickBy(
						{
							lineup: isEmpty(lineupSend) ? undefined : lineupSend,
							round: roundID,
						},
						identity
					)
			  );

		const payload = {
			roundID,
			team: response?.success.team,
		};

		yield* put(requestMyTeamSuccess(payload));
		yield* put(saveTeamSuccess(payload));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(saveTeamFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const autofillTeamSaga = function* ({payload: roundID}: ReturnType<typeof autoFillTeam>) {
	try {
		const team = yield* select(getViewedTeam);
		const lineup = yield* select(getViewedTeamLineup);
		const lineupMenCap = get(team, "captain_men", 0);
		const lineupWomenCap = get(team, "captain_women", 0);
		const lineupSend = {
			...lineup,
			captain_men: lineupMenCap,
			captain_women: lineupWomenCap,
		};

		const response = yield* call(
			Api.FantasyTeam.autofill,
			pickBy(
				{
					lineup: isEmpty(lineupSend) ? undefined : lineupSend,
					round: roundID,
				},
				identity
			)
		);

		const payload = {
			roundID,
			team: response.success.team,
		};

		yield* put(requestMyTeamSuccess(payload));
		yield* put(autoFillTeamSuccess(payload));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(autoFillTeamFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};
