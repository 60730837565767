import {keyBy} from "lodash";
import {RequestState} from "modules/enums";
import {IStore} from "modules/types/store";
import {createSelector} from "reselect";

const DEFAULT_PLAYER = {
	firstName: "",
	lastName: "",
	id: undefined,
	positions: [0],
	squadId: 0,
	gender: undefined,
	stats: {
		percentSelections: 0,
		averagePoints: 0,
		totalPoints: 0,
	},
	status: "",
	nrlProfileLink: "",
};

export const getJSONStore = ({jsons}: IStore) => jsons;

export const getNotificationBar = ({jsons: {notification_bar}}: IStore) => notification_bar;

export const getSquadsState = createSelector(getJSONStore, (jsons) => {
	return {
		squads: jsons.squads,
		squadRequestState: jsons.squadRequestState,
	};
});

export const getIsSquadsLoaded = createSelector(
	getJSONStore,
	(jsons) => jsons.squadRequestState === RequestState.Received
);

export const getSquadsArray = createSelector(getJSONStore, (jsons) => jsons.squads);

export const getSquadsByID = createSelector(getSquadsArray, (squads) => keyBy(squads, "id"));

export const getPlayers = createSelector(getJSONStore, (jsons) => {
	return jsons.players;
});

export const getPlayersPreseason = createSelector(getJSONStore, (jsons) => {
	return jsons.playersPreseason;
});

export const getPlayerStats = createSelector(getJSONStore, (jsons) => {
	return jsons.playersStatus;
});

export const getPlayersByID = createSelector(
	getPlayers,
	(players) => keyBy(players, "id") || DEFAULT_PLAYER
);

export const getPlayersPreseasonByID = createSelector(
	getPlayersPreseason,
	(players) => keyBy(players, "id") || DEFAULT_PLAYER
);

export const getActivePlayers = createSelector(getPlayers, (players) =>
	players.filter((player) => player.status !== "eliminated")
);

export const getActiveSquadsArray = createSelector(
	getSquadsArray,
	getActivePlayers,
	(squadsArray, activePlayers) => {
		const activePlayersSquadsId = Array.from(
			new Set(activePlayers.map((player) => player.squadId))
		);
		return squadsArray.filter((squad) => activePlayersSquadsId.includes(squad.id));
	}
);

export const getPlayerDisplayStats = createSelector(
	getPlayers,
	getPlayerStats,
	(playersArray, PlayerStats) => {
		playersArray.forEach((item) => {
			item.stats = {...item.stats, ...PlayerStats[item.id]};
		});
		return playersArray;
	}
);

export const getActivePlayersWithDisplayStats = createSelector(getPlayerDisplayStats, (players) =>
	players.filter((player) => player.status !== "eliminated")
);
