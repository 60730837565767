import {createTheme} from "@mui/material";

declare module "@mui/material/styles" {
	// allow configuration using `createTheme`
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ThemeOptions {
		[key: string]: unknown;
	}
}

const themeObject = {
	palette: {
		primary: {
			main: "#000",
		},
		secondary: {
			main: "#FFC72C",
		},
	},
	typography: {
		allVariants: {
			fontFamily: "RL2",
		},
	},
};

export const theme = createTheme(themeObject);
type ThemeInterface = typeof themeObject;

declare module "styled-components" {
	// Allows change type of  theme to avoid any
	/* eslint-disable @typescript-eslint/no-empty-interface */

	// eslint-disable-next-line @typescript-eslint/naming-convention
	export interface DefaultTheme extends ThemeInterface {}
}
