import {createSelector} from "reselect";
import {IStore} from "modules/types/store";
import {RequestState} from "modules/enums";

export const getUserState = ({user}: IStore) => user;

export const isSessionChecked = createSelector(
	getUserState,
	({sessionCheckedState}) => sessionCheckedState === RequestState.Received
);

export const isSessionCheckedIdle = createSelector(
	getUserState,
	({sessionCheckedState}) => sessionCheckedState === RequestState.IDLE
);

export const getUser = createSelector(getUserState, ({user}) => user);
export const getUserRequestState = createSelector(getUserState, ({requestState}) => requestState);
export const getUserError = createSelector(getUserState, ({error}) => error);
export const isLoggedIn = createSelector(getUser, (user) =>
	Boolean((user?.id || user?.teamName) && user?.terms)
);
export const hasUserExceptedTerms = createSelector(getUser, (user) => Boolean(user?.terms));

export const isLogOutWasCalled = createSelector(
	getUserState,
	({logoutWasCalled}) => logoutWasCalled
);

export const getUserInfoUpdated = createSelector(
	getUserState,
	({userInfoUpdated}) => userInfoUpdated
);

export const getIsGodModeEnabled = createSelector(getUserState, (user) => user.godMode);

export const getPreregisterState = createSelector(getUserState, (user) => user.preregisterSuccess);
