import {get} from "lodash";
// import team1 from "assets/img/teamLogos/500001.svg";
// import team2 from "assets/img/teamLogos/500002.svg";
// import team3 from "assets/img/teamLogos/500003.svg";
// import team4 from "assets/img/teamLogos/500004.svg";
// import team5 from "assets/img/teamLogos/500005.svg";
// import team6 from "assets/img/teamLogos/500010.svg";
// import team7 from "assets/img/teamLogos/500011.svg";
// import team8 from "assets/img/teamLogos/500012.svg";
// import team9 from "assets/img/teamLogos/500013.svg";
// import team10 from "assets/img/teamLogos/500014.svg";
// import team11 from "assets/img/teamLogos/500021.svg";
// import team12 from "assets/img/teamLogos/500022.svg";
// import team13 from "assets/img/teamLogos/500023.svg";
// import team14 from "assets/img/teamLogos/500028.svg";
// import team15 from "assets/img/teamLogos/500031.svg";
// import team16 from "assets/img/teamLogos/500032.svg";

import broncosLogo from "assets/img/teamLogos/500011.svg";
import bulldogsLogo from "assets/img/teamLogos/500010.svg";
import cowboysLogo from "assets/img/teamLogos/500012.svg";
import dragonsLogo from "assets/img/teamLogos/500022.svg";
import eelsLogo from "assets/img/teamLogos/500031.svg";
import knightsLogo from "assets/img/teamLogos/500003.svg";
import panthersLogo from "assets/img/teamLogos/500014.svg";
import rabbitohsLogo from "assets/img/teamLogos/500005.svg";
import raidersLogo from "assets/img/teamLogos/500013.svg";
import roostersLogo from "assets/img/teamLogos/500001.svg";
import seaEaglesLogo from "assets/img/teamLogos/500002.svg";
import sharksLogo from "assets/img/teamLogos/500028.svg";
import stormLogo from "assets/img/teamLogos/500021.svg";
import titansLogo from "assets/img/teamLogos/500004.svg";
import warriorsLogo from "assets/img/teamLogos/500032.svg";
import westsTigersLogo from "assets/img/teamLogos/500023.svg";
import dolphinsLogo from "assets/img/teamLogos/500723.svg";

import broncosSquadLogo from "assets/img/teamLogos/500011.png";
import bulldogsSquadLogo from "assets/img/teamLogos/500010.png";
import cowboysSquadLogo from "assets/img/teamLogos/500012.png";
import dragonsSquadLogo from "assets/img/teamLogos/500022.png";
import eelsSquadLogo from "assets/img/teamLogos/500031.png";
import knightsSquadLogo from "assets/img/teamLogos/500003.png";
import panthersSquadLogo from "assets/img/teamLogos/500014.png";
import rabbitohsSquadLogo from "assets/img/teamLogos/500005.png";
import raidersSquadLogo from "assets/img/teamLogos/500013.png";
import roostersSquadLogo from "assets/img/teamLogos/500001.png";
import seaEaglesSquadLogo from "assets/img/teamLogos/500002.png";
import sharksSquadLogo from "assets/img/teamLogos/500028.png";
import stormSquadLogo from "assets/img/teamLogos/500021.png";
import titansSquadLogo from "assets/img/teamLogos/500004.png";
import warriorsSquadLogo from "assets/img/teamLogos/500032.png";
import westsTigersSquadLogo from "assets/img/teamLogos/500023.png";
import dolphinsSquadLogo from "assets/img/teamLogos/500723.png";

export const logos = {
	500001: roostersLogo,
	500002: seaEaglesLogo,
	500003: knightsLogo,
	500004: titansLogo,
	500005: rabbitohsLogo,
	500010: bulldogsLogo,
	500011: broncosLogo,
	500012: cowboysLogo,
	500013: raidersLogo,
	500014: panthersLogo,
	500021: stormLogo,
	500022: dragonsLogo,
	500023: westsTigersLogo,
	500028: sharksLogo,
	500031: eelsLogo,
	500032: warriorsLogo,
	500469: roostersLogo,
	500470: broncosLogo,
	500471: dragonsLogo,
	500690: titansLogo,
	500691: knightsLogo,
	500692: eelsLogo,
	500723: dolphinsLogo,
	500785: raidersLogo,
	500786: sharksLogo,
	500787: cowboysLogo,
	500788: westsTigersLogo,
};

export const teamLogos = {
	500001: roostersSquadLogo,
	500002: seaEaglesSquadLogo,
	500003: knightsSquadLogo,
	500004: titansSquadLogo,
	500005: rabbitohsSquadLogo,
	500010: bulldogsSquadLogo,
	500011: broncosSquadLogo,
	500012: cowboysSquadLogo,
	500013: raidersSquadLogo,
	500014: panthersSquadLogo,
	500021: stormSquadLogo,
	500022: dragonsSquadLogo,
	500023: westsTigersSquadLogo,
	500028: sharksSquadLogo,
	500031: eelsSquadLogo,
	500032: warriorsSquadLogo,
	500469: roostersSquadLogo,
	500470: broncosSquadLogo,
	500471: dragonsSquadLogo,
	500690: titansSquadLogo,
	500691: knightsSquadLogo,
	500692: eelsSquadLogo,
	500723: dolphinsSquadLogo,
	500785: raidersSquadLogo,
	500786: sharksSquadLogo,
	500787: cowboysSquadLogo,
	500788: westsTigersSquadLogo,
};

export const teamNames = {
	500001: "roosters",
	500002: "seaEagles",
	500003: "knights",
	500004: "titans",
	500005: "rabbitohs",
	500010: "bulldogs",
	500011: "broncos",
	500012: "cowboys",
	500013: "raiders",
	500014: "panthers",
	500021: "storm",
	500022: "dragons",
	500023: "westsTigers",
	500028: "sharks",
	500031: "eels",
	500032: "warriors",
	500469: "roosters",
	500470: "broncos",
	500471: "dragons",
	500690: "titans",
	500691: "knights",
	500692: "eels",
	500723: "dolphins",
	500785: "raiders",
	500786: "sharks",
	500787: "cowboys",
	500788: "westsTigers",
};

const squadColors = {
	roosters: "#0b2d5b",
	seaEagles: "#660238",
	knights: "#003b73",
	titans: "#005e9b",
	rabbitohs: "#003b19",
	bulldogs: "#00468b",
	broncos: "#6c1d45",
	cowboys: "#0c2340",
	raiders: "#009a4d",
	panthers: "#2c2f30",
	storm: "#491f6b",
	dragons: "#cd232c",
	westsTigers: "#262626",
	sharks: "#1f7eb2",
	eels: "#002d72",
	warriors: "#2a2e34",
	dolphins: "#FC141D",
};

export const getSquadLogo = (id: number) => {
	return get(logos, id);
};

export const getTeamLogo = (id: number) => {
	return get(teamLogos, id);
};

export const getSquadColor = (id: number) => {
	const teamName = get(teamNames, id);
	return get(squadColors, teamName) as string;
};
