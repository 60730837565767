import {createAction} from "redux-act";
import {ApiError} from "modules/utils";

export interface ITeamLineup {
	[number: number]: number[];
}

export interface ITeam {
	id: number;
	name: string;
	lineup: ITeamLineup;
	captain_men: number;
	captain_women: number;
	start_event_id: number;
	points: number | null;
	event_rank: number | null;
	overall_rank: number | null;
	overall_points: number | null;
	scoreflow: Record<number, number>;
	selections: Record<number, number>;
	createdAt: string;
	roundId: number;
}

interface IRequestedTeamPayload {
	team: ITeam;
	roundID: number;
}

interface ISuccessShowMyTeams {
	total_registered_users: number;
	teams: ITeam[];
}

export const requestMyTeam = createAction<number>();
export const requestMyTeamSuccess = createAction<IRequestedTeamPayload>();
export const requestMyTeamFailed = createAction<ApiError>();
export const requestShowMySuccess = createAction<ISuccessShowMyTeams>();

export const clearTeam = createAction<number>();

export const autoFillTeam = createAction<number>();
export const autoFillTeamSuccess = createAction<IRequestedTeamPayload>();
export const autoFillTeamFailed = createAction<ApiError>();

export const saveTeam = createAction<number>();
export const saveTeamSuccess = createAction<IRequestedTeamPayload>();
export const saveTeamFailed = createAction<ApiError>();
export const hideSaveTeamSuccessModal = createAction();

interface ITeamActionPayload {
	roundID: number;
	playerID: number;
	isFemale: boolean;
}

interface ITeamActionAddPayload extends ITeamActionPayload {
	positions: number[];
}

export const setCaptain = createAction<ITeamActionPayload>();
export const addPlayerToTeam = createAction<ITeamActionAddPayload>();
export const removePlayerFromTeam = createAction<ITeamActionPayload>();

export const showPlayerModal = createAction<number>();
export const hidePlayerModal = createAction();
