import styled from "styled-components";
import React, {Fragment, useEffect, useRef} from "react";
import {Close} from "@mui/icons-material";
import {ButtonSecondary} from "components/Buttons";
import {Exist} from "components/Exist";
import media from "assets/css/media";

export const Overlay = styled.div`
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 50;
	left: 0;
	top: 0;
`;

export const ModalContainer = styled.div`
	background: rgba(0, 0, 0, 0.5);
	left: 0;
	top: 0;
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 50;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Text = styled.div`
	margin-bottom: 30px;
	line-height: 17px;
	font-size: 14px;

	strong {
		font-weight: 700;
	}

	@media (min-width: 769px) {
		font-size: 16px;
	}
`;

export const Title = styled.h4`
	font-family: Kino, sans-serif;
	letter-spacing: 0.83px;
	font-size: 22px;
	margin-bottom: 16px;
	line-height: 22px;

	@media (min-width: 769px) {
		margin-bottom: 25px;
		font-size: 30px;
	}
`;

export const Modal = styled.div<{
	isSmall: boolean;
}>`
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: calc(100% - 20px);
	max-height: calc(100% - 20px);
	overflow: auto;
	max-width: 600px;
	min-height: 200px;
	background: #fff;
	border-radius: 5px;
	box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
	z-index: 51;
	${({isSmall}) =>
		isSmall &&
		`
		min-height: 120px;
		max-width: 400px;
	`}
`;

export const WideModalWrap = styled.div`
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: calc(100% - 20px);
	max-height: calc(100% - 20px);
	overflow: auto;
	max-width: 800px;
	min-height: 200px;
	background: #1e2643;
	border-radius: 5px;
	box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
	z-index: 51;
`;

export const ModalInner = styled.div`
	position: relative;
	padding: 25px;
`;

export const WideModalInner = styled.div`
	position: relative;
	padding: 0;
	svg {
		fill: #fff;
	}
`;

export const ModalContent = styled.div`
	color: #343232;
	text-align: center;
`;

export const CloseArea = styled.button`
	border: 0;
	background: none;
	padding: 0;
	margin: 0;
	cursor: pointer;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 2;
`;

interface ICloseButtonProps {
	onClick?: () => void;
	className?: string;
}

export const CloseButton: React.FC<ICloseButtonProps> = (props) => (
	<CloseArea {...props}>
		<Close />
	</CloseArea>
);

export const ButtonsBox = styled.div`
	padding-top: 40px;
	display: flex;
	align-items: center;
	justify-content: center;

	button,
	a {
		margin: 0 10px;
		width: 170px;

		@media (max-width: ${media.small_mobile}) {
			width: 80%;
			margin: 5px 0;
		}
	}

	@media (max-width: ${media.small_mobile}) {
		flex-direction: column;
	}
`;

export const ModalTitle = styled.h4`
	font-size: 20px;
	line-height: 22px;
	margin-bottom: 13px;
	color: var(--textColor1);
`;

export const ModalText = styled.p`
	color: var(--textColor1);
	font-size: 16px;
	line-height: 24px;
`;

export const ModalLogo = styled.img`
	width: 80px;
	height: auto;
	margin-bottom: 15px;
`;

export const ModalLogoSmall = styled.img`
	width: 40px;
	height: auto;
	margin-bottom: 15px;
`;

interface IModalWindow {
	onClose: () => void;
	title?: string;
	message?: string;
	buttonText?: string;
	iconSrc?: string;
}

export const ModalWindow: React.FC<IModalWindow> = (props) => {
	const {message, title, onClose, buttonText, iconSrc} = props;

	const modalRef = useRef<HTMLElement>(null);

	useEffect(() => {
		const focusableModalElements = modalRef?.current?.querySelectorAll(
			'input[type="text"], button,a[href]'
		);

		const firstElement = focusableModalElements ? focusableModalElements[0] : null;
		const lastElement = focusableModalElements
			? focusableModalElements[focusableModalElements.length - 1]
			: null;

		if (firstElement && document.activeElement !== firstElement) {
			(firstElement as HTMLElement)?.focus();
		}

		const close = (ev: KeyboardEvent) => {
			if (ev.key === "Escape" || ev.key === " " || (ev.key === "Enter" && onClose)) {
				ev.stopPropagation();
				onClose();
			}
			if (ev.key === "Tab" && document.activeElement === lastElement) {
				(firstElement as HTMLElement)?.focus();
				ev.stopPropagation();
			}
		};

		window.addEventListener("keydown", close);
		return () => window.removeEventListener("keydown", close);
	}, [onClose]);

	if (!message) {
		return null;
	}

	return (
		<Fragment>
			<Overlay onClick={onClose} />
			<Modal
				isSmall={false}
				ref={modalRef as React.RefObject<HTMLDivElement>}
				tabIndex={-1}
				role="dialog"
				aria-modal="true">
				<ModalInner tabIndex={0}>
					<CloseButton onClick={onClose} aria-label="Close" />
					<ModalContent>
						<Exist when={Boolean(iconSrc)}>
							<ModalLogo src={iconSrc} alt="" role="banner" />
						</Exist>
						<Exist when={Boolean(title)}>
							<ModalTitle>{title}</ModalTitle>
						</Exist>
						<ModalText>{message}</ModalText>
						<Exist when={Boolean(buttonText)}>
							<ButtonsBox>
								<ButtonSecondary onClick={onClose}>{buttonText}</ButtonSecondary>
							</ButtonsBox>
						</Exist>
					</ModalContent>
				</ModalInner>
			</Modal>
		</Fragment>
	);
};
