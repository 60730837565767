import {GenderType} from "modules/enums";
import {createAction} from "redux-act";

export interface IPlayer {
	firstName: string;
	lastName: string;
	id: number;
	positions: number[];
	squadId: number;
	gender: GenderType;
	stats: {
		percentSelections: number;
		averagePoints?: number;
		avgPoints?: number;
		totalPoints: number;
		roundScores?: Record<string, number>;
		roundOpponents?: Record<string, number>;
		roundMinsPlayed?: Record<string, number>;
		T?: number;
		TS?: number;
		G?: number;
		FG?: number;
		TA?: number;
		LB?: number;
		LBA?: number;
		TCK?: number;
		TB?: number;
		MT?: number;
		OFH?: number;
		OFG?: number;
		ER?: number;
		FTF?: number;
		MG?: number;
		KM?: number;
		KD?: number;
		PC?: number;
		SB?: number;
		SO?: number;
		TOG?: number;
		FDO?: number;
		TO?: number;
		SAI?: number;
		EFIG?: number;
	};
	status:
		| "playing"
		| "not-playing"
		| "injured"
		| "uncertain"
		| "eliminated"
		| "suspended"
		| "reserve"
		| "emergency";
	nrlProfileLink: string;
}

export interface IPlayerDisplayStats {
	T: number;
	TS: number;
	G: number;
	FG: number;
	TA: number;
	LB: number;
	LBA: number;
	TCK: number;
	TB: number;
	MT: number;
	OFH: number;
	OFG: number;
	ER: number;
	FTF: number;
	MG: number;
	KM: number;
	KD: number;
	PC: number;
	SB: number;
	SO: number;
	TOG: number;
	FDO: number;
	TO: number;
	SAI: number;
	EFIG: number;
}

export interface IIncomingPlayer {
	firstName: string;
	lastName: string;
	id: number;
	position: "HOK" | "EDG" | "HLF" | "MID" | "WFB" | "CTR";
	squadId: number;
	gender: GenderType;
	stats: {
		percentSelections: number;
		averagePoints: number;
		totalPoints: number;
	};
	status:
		| "playing"
		| "not-playing"
		| "injured"
		| "uncertain"
		| "eliminated"
		| "suspended"
		| "reserve"
		| "emergency";
	nrlProfileLink: string;
}

export const fetchPlayers = createAction("Fetch Players");
export const fetchPlayersSuccess = createAction<IIncomingPlayer[]>("Fetch Players Success");
export const fetchPlayersFailed = createAction("Fetch Players Failed");
export const fetchPlayersPreseason = createAction("Fetch Players");
export const fetchPlayersPreseasonSuccess =
	createAction<IIncomingPlayer[]>("Fetch Players Success");
export const fetchPlayersPreseasonFailed = createAction("Fetch Players Failed");

export const fetchPlayerStats = createAction("Fetch Players Stats");
export const fetchPlayerStatsSuccess = createAction<Record<string, IPlayerDisplayStats>>(
	"Fetch Players Stats Success"
);
export const fetchPlayerStatsFailed = createAction("Fetch Players Stats Failed");
