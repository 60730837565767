import {SagaIterator} from "redux-saga";
import {all, takeLatest} from "redux-saga/effects";
import {
	autoFillTeam,
	createLeague,
	fetchFaq,
	fetchGameRules,
	fetchLeagueMonthlyRankings,
	fetchLeagueRankings,
	fetchLeagueTableRankings,
	fetchMoreLeagueMonthlyRankings,
	fetchMoreLeagueRankings,
	fetchMoreLeagueTableRankings,
	fetchMoreRankings,
	fetchNotificationBar,
	fetchPlayers,
	fetchPlayersPreseason,
	fetchPlayerStats,
	fetchPrizes,
	fetchRankings,
	fetchRounds,
	fetchSchedule,
	fetchSquads,
	fetchTerms,
	globalError,
	hideReactivateModal,
	inviteToLeague,
	joinToLeague,
	leaveLeague,
	loadMoreLeaguesForJoin,
	loadMoreShowJoined,
	postContact,
	removeFromLeague,
	requestCurrentUser,
	requestMyTeam,
	saveTeam,
	searchLeaguesForJoin,
	showJoined,
	showLeague,
	showLeaguesForJoin,
	showMyLeagues,
	subscribeToLiveScores,
	updateLeague,
	userAuthCodeLogin,
	userDeactivate,
	userInfoUpdate,
	userLogout,
	userPreregister,
	userRegister,
	userTeamName,
	userUpdate,
} from "modules/actions";
import {errorsManagerSaga} from "modules/sagas/errors";
import {
	authCodeSaga,
	checkTeamNamerSaga,
	deactivateUserSaga,
	getCurrentUserSaga,
	logoutSaga,
	preregisterSaga,
	registerSaga,
	updateUserDetailsSaga,
	updateUserSaga,
	userReactivateSaga,
} from "modules/sagas/user";
import {
	requestFaqJSONSaga,
	requestGameRulesJSONSaga,
	requestPrizesJSONSaga,
	requestScheduleJSONSaga,
	requestTermsJSONSaga,
	requestSquadsJSONSaga,
	requestPlayersJSONSaga,
	requestRoundsJSONSaga,
	fetchChecksumsSaga,
	requestPlayerStatsJSONSaga,
	fetchNotificationBarSaga,
	requestPlayersPreseasonJSONSaga,
} from "modules/sagas/json";
import {postContactSaga} from "modules/sagas/contact";
import {
	createLeagueSaga,
	joinToLeagueSaga,
	leagueMonthlyRankingsSaga,
	leagueRankingsSaga,
	leaveLeagueSaga,
	removeFromLeagueSaga,
	loadMoreJoinedSaga,
	loadMoreLeagueMonthlyRankingsSaga,
	loadMoreLeagueRankingsSaga,
	loadMoreLeaguesForJoinSaga,
	searchLeaguesForJoinSaga,
	sendLeagueInvitesSaga,
	showJoinedSaga,
	showLeagueSaga,
	showLeaguesForJoinSaga,
	showMyLeaguesSaga,
	updateLeagueSaga,
	overallRankingsSaga,
	loadMoreOverallRankingsSaga,
	leagueTableRankingsSaga,
	loadMoreLeagueTableRankingsSaga,
} from "modules/sagas/leagues";
import {autofillTeamSaga, saveTeamSaga, showMyTeamSaga} from "./team";
import {fetchLiveScoresSaga} from "./liveScoring";
import {fetchChecksums} from "modules/actions/checksums";

/**
 * Root saga that connect sagas with actions.
 */
export const rootSaga = function* (): SagaIterator {
	yield all([
		/**
		 * JSON
		 */

		takeLatest(fetchFaq, requestFaqJSONSaga),
		takeLatest(fetchGameRules, requestGameRulesJSONSaga),
		takeLatest(fetchPrizes, requestPrizesJSONSaga),
		takeLatest(fetchTerms, requestTermsJSONSaga),
		takeLatest(fetchSchedule, requestScheduleJSONSaga),
		takeLatest(fetchSquads, requestSquadsJSONSaga),
		takeLatest(fetchPlayers, requestPlayersJSONSaga),
		takeLatest(fetchPlayersPreseason, requestPlayersPreseasonJSONSaga),
		takeLatest(fetchPlayerStats, requestPlayerStatsJSONSaga),
		takeLatest(fetchRounds, requestRoundsJSONSaga),
		takeLatest(fetchNotificationBar, fetchNotificationBarSaga),

		/**
		 * Team
		 */
		takeLatest(saveTeam, saveTeamSaga),
		takeLatest(requestMyTeam, showMyTeamSaga),
		takeLatest(autoFillTeam, autofillTeamSaga),
		/**
		 * Common
		 */
		takeLatest(globalError, errorsManagerSaga),
		takeLatest(postContact, postContactSaga),

		/**
		 * Auth
		 */
		takeLatest(userAuthCodeLogin, authCodeSaga),
		takeLatest(userRegister, registerSaga),
		takeLatest(userLogout, logoutSaga),

		/**
		 * User
		 */

		takeLatest(userTeamName, checkTeamNamerSaga),
		takeLatest(requestCurrentUser, getCurrentUserSaga),
		takeLatest(userUpdate, updateUserSaga),
		takeLatest(userInfoUpdate, updateUserDetailsSaga),
		takeLatest(userDeactivate, deactivateUserSaga),
		takeLatest(userPreregister, preregisterSaga),
		takeLatest(hideReactivateModal, userReactivateSaga),

		/**
		 * Leagues
		 */
		takeLatest(createLeague, createLeagueSaga),
		takeLatest(inviteToLeague, sendLeagueInvitesSaga),
		takeLatest(showMyLeagues, showMyLeaguesSaga),
		takeLatest(showJoined, showJoinedSaga),
		takeLatest(loadMoreShowJoined, loadMoreJoinedSaga),
		takeLatest(showLeaguesForJoin, showLeaguesForJoinSaga),
		takeLatest(searchLeaguesForJoin, searchLeaguesForJoinSaga),
		takeLatest(loadMoreLeaguesForJoin, loadMoreLeaguesForJoinSaga),
		takeLatest(joinToLeague, joinToLeagueSaga),
		takeLatest(showLeague, showLeagueSaga),
		takeLatest(leaveLeague, leaveLeagueSaga),
		takeLatest(removeFromLeague, removeFromLeagueSaga),
		takeLatest(updateLeague, updateLeagueSaga),

		/**
		 * Rankings
		 */
		takeLatest(fetchLeagueRankings, leagueRankingsSaga),
		takeLatest(fetchMoreLeagueRankings, loadMoreLeagueRankingsSaga),
		takeLatest(fetchLeagueMonthlyRankings, leagueMonthlyRankingsSaga),
		takeLatest(fetchMoreLeagueMonthlyRankings, loadMoreLeagueMonthlyRankingsSaga),

		takeLatest(fetchRankings, overallRankingsSaga),
		takeLatest(fetchMoreRankings, loadMoreOverallRankingsSaga),

		takeLatest(fetchLeagueTableRankings, leagueTableRankingsSaga),
		takeLatest(fetchMoreLeagueTableRankings, loadMoreLeagueTableRankingsSaga),

		/**
		 * Checksums
		 */
		takeLatest(subscribeToLiveScores, fetchLiveScoresSaga),
		takeLatest(fetchChecksums, fetchChecksumsSaga),
	]);
};

export * from "./errors";
