export const ENV = process.env.REACT_APP_ENVIRONMENT;
export const BASE_URL = process.env.REACT_APP_BASE_URL || "";
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL || "";
export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const FLAGS_URL = process.env.REACT_APP_FLAGS_URL || "";
export const SHARE_URL = process.env.REACT_APP_SHARE_URL || "";
export const SITE_TITLE = process.env.REACT_APP_NAME || "";

export const PLAYER_IMAGES_URL = `${IMAGES_URL}player/`;

export const STORAGE_VERSION = 1;
export const EMAIL_REGEXP = "\\S+@\\S+\\.\\S+";
export const PASSWORD_PATTERN =
	"^((?=.*?\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[-!@#$%^&*()_+|~=`{}\\[\\]:\";'<>?,.\\/])).{8,}$";

export const LINEUP_SIZE = 6;
export const DEFAULT_CAP_SIZE = 2;
export const MAX_INVITES_SIZE = 5;
export const OVERALL_LEAGUE_ID = 1;
export const IS_LEAGUE_MANAGE_DISABLED = true;
export const SHOW_INITIAL_MODAL = "showInitialModal";

export const REACT_APP_SSO_URL = process.env.REACT_APP_SSO_URL || "";
export const REACT_APP_SSO_CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID || "";
export const REACT_APP_SSO_REDIRECT_URI = process.env.REACT_APP_SSO_REDIRECT_URI || "";
export const REACT_APP_LOGOUT_LINK = process.env.REACT_APP_LOGOUT_LINK || "/";

const isSecretePassed = sessionStorage.getItem("isSecretPassed");
const IS_SECRET_ENABLED = false;
export const IS_SECRET_PASSED = isSecretePassed
	? JSON.parse(isSecretePassed) === true
	: !IS_SECRET_ENABLED;
