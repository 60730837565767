import {createAction} from "redux-act";
import {MatchStatus, RoundStatus} from "modules/enums";

export interface ICourse {
	name: string;
	city: string;
	country: string;
}

export interface IRound {
	id: number;
	name: string;
	dateStart: string;
	dateEnd: string;
	isMajor: boolean;
	isWgc: boolean;
	isMatchPlay: boolean;
	fieldSize?: number;
	country: string;
	status: RoundStatus;
	courses?: ICourse[];
}

export interface IMatch {
	id: number;
	sport: string;
	type: "men" | "women";
	venueId: number;
	date: string;
	homeSquadId: number;
	awaySquadId: number;
	homeScore: number | null;
	awayScore: number | null;
	status: MatchStatus;
}

export interface IRound {
	id: number;
	status: RoundStatus;
	startDate: string;
	endDate: string;
	nrlRdNo: string | number;
	nrlwRdNo: string | number;
	units: IMatch[];
}

export const fetchRounds = createAction("Fetch Rounds");
export const fetchRoundsSuccess = createAction<IRound[]>("Fetch Rounds Success");
export const fetchRoundsFailed = createAction("Fetch Rounds Failed");

export const setViewedRoundID = createAction<number | null>();
export const setViewedRankingRoundID = createAction<number | string | null>();
export const setViewedLeagueRankingRoundID = createAction<number | string | null>();
export const switchEventStatusToLive = createAction<number>();
