import styled from "styled-components";
import React, {AllHTMLAttributes} from "react";
import TextField, {TextFieldProps} from "@mui/material/TextField";
import SelectMUI from "@mui/material/Select";
import {FormControl} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckIcon from "@mui/icons-material/Check";
import {SelectProps} from "@mui/material/Select/Select";
import {Exist} from "components/Exist";
import media from "assets/css/media";

export const FormField = styled.div`
	margin-bottom: 20px;
`;

export const FormFieldNoAst = styled.div`
	margin-bottom: 20px;
	.css-wgai2y-MuiFormLabel-asterisk {
		display: none;
	}
`;

const BaseCheckboxContainer = styled.label`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor: pointer;
	background-color: #fff;
	border: 0;
	min-width: 25px;
	width: 25px;
	height: 25px;
	box-sizing: border-box;
	border-radius: 4px;

	svg {
		color: transparent;
		font-size: 20px;
	}

	input {
		position: absolute;
		margin: 0;
		padding: 0;
		opacity: 0;
		cursor: pointer;

		&:checked + svg {
			color: rgba(0, 0, 0, 0.87);
		}

		&:disabled {
			opacity: 0.75;
			cursor: default;
		}
	}
`;

const BaseCheckbox: React.FC<AllHTMLAttributes<HTMLInputElement>> = ({id, className, ...rest}) => (
	<BaseCheckboxContainer className={className} htmlFor={id}>
		<input id={id} {...rest} />
		<CheckIcon />
	</BaseCheckboxContainer>
);

const BaseLabel = styled.label`
	text-align: left;
	font-size: 12px;
	line-height: 17px;
	font-weight: 300;
`;

export const Label = styled(BaseLabel)`
	cursor: pointer;
`;

export const RadioButton = styled(BaseCheckbox)``;

RadioButton.defaultProps = {
	type: "radio",
};

export const Checkbox = styled(BaseCheckbox)``;

Checkbox.defaultProps = {
	type: "checkbox",
};

export const SelectFormControl = styled(FormControl)`
	.MuiFilledInput-root {
		background: #fff;
	}
	.MuiFilledInput-root::before {
		border: none;
	}
	&.MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
		color: var(--baseBlack);
	}
`;

SelectFormControl.defaultProps = {
	fullWidth: true,
	size: "small",
};

export const SelectStyled = styled(SelectMUI)`
	color: #a3a3a3;
	border-radius: 4px;
	height: 54px;

	&.MuiFilledInput-root::before,
	&.MuiFilledInput-root::after {
		border-radius: 3px;
	}
`;

SelectStyled.defaultProps = {
	size: "small",
	fullWidth: true,
};

export const SelectWrap = styled.div`
	background-color: #fff;
	min-width: 220px;
	max-width: 220px;
	width: 100%;
	border-radius: 4px;
	height: 54px;
	fieldset {
		// border: none;
	}

	@media (max-width: 680px) {
		max-width: 100%;
	}
`;

export const SelectWrapFullWidth = styled(SelectWrap)`
	max-width: 100%;
	fieldset {
		// border: none;
	}
	@media (max-width: 680px) {
		max-width: 100%;
	}
`;

export const Select: React.FC<SelectProps> = ({
	label = "label",
	defaultValue = "",
	name,
	required,
	children,
	...rest
}) => (
	<SelectFormControl fullWidth>
		<InputLabel required={required} />
		<SelectStyled
			defaultValue={defaultValue}
			name={name}
			required={required}
			IconComponent={KeyboardArrowDownIcon}
			{...rest}>
			{children}
		</SelectStyled>
	</SelectFormControl>
);

export const Input = styled(TextField)`
	background: #fff;
	border-radius: 4px;
	fieldset {
		//border: none;
	}
`;

export const TeamNameInput = styled(TextField)`
	background: #fff;
	border: 1px solid var(--baseBlack);
	border-radius: 4px;
`;

Input.defaultProps = {
	fullWidth: true,
};

export const InputYellowBorder = styled(TextField)`
	border-radius: 3px;
	input {
		color: #fff;
		height: 23px;
	}
`;

export const ErrorText = styled.p`
	color: var(--accent2);
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.5px;
	line-height: 18px;
	margin-bottom: 20px;
`;

export const FormTextField = styled(TextField)`
	width: 50%;
	background-color: #fff;
	border-radius: 5px;
	@media (max-width: ${media.mobile}) {
		width: 100%;
	}
`;

export const ErrorMessage = styled.p`
	font-size: 14px;
	line-height: 17px;
	margin-top: 2px;
	color: #d32f2f;
`;

export const FormInput: React.FC<TextFieldProps> = ({
	required = true,
	variant = "filled",
	...rest
}) => (
	<FormField>
		<FormTextField required={required} variant={variant} {...rest} />
		<Exist when={rest.inputProps?.errorplace === rest.name}>
			<ErrorMessage>{rest.inputProps?.errortext}</ErrorMessage>
		</Exist>
	</FormField>
);
