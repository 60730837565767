import {ComponentType} from "react";
import Storage from "modules/utils/Storage";
import {
	REACT_APP_SSO_CLIENT_ID,
	REACT_APP_SSO_REDIRECT_URI,
	REACT_APP_SSO_URL,
} from "modules/constants";
import {ITeam} from "modules/actions";

export * from "./Api";
export * from "./hooks";
export * from "./Storage";
export * from "./socialShare";
export * from "./Countdown";

type Factory<T> = () => Promise<{
	default: ComponentType<T>;
}>;

export function retryFailLoad<T>(fn: Factory<T>, retriesLeft = 5, interval = 1000): Factory<T> {
	return () =>
		new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error: unknown) => {
					setTimeout(() => {
						if (retriesLeft === 1) {
							reject(error);
							return;
						}

						retryFailLoad(fn, retriesLeft - 1, interval)().then(resolve, reject);
					}, interval);
				});
		});
}

export const allTrue = (items: boolean[]) => items.every((val) => val === true);
export const someTrue = (items: boolean[]) => items.some((val) => val);
export const someFalse = (items: boolean[]) => items.some((val) => val === false);
export const formatName = (name: string | null | undefined) => {
	if (!name) {
		return "";
	}
	const arrString = Array.from(name);
	const initial = arrString[0];
	const lastName = name.split(" ").slice(1).join(" ");
	return `${initial}. ${lastName}`;
};

export const getLoginLink = () => {
	const code_challenge = Storage.GET("code_challenge") as string;
	const nonce = Storage.GET("nonce") as string;
	const myState = Storage.GET("myState") as string;

	const params = [
		`client_id=${REACT_APP_SSO_CLIENT_ID}`,
		`redirect_uri=${REACT_APP_SSO_REDIRECT_URI}`,
		"grant_type=authorization_code",
		`code_challenge=${code_challenge}`,
		`nonce=${nonce}`,
		`state=${myState}`,
		"code_challenge_method=S256",
		"response_type=code",
		"scope=openid email profile offline_access country",
	];

	return `${REACT_APP_SSO_URL}/account/authorize?${params.join("&")}`;
	// return `${REACT_APP_SSO_URL}/account/authorize?client_id=${REACT_APP_SSO_CLIENT_ID}
	//&redirect_uri=${REACT_APP_SSO_REDIRECT_URI}&grant_type=authorization_code&
	//code_challenge=${code_challenge}&state=${myState}&code_challenge_method=S256&
	//response_type=code&scope=openid email profile offline_access`;
};

export const isOdd = (n: number) => Math.abs(n % 2) === 1;
export const BLANK_TEAM = {
	1: [0],
	2: [0],
	3: [0],
	4: [0],
	5: [0],
	6: [0],
};

export const getLineupIDs = (team: ITeam | null) => {
	if (team !== null) {
		return [
			...team.lineup[1],
			...team.lineup[2],
			...team.lineup[3],
			...team.lineup[4],
			...team.lineup[5],
			...team.lineup[6],
		].filter((item) => item > 0);
	}
	return [];
};
