import React, {useCallback, useState} from "react";
import {ButtonsBox, ModalContainer, ModalContent, Text, Title} from "components/Modals/common";
import {useDispatch, useSelector} from "react-redux";
import {ButtonPrimary} from "components";
import styled from "styled-components";
import {Checkbox, FormControlLabel} from "@mui/material";
import {getIsOpenReactivateModal, getUser} from "modules/selectors";
import {hideReactivateModal} from "modules/actions";
import {get} from "lodash";

const FormControlLabelStyled = styled(FormControlLabel)`
	align-self: flex-start;
`;

const Modal = styled.div`
	padding: 10px;
	width: 100%;
	background: #fff;
	margin: 0 10%;
	box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
	z-index: 51;
	position: relative;

	@media (min-width: 769px) {
		height: auto;
		max-width: 570px;
		min-height: 200px;
	}
`;

const ReactivateTitle = styled(Title)`
	line-height: inherit;
	margin-top: 15px;
`;

const Description = styled(Text)`
	margin-bottom: 10px;
`;

const ReactivateButton = styled(ButtonPrimary)`
	background-color: ##fdda25;
`;

const Label = styled.p`
	padding-left: 3px;
	font-size: 14px;
	text-align: left;
	font-family: "Proxima Nova", sans-serif;
`;

const ReactivateLink = styled.a`
	font-size: 14px;
	text-decoration: underline;
	color: #343232;
`;

const ReactivateButtonsBox = styled(ButtonsBox)`
	padding: 20px;
`;

const CheckoutLabel: React.FC = () => {
	return (
		<Label>
			I have read and accepted the NRL Bundy Mixer{" "}
			<ReactivateLink
				href="https://mixer.nrl.com/help/terms"
				target="_blank"
				rel="noreferrer">
				Terms and Conditions
			</ReactivateLink>
			*
		</Label>
	);
};

const checkBoxStyle = {
	"&.Mui-checked": {
		color: "##fdda25",
	},
};

export const ReactivateModal: React.FC = () => {
	const [form, updateForm] = useState({terms: false, NZRUOptin: false, rugbyAuOptin: false});
	const [isDisabled, setDisableButton] = useState(true);

	const dispatch = useDispatch();
	const open = useSelector(getIsOpenReactivateModal);
	const user = useSelector(getUser);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, name} = e.currentTarget;

		if (name === "terms") {
			setDisableButton(!checked);
		}

		updateForm({
			...form,
			[name]: checked,
		});
	};

	const closeHandler = useCallback(() => {
		dispatch(
			hideReactivateModal({
				teamName: get(user, "teamName", ""),
				terms: get(user, "terms", false),
			})
		);
	}, [dispatch, user]);

	if (!open) return null;

	return (
		<ModalContainer>
			<Modal>
				<ModalContent>
					<ReactivateTitle>REACTIVATE YOUR NRL BUNDY MIXER ACCOUNT</ReactivateTitle>
					<Description>
						Welcome back! As you have previously registered for Bundy Mixer you will be
						using the same account, please accept the Terms and Conditions below to
						continue.
					</Description>
					<FormControlLabelStyled
						control={
							<Checkbox name="terms" onChange={handleOnChange} sx={checkBoxStyle} />
						}
						label={<CheckoutLabel />}
					/>
					<ReactivateButtonsBox>
						<ReactivateButton disabled={isDisabled} onClick={closeHandler}>
							REACTIVATE{" "}
						</ReactivateButton>
					</ReactivateButtonsBox>
				</ModalContent>
			</Modal>
		</ModalContainer>
	);
};
