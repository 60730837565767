import React, {useCallback, useState} from "react";
import styled from "styled-components";
import {Exist, Timer} from "components";
import {DateTime} from "luxon";
import media from "assets/css/media";
import {useSelector} from "react-redux";
import {getActualRound, getTradesDeadlineTime} from "modules/selectors";

const Wrapper = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;

	h3 {
		color: #000;
		font-size: 30px;
		letter-spacing: 0;
		line-height: 30px;
		text-align: center;
		font-weight: normal;
		text-transform: uppercase;
		margin: 6px 0 12px 0;
	}

	h4 {
		color: #000;
		font-size: 18px;
		letter-spacing: 0;
		line-height: 30px;
		text-align: center;
		font-weight: normal;
		text-transform: uppercase;
		margin: 0;

		span {
			margin-right: 12px;
		}
	}

	@media (max-width: ${media.mobile}) {
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;

		h3 {
			font-size: 14px;
			line-height: 14px;
			margin: 4px 0;
		}
	}
`;

const NextRoundText = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 22px;
	text-align: center;
	margin-left: 10px;
	color: #999999;
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase;
	@media (max-width: ${media.mobile}) {
		font-size: 10px;
	}
`;

const LockoutSpan = styled.span`
	font-size: 16px;
	height: 22px;
	line-height: 22px;
	font-weight: 500;
	text-transform: capitalize;
`;

export const LockoutTimer: React.FC = () => {
	// const dispatch = useDispatch();
	const [isStarting, setIsStarting] = useState(false);
	const tradesDeadlineTime = useSelector(getTradesDeadlineTime);
	const actualRound = useSelector(getActualRound);
	const startDate = DateTime.fromISO(actualRound?.startDate || "");
	const someDate = DateTime.fromJSDate(new Date());
	const isActualRoundBeforeCurrentDate = startDate.startOf("day") < someDate.startOf("day");
	const isDeadlineTime = typeof tradesDeadlineTime === "number";
	const hasActiveRound = false;
	console.log(isStarting, isActualRoundBeforeCurrentDate);
	const onTradesDeadline = useCallback(() => {
		setIsStarting(true);
	}, []);

	/*
	if (isActualRoundBeforeCurrentDate) {
		return null;
	}
	*/

	return (
		<Wrapper className="lockout-timer">
			<NextRoundText>Locks In</NextRoundText>
			<Exist when={hasActiveRound}>
				<h3>In Progress</h3>
			</Exist>
			<Exist when={!isDeadlineTime}>
				<LockoutSpan>{tradesDeadlineTime}</LockoutSpan>
			</Exist>
			<Exist when={isDeadlineTime}>
				<Timer date={tradesDeadlineTime} onComplete={onTradesDeadline} />
			</Exist>
		</Wrapper>
	);
};
