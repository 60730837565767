import {createAction} from "redux-act";

export interface IInvitesModal {
	success: boolean;
	error?: string;
}
export const showInvitesModal = createAction<IInvitesModal>();
export const hideInvitesModal = createAction();

export const hideWelcomeModal = createAction();

export const showReactivateModal = createAction();

export interface IReactivatePayload {
	teamName: string | null;
	terms: boolean | null;
	promotion_opt_in?: boolean;
}

export const hideReactivateModal = createAction<IReactivatePayload>();

export const reactivateCurrentUserSuccess = createAction();
export const reactivateCurrentUserFailed = createAction();

export const setLastActiveElement = createAction<Element | null>();
