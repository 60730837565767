import {createAction} from "redux-act";
import {IUser} from "modules/reducers";
import {ApiError} from "modules/utils";

export interface IAuthCodePayload {
	code: string;
	codeVerifier?: string | null;
	// nonce?: string | null;
	redirectUrl?: string | null;
}

export interface IUserLoginPayload {
	email: string;
	password: string;
}

export const userAuthCodeLogin = createAction<IAuthCodePayload>();
export const userAuthCodeSuccess = createAction<IUser>();
export const userAuthCodeFailed = createAction<ApiError>();

export const userLogin = createAction<IUserLoginPayload>();
export const userLoginSuccess = createAction<IUser>();
export const userLoginFailed = createAction<ApiError>();

export interface IUserUpdatePayload {
	teamName?: string;
	optIn?: boolean;
	isNotificationsEnabled?: boolean;
}

export const userTeamName = createAction<IUserUpdatePayload>();
export const userTeamNameSuccess = createAction<Record<string, unknown>>();
export const userTeamNameFailed = createAction<ApiError>();

export const userUpdate = createAction<IUserUpdatePayload>();
export const userUpdateSuccess = createAction<IUser>();
export const userUpdateFailed = createAction<ApiError>();

export const userDeactivate = createAction();
export const userDeactivateSuccess = createAction();
export const userDeactivateFailed = createAction<ApiError>();

type IUserInfoUpdatePayload = Pick<IUserUpdatePayload, "teamName" | "isNotificationsEnabled">;

export const userInfoUpdate = createAction<IUserInfoUpdatePayload>();
export const markUserInfoAsUpdated = createAction();
export const unMarkUserInfoAsUpdated = createAction();

export const userLogout = createAction();
export const userLogoutSuccess = createAction();
export const userLogoutFailed = createAction<ApiError>();

export interface IUserRegisterPayload {
	teamName: string;
	terms: boolean;
	promotion_opt_in: boolean;
	code: string;
	optIn: boolean;
	redirectUrl: string;
	codeVerifier: string | null;
}

export const userRegister = createAction<IUserRegisterPayload>();
export const userRegisterSuccess = createAction<IUser>();
export const userRegisterFailed = createAction<ApiError>();

export interface IUserPreregister {
	email: string;
	firstName: string;
}

export const userPreregister = createAction<IUserPreregister>();
export const userPreregisterSuccess = createAction();
export const userPreregisterFailed = createAction<ApiError>();

export const userClearError = createAction();
export const resetUserRequestState = createAction();

export const requestCurrentUser = createAction();
export const requestCurrentUserSuccess = createAction<IUser>();
export const requestCurrentUserFailed = createAction();

export const setGodMode = createAction();
