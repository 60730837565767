import React, {useState} from "react";
import {Container, ExternalLink, InternalLink} from "components/Common";
import styled from "styled-components";
import geniusLogoLight from "assets/img/genius-logo-light.svg";
import media from "assets/css/media";
import iconFacebook from "assets/img/icon-facebook.svg";
import iconInstagram from "assets/img/icon-insta.svg";
import iconTwitter from "assets/img/icon-twitter.svg";
import plus18 from "assets/img/plus18.png";
import {useDispatch} from "react-redux";
import {setGodMode} from "modules/actions";
import {Input} from "@mui/material";
import {Exist} from "components/Exist";

const FooterWrap = styled.div`
	background-color: var(--baseBlack);
	display: flex;
	height: 160px;
	width: 100%;
	padding: 40px 0 20px;
	@media (max-width: ${media.mobile}) {
		height: 205px;
	}
`;

const FooterContainer = styled(Container)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const MiddleBox = styled.div`
	display: flex;
	input {
		background: white;
		color: black;
	}
	@media (max-width: ${media.small_mobile}) {
		flex-direction: column;
		text-align: center;
	}
`;

const IconWrap = styled.div`
	display: flex;
	margin: 0 0 0 10px;
	@media (max-width: ${media.small_mobile}) {
		margin: 0 0 10px 0;
		flex-direction: row;
		margin: 10px auto 15px;
	}
`;

const BottomBox = styled.div`
	display: flex;
	justify-content: space-between;

	@media (max-width: ${media.mobile}) {
		flex-flow: column;
		height: 100px;
	}
`;

const Nav = styled.nav`
	display: flex;
	font-size: 12px;
	border-top: 1px solid;
	border-color: var(--yellowTint);
	opacity: 0.4;
	flex: 1;
	margin: auto 20px 0 0;
	a {
		margin-top: 10px;
	}
	@media (max-width: ${media.mobile}) {
		margin: auto 0 0 0;
		a {
			margin-top: 20px;
		}
		justify-content: center;
		&:hover,
		&:focus {
			background-size: 0 1px;
		}
	}
`;

const NavLink = styled(InternalLink)`
	display: inline-block;
	margin-left: 15px;
	color: var(--textColor2);
	margin-bottom: 5px;
	background-size: 0 1px;

	&:hover,
	&:focus {
		background-size: 100% 1px;
	}
`;

const ExtLink = styled(ExternalLink)`
	color: var(--textColor2);
	background-size: 0 1px;

	&:hover,
	&:focus {
		background-size: 100% 1px;
	}
`;

const SocialLink = styled(ExternalLink)`
	border: 1px solid #65570f;
	height: 36px;
	width: 36px;
	margin: 0 2px;
`;

const FHLogoLink = styled(ExternalLink)`
	transition: opacity 0.5s;

	&:hover {
		opacity: 0.5;
	}
	@media (max-width: ${media.mobile}) {
		text-align: center;
	}
	@media (max-width: ${media.small_mobile}) {
		margin: 10px 0;
	}
`;

const FollowText = styled.div`
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 0.875px;
	text-transform: uppercase;
	color: var(--textColor2);
	height: 36px;
	line-height: 36px;
	@media (max-width: ${media.mobile}) {
		margin-top: 20px;
	}
`;

const Plus18Wrap = styled.div`
	margin: auto auto auto 15px;
	width: 30px;
	text-align: center;
	@media (max-width: ${media.small_mobile}) {
		margin: auto 0 auto 15px;
	}
`;

const StyledSocialLink = styled(SocialLink)`
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		height: 20px;
		width: 20px;
	}
`;

const SECRET_ANSWER = "please let me in";
const IS_SECRET_ENABLED = false;

const IS_OFF_SEASON = (process.env.REACT_APP_IS_OFFSEASON || "") === "true";
const IS_GOD_MODE = localStorage.getItem("god_mode") === "true";

export const isShowOffSeaonPage = IS_OFF_SEASON && !IS_GOD_MODE;

export const Footer: React.FC = () => {
	const dispatch = useDispatch();
	const [clickCount, setClickCount] = useState(0);
	const [inputField, setInputField] = useState({
		show: false,
		current: "",
	});

	const handleClick = () => {
		if (!IS_SECRET_ENABLED) {
			return;
		}
		setClickCount(clickCount + 1);
		if (clickCount + 1 === 10) {
			setInputField({
				...inputField,
				show: true,
			});
		}
	};

	const handleTyping = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputField({
			...inputField,
			current: e.target.value,
		});
		if (e.target.value === SECRET_ANSWER) {
			dispatch(setGodMode());
		}
	};

	return (
		<FooterWrap>
			<FooterContainer>
				<MiddleBox>
					{clickCount === 10 ? (
						<Input value={inputField.current} onChange={handleTyping} />
					) : (
						<FollowText onClick={handleClick}>Follow NRL Fantasy</FollowText>
					)}

					<IconWrap>
						<SocialLink href="https://www.facebook.com/nrlfantasy/">
							<img src={iconFacebook} alt="NRL Fantasy Facebook" />
						</SocialLink>
						<StyledSocialLink href="https://x.com/nrlfantasy">
							<img src={iconTwitter} alt="NRL Fantasy Twitter" />
						</StyledSocialLink>
						<SocialLink href="https://www.instagram.com/nrlfantasy/">
							<img src={iconInstagram} alt="NRL Fantasy Instagram" />
						</SocialLink>
						<Exist when={!isShowOffSeaonPage}>
							<Plus18Wrap>
								<img src={plus18} alt="18+" />
							</Plus18Wrap>
						</Exist>
					</IconWrap>
				</MiddleBox>
				<BottomBox>
					<Nav>
						<ExtLink href="https://www.nrl.com/privacy-policy">Privacy</ExtLink>
						<NavLink to={isShowOffSeaonPage ? "/" : "/help/terms"}>
							Terms & Conditions
						</NavLink>
						<NavLink to={isShowOffSeaonPage ? "/" : "/help/faqs"}>FAQ</NavLink>
						<NavLink to={isShowOffSeaonPage ? "/" : "/help/rules"}>
							Game Guidelines
						</NavLink>
					</Nav>
					<FHLogoLink href="https://geniussports.com/">
						<img src={geniusLogoLight} alt="Genius Sports" />
					</FHLogoLink>
				</BottomBox>
			</FooterContainer>
		</FooterWrap>
	);
};
