import {all, call, delay, race, select, take} from "typed-redux-saga";

import {differenceBy, first, flow, get, identity, isEmpty, last, partial, toPairs} from "lodash";

import {unsubscribeFromLiveScores} from "modules/actions";
import {getChecksums} from "modules/selectors";

import {IDictionary} from "modules/types";
import {
	fetchChecksumsSaga,
	requestRoundsJSONSaga,
	requestSquadsJSONSaga,
	requestPlayersJSONSaga,
} from "modules/sagas/json";

const mapChecksumsToSaga = {
	rounds: requestRoundsJSONSaga,
	squads: requestSquadsJSONSaga,
	players: requestPlayersJSONSaga,
};

const WAIT = 31000; // Half minute and one second

export const fetchLiveScoresSaga = function* (): unknown {
	const {stopped} = yield* race({
		wait: delay(WAIT), // Wait for a half minute and one second
		stopped: take(unsubscribeFromLiveScores),
	});

	if (!stopped) {
		const [old_checksums] = yield* all([select(getChecksums), call(fetchChecksumsSaga)]);

		const new_checksums = yield* select(getChecksums);
		const requestsForChanges = differenceBy(
			toPairs(old_checksums as IDictionary<string>),
			toPairs(new_checksums),
			last
		)
			.map(flow([first, partial(get, mapChecksumsToSaga)]))
			.filter(identity);

		if (!isEmpty(requestsForChanges)) {
			yield* all(requestsForChanges.map((request) => call(request)));
		}

		yield* call(fetchLiveScoresSaga);
	}
};
