import {
	hideInvitesModal,
	hideReactivateModal,
	hideWelcomeModal,
	setLastActiveElement,
	showInvitesModal,
	showReactivateModal,
} from "modules/actions/modals";
import {createReducer} from "redux-act";
import {Storage} from "modules/utils";
import {SHOW_INITIAL_MODAL} from "modules/constants";

const defaultState = {
	inviteSuccessModal: {
		isShow: false,
		error: "",
		success: false,
	},
	genericModal: {
		isShow: false,
	},
	welcomeModal: {
		isShow: JSON.parse(Storage.GET(SHOW_INITIAL_MODAL) || "true") as boolean,
	},
	showReactivateModal: false,
	lastActiveElement: null,
};

export interface IModalsReducer {
	inviteSuccessModal: {
		isShow: boolean;
		success: boolean;
		error?: string;
	};
	genericModal: {
		isShow: boolean;
	};
	welcomeModal: {
		isShow: boolean;
	};
	showReactivateModal?: boolean;
	lastActiveElement: Element | null;
}

const onHideWelcomeModal = (state: IModalsReducer) => ({
	...state,
	welcomeModal: {
		isShow: false,
	},
});

const onShowReactivateModal = (state: IModalsReducer) => ({
	...state,
	showReactivateModal: true,
});

const onHideReactivateModal = (state: IModalsReducer) => ({
	...state,
	showReactivateModal: false,
});

export const modals = createReducer<IModalsReducer>({}, defaultState)
	.on(showInvitesModal, (state, payload) => ({
		...state,
		...payload,
		inviteSuccessModal: {
			...state.inviteSuccessModal,
			isShow: true,
			success: payload.success,
		},
	}))
	.on(hideInvitesModal, (state) => ({
		...state,
		inviteSuccessModal: {
			...state.inviteSuccessModal,
			isShow: false,
			success: false,
			error: "",
		},
	}))
	.on(setLastActiveElement, (state, payload) => ({
		...state,
		lastActiveElement: payload,
	}))
	.on(hideWelcomeModal, onHideWelcomeModal)
	.on(showReactivateModal, onShowReactivateModal)
	.on(hideReactivateModal, onHideReactivateModal);
