import {call, delay, put, select} from "typed-redux-saga";
import {Api, ApiError} from "modules/utils";
import {
	createLeague,
	createLeagueFailed,
	createLeagueSuccess,
	fetchLeagueMonthlyRankings,
	fetchLeagueRankings,
	fetchLeagueRankingsFailed,
	fetchLeagueRankingsSuccess,
	fetchMoreLeagueMonthlyRankings,
	fetchMoreLeagueRankings,
	fetchRankings,
	fetchRankingsFailed,
	fetchRankingsSuccess,
	fetchMoreRankings,
	inviteToLeague,
	inviteToLeagueFailed,
	inviteToLeagueSuccess,
	joinToLeague,
	joinToLeagueFailed,
	joinToLeagueSuccess,
	leaveLeague,
	leaveLeagueFailed,
	leaveLeagueSuccess,
	loadMoreLeaguesForJoin,
	loadMoreShowJoined,
	removeFromLeague,
	removeFromLeagueFailed,
	removeFromLeagueSuccess,
	searchLeaguesForJoin,
	showGlobalError,
	showJoined,
	showJoinedFailed,
	showJoinedSuccess,
	showLeague,
	showLeagueFailed,
	showLeaguesForJoin,
	showLeaguesForJoinFailed,
	showLeaguesForJoinSuccess,
	showLeagueSuccess,
	showMyLeagues,
	showMyLeaguesFailed,
	showMyLeaguesSuccess,
	updateLeague,
	updateLeagueFailed,
	updateLeagueSuccess,
	fetchLeagueTableRankings,
	fetchLeagueTableRankingsSuccess,
	fetchLeagueTableRankingsFailed,
	fetchMoreLeagueTableRankings,
	ILeagueRankingsResult,
} from "modules/actions/";
import {
	getJoinedInLeague,
	getLeagueRankings,
	getLeaguesForJoin,
	getLeagueTableRankings,
	getOverallRankings,
} from "modules/selectors";

export const createLeagueSaga = function* ({payload}: ReturnType<typeof createLeague>) {
	try {
		const response = yield* call(Api.FantasyLeague.create, payload);
		yield* put(createLeagueSuccess(response.success.league));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(createLeagueFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const updateLeagueSaga = function* ({payload}: ReturnType<typeof updateLeague>) {
	try {
		const response = yield* call(Api.FantasyLeague.update, payload);
		yield* put(updateLeagueSuccess(response.success.league));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(updateLeagueFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const sendLeagueInvitesSaga = function* ({payload}: ReturnType<typeof inviteToLeague>) {
	try {
		yield* call(Api.FantasyLeague.invite, payload);
		yield* put(inviteToLeagueSuccess());
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(inviteToLeagueFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const showMyLeaguesSaga = function* ({payload}: ReturnType<typeof showMyLeagues>) {
	try {
		const response = yield* call(Api.FantasyLeague.showMy, payload);
		yield* put(showMyLeaguesSuccess(response.success));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(showMyLeaguesFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const showJoinedSaga = function* ({payload}: ReturnType<typeof showJoined>) {
	try {
		const response = yield* call(Api.FantasyLeague.showJoined, payload);
		yield* put(showJoinedSuccess(response.success));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(showJoinedFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const loadMoreJoinedSaga = function* ({payload}: ReturnType<typeof loadMoreShowJoined>) {
	try {
		const joined = yield* select(getJoinedInLeague);
		const result = yield* call(Api.FantasyLeague.showJoined, payload);
		yield* put(
			showJoinedSuccess({
				users: [...joined, ...result.success.users],
				nextPage: result.success.nextPage,
			})
		);
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(fetchLeagueRankingsFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const showLeaguesForJoinSaga = function* ({payload}: ReturnType<typeof showLeaguesForJoin>) {
	try {
		const response = yield* call(Api.FantasyLeague.showForJoin, payload);
		yield* put(showLeaguesForJoinSuccess(response.success));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(showLeaguesForJoinFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const searchLeaguesForJoinSaga = function* (
	action: ReturnType<typeof searchLeaguesForJoin>
) {
	yield* delay(150);
	yield* call(showLeaguesForJoinSaga, action);
};

export const loadMoreLeaguesForJoinSaga = function* ({
	payload,
}: ReturnType<typeof loadMoreLeaguesForJoin>) {
	try {
		const leagues = yield* select(getLeaguesForJoin);
		const response = yield* call(Api.FantasyLeague.showForJoin, payload);

		yield* put(
			showLeaguesForJoinSuccess({
				leagues: [...leagues.list, ...response.success.leagues],
				nextPage: response.success.nextPage,
			})
		);
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(showLeaguesForJoinFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const joinToLeagueSaga = function* ({payload}: ReturnType<typeof joinToLeague>) {
	try {
		const response = yield* call(Api.FantasyLeague.joinToLeague, payload);
		yield* put(joinToLeagueSuccess(response.success.league));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(joinToLeagueFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const showLeagueSaga = function* ({payload}: ReturnType<typeof showLeague>) {
	try {
		const response = yield* call(Api.FantasyLeague.show, payload);
		yield* put(showLeagueSuccess(response.success));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(showLeagueFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const leaveLeagueSaga = function* ({payload}: ReturnType<typeof leaveLeague>) {
	try {
		yield* call(Api.FantasyLeague.leave, payload);
		yield* put(leaveLeagueSuccess(payload.id));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(leaveLeagueFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const removeFromLeagueSaga = function* ({payload}: ReturnType<typeof removeFromLeague>) {
	try {
		yield* call(Api.FantasyLeague.remove, payload);
		yield* put(removeFromLeagueSuccess(payload.user_id));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(removeFromLeagueFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const leagueRankingsSaga = function* ({payload}: ReturnType<typeof fetchLeagueRankings>) {
	try {
		const result = yield* call(Api.FantasyLeague.rankings, payload);
		yield* put(fetchLeagueRankingsSuccess(result.success));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(fetchLeagueRankingsFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const overallRankingsSaga = function* ({payload}: ReturnType<typeof fetchRankings>) {
	try {
		const result = yield* call(Api.FantasyLeague.overallRankings, payload);
		yield* put(fetchRankingsSuccess(result.success));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(fetchRankingsFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const leagueTableRankingsSaga = function* ({
	payload,
}: ReturnType<typeof fetchLeagueTableRankings>) {
	try {
		const result = yield* call(Api.FantasyLeague.leagueTableRankings, payload);
		yield* put(fetchLeagueTableRankingsSuccess(result.success));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(fetchLeagueTableRankingsFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const loadMoreLeagueRankingsSaga = function* ({
	payload,
}: ReturnType<typeof fetchMoreLeagueRankings>) {
	try {
		const rankings = yield* select(getLeagueRankings);
		const result = yield* call(Api.FantasyLeague.rankings, payload);
		yield* put(
			fetchLeagueRankingsSuccess({
				currentUserRanking: result.success.currentUserRanking,
				items: [...rankings.items, ...result.success.items],
				ranking: [...rankings.ranking],
				nextPage: result.success.nextPage,
			})
		);
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(fetchLeagueRankingsFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const loadMoreOverallRankingsSaga = function* ({
	payload,
}: ReturnType<typeof fetchMoreRankings>) {
	try {
		const rankings = yield* select(getOverallRankings);
		const result = yield* call(Api.FantasyLeague.overallRankings, payload);
		yield* put(
			fetchRankingsSuccess({
				currentUserRanking: result.success.currentUserRanking,
				items: [...rankings.items, ...result.success.items],
				// ranking: [...rankings.ranking],
				nextPage: result.success.nextPage,
			})
		);
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(fetchLeagueRankingsFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const loadMoreLeagueTableRankingsSaga = function* ({
	payload,
}: ReturnType<typeof fetchMoreLeagueTableRankings>) {
	try {
		const rankings: ILeagueRankingsResult = yield* select(getLeagueTableRankings);
		const result = yield* call(Api.FantasyLeague.leagueTableRankings, payload);
		yield* put(
			fetchLeagueTableRankingsSuccess({
				currentUserRanking: result.success.currentUserRanking,
				items: [...rankings.items, ...result.success.items],
				ranking: [...rankings.ranking],
				nextPage: result.success.nextPage,
			})
		);
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(fetchLeagueRankingsFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const leagueMonthlyRankingsSaga = function* ({
	payload,
}: ReturnType<typeof fetchLeagueMonthlyRankings>) {
	try {
		const result = yield* call(Api.FantasyLeague.monthRankings, payload);
		yield* put(fetchLeagueRankingsSuccess(result.success));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(fetchLeagueRankingsFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const loadMoreLeagueMonthlyRankingsSaga = function* ({
	payload,
}: ReturnType<typeof fetchMoreLeagueMonthlyRankings>) {
	try {
		const rankings = yield* select(getLeagueRankings);
		const result = yield* call(Api.FantasyLeague.monthRankings, payload);
		yield* put(
			fetchLeagueRankingsSuccess({
				currentUserRanking: result.success.currentUserRanking,
				items: [...rankings.items, ...result.success.items],
				ranking: [...rankings.ranking],
				nextPage: result.success.nextPage,
			})
		);
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(fetchLeagueRankingsFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};
