import {createReducer} from "redux-act";
import {
	addPlayerToTeam,
	autoFillTeam,
	autoFillTeamFailed,
	autoFillTeamSuccess,
	clearTeam,
	hidePlayerModal,
	hideSaveTeamSuccessModal,
	ITeam,
	removePlayerFromTeam,
	requestMyTeamSuccess,
	requestShowMySuccess,
	saveTeam,
	saveTeamFailed,
	saveTeamSuccess,
	setCaptain,
	showPlayerModal,
} from "modules/actions";
import {RequestState} from "modules/enums";
import {BLANK_TEAM} from "modules/utils";
import {get} from "lodash";

interface IReducer {
	byRound: Record<number, ITeam>;
	requestState: RequestState;
	hasUnsavedChanges: boolean;
	showDetailsForPlayerID: number;
	showSuccessSaveModal: boolean;
	roundsCreated: number[];
	total_registered_users: number;
}

const defaultState: IReducer = {
	byRound: {},
	requestState: RequestState.IDLE,
	hasUnsavedChanges: false,
	showDetailsForPlayerID: 0,
	showSuccessSaveModal: false,
	roundsCreated: [],
	total_registered_users: 0,
};

const defaultTeam = {
	id: 0,
	name: "",
	lineup: BLANK_TEAM,
	captain_men: 0,
	captain_women: 0,
	start_event_id: 0,
	points: null,
	scoreflow: {},
	selections: {},
	createdAt: "",
};

const onSuccessTeamSave = (state: IReducer) => ({
	...state,
	requestState: RequestState.Received,
	showSuccessSaveModal: true,
});

const onFailedTeamSave = (state: IReducer) => ({
	...state,
	requestState: RequestState.Received,
});

export const team = createReducer<typeof defaultState>({}, defaultState)
	.on(saveTeam, (state) => ({
		...state,
		requestState: RequestState.Requested,
	}))
	.on(autoFillTeam, (state) => ({
		...state,
		requestState: RequestState.Requested,
	}))
	.on(saveTeamSuccess, onSuccessTeamSave)
	.on(saveTeamFailed, onFailedTeamSave)
	.on(autoFillTeamSuccess, onSuccessTeamSave)
	.on(autoFillTeamFailed, onFailedTeamSave)
	.on(hideSaveTeamSuccessModal, (state) => ({
		...state,
		requestState: RequestState.Received,
		showSuccessSaveModal: false,
	}))
	.on(requestShowMySuccess, (state, payload) => {
		const teamsObject = payload.teams.reduce((teamObj, team) => {
			const roundID = team.roundId;
			const femaleCaptain = get(team, "lineup.captain_women", 0) as number;
			const maleCaptain = get(team, "lineup.captain_men", 0) as number;
			return {
				...teamObj,
				[roundID]: {
					...team,
					isCreated: true,
					captain_women: femaleCaptain,
					captain_men: maleCaptain,
				},
			};
		}, {});
		const roundsCreated = payload.teams.map((team) => team.roundId);
		return {
			...state,
			total_registered_users: payload.total_registered_users,
			byRound: teamsObject,
			roundsCreated,
		};
	})
	.on(requestMyTeamSuccess, (state, {team, roundID}) => {
		const femaleCaptain = get(team, "lineup.captain_women", 0) as number;
		const maleCaptain = get(team, "lineup.captain_men", 0) as number;
		return {
			...state,
			hasUnsavedChanges: false,
			roundsCreated: [...new Set([...state.roundsCreated, roundID])],
			byRound: {
				...state.byRound,
				[roundID]: {
					...defaultTeam,
					...team,
					captain_women: femaleCaptain,
					captain_men: maleCaptain,
				},
			},
		};
	})
	.on(clearTeam, (state, roundID) => ({
		...state,
		hasUnsavedChanges: true,
		requestState: RequestState.IDLE,
		byRound: {
			...state.byRound,
			[roundID]: {
				...state.byRound?.[roundID],
				captain_men: 0,
				captain_women: 0,
				lineup: BLANK_TEAM,
			},
		},
	}))
	.on(addPlayerToTeam, (state, {roundID, playerID, positions}) => {
		const team = state.byRound?.[roundID];
		const teamLineup = team ? team.lineup : BLANK_TEAM;
		const firstPosition = positions[0];
		let positionUpdate = firstPosition;

		const isFirstPositionFull = teamLineup[firstPosition as keyof typeof teamLineup][0] !== 0;
		const secondPosition = positions[1];
		const isSecondPositionFull =
			secondPosition && teamLineup[secondPosition as keyof typeof teamLineup][0] !== 0;
		if (positions.length > 1 && isFirstPositionFull && !isSecondPositionFull) {
			positionUpdate = secondPosition;
		}
		return {
			...state,
			hasUnsavedChanges: true,
			requestState: RequestState.IDLE,
			byRound: {
				...state.byRound,
				[roundID]: {
					...team,
					lineup: {
						...teamLineup,
						[positionUpdate]: [playerID],
					},
				},
			},
		};
	})
	.on(removePlayerFromTeam, (state, {roundID, playerID}) => {
		const team = state.byRound?.[roundID];
		const isPlayerWomanCaptain = playerID === team?.captain_women;
		const isPlayerMaleCaptain = playerID === team?.captain_men;
		let index = 0;
		if (team.lineup) {
			Object.keys(team.lineup).forEach((key) => {
				if (Number(key)) {
					const positionArr = team.lineup[Number(key)];
					const isInArr = positionArr.includes(playerID);
					if (isInArr) {
						index = Number(key);
					}
				}
			});
		}

		const newTeam = {
			...team,
			captain_women: isPlayerWomanCaptain ? 0 : team?.captain_women,
			captain_men: isPlayerMaleCaptain ? 0 : team?.captain_men,
			lineup: {
				...team.lineup,
				[index]: [0],
			},
		};

		return {
			...state,
			hasUnsavedChanges: true,
			requestState: RequestState.IDLE,
			byRound: {
				...state.byRound,
				[roundID]: {
					...newTeam,
				},
			},
		};
	})
	.on(setCaptain, (state, {roundID, playerID, isFemale}) => {
		const team = state.byRound?.[roundID];
		const isCaptain = [team.captain_women, team.captain_men].includes(playerID);

		const updatedID = isCaptain ? null : playerID;
		const captainObj = {
			[isFemale ? "captain_women" : "captain_men"]: updatedID,
		};

		const newTeam = {
			...team,
			...captainObj,
		};

		return {
			...state,
			hasUnsavedChanges: true,
			requestState: RequestState.IDLE,
			byRound: {
				...state.byRound,
				[roundID]: {
					...newTeam,
				},
			},
		};
	})
	.on(showPlayerModal, (state, playerID) => ({
		...state,
		showDetailsForPlayerID: playerID,
	}))
	.on(hidePlayerModal, (state) => ({
		...state,
		showDetailsForPlayerID: 0,
	}));
