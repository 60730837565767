import {isString, get} from "lodash";
import {SagaIterator} from "redux-saga";
import {put, call, select} from "typed-redux-saga";
import {ISagaAction} from "modules/types";
import {ApiError} from "modules/utils";
import {isLoggedIn} from "modules/selectors";
import {showGlobalError} from "modules/actions";
import {userLogoutSuccess} from "modules/actions/user";

type TPayload = ApiError | Error | string;

const defaultErrorHandler = (payload: TPayload) => {
	if (isString(payload)) {
		throw new Error(payload);
	}

	if (isString(payload.message)) {
		throw new Error(payload.message);
	}

	if (payload instanceof ApiError) {
		throw new Error(payload.message);
	}
};

const onError = {
	*[ApiError.AUTH_REQUIRED](error: TPayload): SagaIterator {
		if (yield* select(isLoggedIn)) {
			return yield* put(userLogoutSuccess());
		}

		yield* call(defaultErrorHandler, error);
	},
};

export const errorsManagerSaga = function* ({payload}: ISagaAction<TPayload>): SagaIterator {
	const error_code = get(payload, "code", 0);

	try {
		const onErrorHandler = get(onError, error_code, defaultErrorHandler);
		yield* call(onErrorHandler, payload);
	} catch (err) {
		yield* put(
			showGlobalError({
				message: get(err, "message", ""),
				code: error_code || 0,
			})
		);
	}
};
