// import {HTTPClient as HTTPClientCore, IRequestConfig} from "@fanhubmedia/fe-common-utils";
import {HTTPClient as HTTPClientCore, IRequestConfig} from "./HTTPClient";
import axios, {AxiosError} from "axios";
import {isObject, set, get} from "lodash";
import ApiError from "./ApiError";
import {API_URL, JSON_URL} from "modules/constants";
import {IApiResponse} from "modules/types";
import {INotificationBar, IUser} from "modules/reducers";
import {
	IContactPayload,
	IFaqItem,
	IHelpResponse,
	IUserLoginPayload,
	IUserRegisterPayload,
	IUserUpdatePayload,
	ILeague,
	IInviteToLeaguePayload,
	IShowLeaguesPayload,
	IMyLeague,
	IShowLeaguePayload,
	IUpdateLeaguePayload,
	ILeagueRankingsPayload,
	ILeagueRankingsResult,
	ILeagueMonthlyRankingsPayload,
	ISquad,
	IAuthCodePayload,
	ITeam,
	IIncomingPlayer,
	IRound,
	IUserPreregister,
	IReactivatePayload,
	IPlayerDisplayStats,
} from "modules/actions";
import {CANCEL} from "redux-saga";
import {
	ICreateLeaguePayload,
	IRankingsPayload,
	IRemoveUserFromLeaguePayload,
	IShowJoinedLeagueResult,
} from "modules/actions/leagues";

class HTTPClient extends HTTPClientCore {
	/**
	 * Overridden method adds CancelToken symbol, that allow redux-saga'
	 * "takeLatest" function to cancel any requests automatically.
	 * http://fe-common-utils.s3-website-eu-west-1.amazonaws.com/classes/httpclient.html
	 */
	public makeRequest<T>(config: IRequestConfig): Promise<T> {
		const source = axios.CancelToken.source();
		const request = super.makeRequest<T>({
			...config,
			cancelToken: source.token,
		});

		return set<Promise<T>>(request, CANCEL, () => source.cancel());
	}
}

const onCatchNetworkError = ({response, message = "Network error"}: AxiosError<ApiError>) => {
	const data = response?.data;
	const error = isObject(data)
		? data
		: {
				errors: [new ApiError(message)],
		  };

	return Promise.reject(error).catch((err) => ApiError.CHECK(err as IApiResponse));
};

const APIClient = new HTTPClient({
	baseURL: API_URL,
	withCredentials: true,
	onCatchNetworkError,
});

const JSONClient = new HTTPClient({
	baseURL: JSON_URL,
});

export type TUserResponse = IApiResponse<{user: IUser}>;
type TUserTeamNameResponse = IApiResponse<Record<string, unknown>>;
type TShowMyTeamResponse = IApiResponse<{team: ITeam}>;
type TShowMyTeamsResponse = IApiResponse<{total_registered_users: number; teams: ITeam[]}>;
type TCreateLeagueResponse = IApiResponse<{league: ILeague}>;
type TJoinLeagueResponse = IApiResponse<{league: IMyLeague}>;
type TShowMyLeaguesResponse = IApiResponse<{leagues: IMyLeague[]; nextPage: boolean}>;
type TLeagueRankingsResult = IApiResponse<ILeagueRankingsResult>;

interface IAutofillTeamPayload {
	lineup?: number[];
	captain?: number;
}

interface ISaveTeamPayload extends IAutofillTeamPayload {
	name?: string;
	roundID?: number;
}

export const Api = {
	JSON: {
		checksums: () => JSONClient.get<Record<string, string>>("checksums.json"),
		gameRules: () => JSONClient.get<IHelpResponse>("guidelines.json"),
		faq: () => JSONClient.get<IFaqItem[]>("faqs.json"),
		terms: () => JSONClient.get<IHelpResponse>("terms.json"),
		schedule: () => JSONClient.get<IHelpResponse>("schedule.json"),
		prizes: () => JSONClient.get<IHelpResponse>("prizes.json"),
		squads: () => JSONClient.get<ISquad[]>("squads.json"),
		players: () => JSONClient.get<IIncomingPlayer[]>("players.json"),
		playersPreseason: () => JSONClient.get<IIncomingPlayer[]>("players_preseason.json"),
		playerStats: () =>
			JSONClient.get<Record<string, IPlayerDisplayStats>>("stats/players_stats.json"),
		rounds: () => JSONClient.get<IRound[]>("rounds.json"),
		// squads: () => JSONClient.get<ISquad[]>("squads.json")
		notification_bar: () => JSONClient.get<INotificationBar>("notification_bar.json"),
	},
	Auth: {
		authCodeLogin: (params: IAuthCodePayload) =>
			APIClient.post<TUserResponse>("nrlaccount/login", params),
		login: (params: IUserLoginPayload) => APIClient.post<TUserResponse>("auth/login", params),
		register: (params: IUserRegisterPayload) =>
			APIClient.post<TUserResponse>("nrlaccount/register", params),
		logout: () => APIClient.post<IApiResponse>("auth/logout"),
		deactivate: () => APIClient.post<IApiResponse>("auth/deactivate_account"),
		preregister: (params: IUserPreregister) =>
			APIClient.post<TUserResponse>("preregistration", params),
	},
	FantasyLeague: {
		create: (params: ICreateLeaguePayload) =>
			APIClient.post<TCreateLeagueResponse>("league/create", params),
		update: ({id, ...params}: IUpdateLeaguePayload) =>
			APIClient.post<TCreateLeagueResponse>(`league/${id}/update`, params),
		showMy: (params: IShowLeaguesPayload) =>
			APIClient.get<TShowMyLeaguesResponse>("leagues", params),
		show: ({id}: IShowLeaguePayload) => APIClient.get<IApiResponse<IMyLeague>>(`/league/${id}`),
		showJoined: (params: IShowLeaguePayload) =>
			APIClient.get<IApiResponse<IShowJoinedLeagueResult>>(
				`league/${params.id}/users?page=${params.page || 1}`
			),
		leave: ({id}: IShowLeaguePayload) => APIClient.post<IApiResponse>(`${id}/leave`),
		remove: ({id, user_id}: IRemoveUserFromLeaguePayload) =>
			APIClient.post<IApiResponse>(`league/${id}/user/${user_id}`),
		showForJoin: (params: IShowLeaguesPayload) =>
			APIClient.get<TShowMyLeaguesResponse>("league/index", params),
		invite: ({id, ...params}: IInviteToLeaguePayload) =>
			APIClient.post<IApiResponse>(`league/${id}/invite`, params),
		joinToLeague: (code: string) => APIClient.post<TJoinLeagueResponse>(`league/${code}/join`),
		rankings: ({leagueID, roundID, ...params}: ILeagueRankingsPayload) => {
			const roundIDStr = roundID ? `/${roundID}` : "";

			return APIClient.get<TLeagueRankingsResult>(
				`league/${leagueID}/ladder${roundIDStr}`,
				params
			);
		},
		overallRankings: ({roundID, ...params}: IRankingsPayload) => {
			const roundIDStr = roundID === "Overall" ? "" : `/${roundID}`;

			return APIClient.get<TLeagueRankingsResult>(`leaderboard${roundIDStr}`, params);
		},
		leagueTableRankings: ({leagueID, roundID, ...params}: ILeagueRankingsPayload) => {
			const roundIDStr = roundID === "Overall" ? "" : `/${roundID}`;

			return APIClient.get<TLeagueRankingsResult>(
				`league/${leagueID}/ladder${roundIDStr}`,
				params
			);
		},
		monthRankings: ({leagueID, month, ...params}: ILeagueMonthlyRankingsPayload) =>
			APIClient.get<TLeagueRankingsResult>(
				`rankings/${leagueID}/month_rankings/${month}`,
				params
			),
	},
	FantasyTeam: {
		showMy: (roundID: number) => APIClient.get<TShowMyTeamsResponse>(`team/show_my`),
		save: (params: ISaveTeamPayload) =>
			APIClient.post<TShowMyTeamResponse>(`team/update/${get(params, "roundID", 0)}`, {
				lineup: params.lineup,
			}),
		autofill: (params: IAutofillTeamPayload) =>
			APIClient.post<TShowMyTeamResponse>("team/autopick", params),
		create: (params: ISaveTeamPayload) =>
			APIClient.post<TShowMyTeamResponse>("team/create", params),
	},
	User: {
		user: () => APIClient.get<TUserResponse>("user"),
		userTeamName: (params: IUserUpdatePayload) =>
			APIClient.post<TUserTeamNameResponse>("user/check_team_name", params),
		update: (params: IUserUpdatePayload) =>
			APIClient.post<TUserResponse>("user/update", params),
		reactivate: (params: IReactivatePayload) =>
			APIClient.post<TUserResponse>("user/recover", params),
	},
	Common: {
		contact: (params: IContactPayload) => APIClient.post<IApiResponse>("contact", params),
	},
};

export * from "./ApiError";

export default Api;
