import {get} from "lodash";
import {IPlayer, ISquad} from "modules/actions";
import {PlayerPositions} from "modules/enums";
import {ReactComponent as SuspendedIcon} from "assets/img/icon_suspended.svg";
import {ReactComponent as ByeIcon} from "assets/img/icon-bye.svg";
import {ReactComponent as EmergencyIcon} from "assets/img/icon-emergency.svg";
import {ReactComponent as InjuredIcon} from "assets/img/icon-injured.svg";
import {ReactComponent as NotSelectedIcon} from "assets/img/icon-not-selected.svg";
import {ReactComponent as SelectedIcon} from "assets/img/icon-selected.svg";
import {ReactComponent as UncertainIcon} from "assets/img/icon-uncertain.svg";

const MID_FWD = "Middle Forward";
const EDG_FWD = "Edge Forward";

interface IPosition {
	id: number;
	name: string;
	short_name: string;
	plural: string;
	full_name: string;
}

interface IPositions {
	[id: number]: IPosition;
}

export const positionsByStringKey = {
	HOK: 1,
	MID: 2,
	EDG: 3,
	HLF: 4,
	CTR: 5,
	WFB: 6,
};

export const positions: IPositions = {
	[PlayerPositions.HOK]: {
		id: PlayerPositions.HOK,
		name: "Hooker",
		short_name: "HOK",
		plural: "Hooker",
		full_name: "Hooker",
	},
	[PlayerPositions.MID]: {
		id: PlayerPositions.MID,
		name: "Middle",
		short_name: "MID",
		plural: MID_FWD,
		full_name: MID_FWD,
	},
	[PlayerPositions.EDG]: {
		id: PlayerPositions.EDG,
		name: "Edge",
		short_name: "EDG",
		plural: EDG_FWD,
		full_name: EDG_FWD,
	},
	[PlayerPositions.HLF]: {
		id: PlayerPositions.HLF,
		name: "Halves",
		short_name: "HLF",
		plural: "Half",
		full_name: "Half",
	},
	[PlayerPositions.CTR]: {
		id: PlayerPositions.CTR,
		name: "Centres",
		short_name: "CTR",
		plural: "Centre",
		full_name: "Centre",
	},
	[PlayerPositions.WFB]: {
		id: PlayerPositions.WFB,
		name: "Wing / Fullbacks",
		short_name: "WFB",
		plural: "Wing / Full Back",
		full_name: "Wing / Fullback",
	},
};

export const positionIDs = Object.keys(positions).map((position) => Number(position));
export const positionArr = positionIDs.map((position) => positions[Number(position)]);

export const getPlayerPositionName = (playerPositions: number[]) => {
	let returnString = "";
	playerPositions.forEach((positionID, index) => {
		if (positionIDs.includes(positionID)) {
			const shortName = positions[positionID].short_name;
			if (index === 0) {
				returnString = shortName;
			} else {
				returnString += ` ${shortName}`;
			}
		}
	});
	return returnString;
};

interface ISquadMascot {
	[key: string]: string;
}

export const getSquadMascot = (squad: ISquad) => {
	const squads: ISquadMascot = {
		BRI: "Broncos",
		BRIW: "Broncos",
		CAN: "Raiders",
		CANW: "Raiders",
		CBY: "Bulldogs",
		CRO: "Sharks",
		CROW: "Sharks",
		GLD: "Titans",
		GCTW: "Titans",
		GCT: "Titans",
		MAN: "Sea Eagles",
		MEL: "Storm",
		NEW: "Knights",
		NEWW: "Knights",
		NQL: "Cowboys",
		NQLW: "Cowboys",
		PAR: "Eels",
		PARW: "Eels",
		PEN: "Panthers",
		SGI: "Dragons",
		SOU: "Rabbitohs",
		STG: "Dragons",
		STGW: "Dragons",
		SYD: "Roosters",
		SYDW: "Roosters",
		WAR: "Warriors",
		WST: "Tigers",
		WSTW: "Tigers",
	};
	const squadMascot = squads[squad.code];
	return squadMascot ? squadMascot : squad.name;
};

export const getPlayerScore = (player: IPlayer | null, roundID: number) => {
	return get(player, `stats.roundScores.${roundID}`, 0) as number;
};

export const PlayerStatus = [
	"Selected",
	"Score",
	"T",
	"TS",
	"G",
	"FG",
	"TA",
	"LB",
	"LBA",
	"TCK",
	"TB",
	"MT",
	"OFH",
	"OFG",
	"ER",
	"FTF",
	"MG",
	"KM",
	"KD",
	"PC",
	"SB",
	"SO",
	"TOG",
	"FDO",
	"TO",
	"SAI",
	"EFIG",
];

interface IPlayerStatusValue {
	[key: string]: string;
}

export const PlayerStatusValue: IPlayerStatusValue = {
	Selected: "Selected %",
	Score: "Score",
	T: "Tries",
	TS: "Try Saves",
	G: "Goals",
	FG: "Field Goals",
	TA: "Try Assists",
	LB: "Line Breaks",
	LBA: "Line Break Assists",
	TCK: "Tackles",
	TB: "Tackle Breaks",
	MT: "Missed Tackles",
	OFH: "Hand Offloads",
	OFG: "Ground Offloads",
	ER: "Errors",
	FTF: "40/20/40's",
	MG: "Total Metres Gained",
	KM: "Kick Metres",
	KD: "Kicks Defused",
	PC: "Penalties Conceded",
	SB: "Sin-Bin",
	SO: "Send-Off",
	TOG: "Time On Ground",
	FDO: "Forced Drop Out",
	TO: "Turnovers",
	SAI: "Six Again Infringments",
	EFIG: "Escape From In Goal",
};

const playerStatusIconSrc = {
	uncertain: UncertainIcon,
	playing: SelectedIcon,
	"not-playing": NotSelectedIcon,
	injured: InjuredIcon,
	eliminated: ByeIcon,
	suspended: SuspendedIcon,
	reserve: EmergencyIcon,
	emergency: EmergencyIcon,
};

export const getPlayStatus = (player: IPlayer) =>
	playerStatusIconSrc[player?.status || "uncertain"];
