import React from "react";
import styled from "styled-components";

interface IPreloader {
	one?: string;
	two?: string;
	three?: string;
	four?: string;
}

interface IPreloaderContent {
	one: string;
	two: string;
	three: string;
	four: string;
}

const PreloaderWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const PreloaderContent = styled.div<IPreloaderContent>`
	display: inline-block;
	position: relative;
	width: 100px;
	height: 48px;
	border-radius: 5px;

	background: var(--background);

	@keyframes lds-ellipsis1 {
		0% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}

	@keyframes lds-ellipsis3 {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}

	@keyframes lds-ellipsis2 {
		0% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(24px, 0);
		}
	}

	div {
		position: absolute;
		top: 18px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: var(--yellowTint);
		animation-timing-function: cubic-bezier(0, 1, 1, 0);

		&:nth-child(1) {
			left: ${(props) => props.one};
			animation: lds-ellipsis1 0.6s infinite;
		}

		&:nth-child(2) {
			left: ${(props) => props.two};
			animation: lds-ellipsis2 0.6s infinite;
		}

		&:nth-child(3) {
			left: ${(props) => props.three};
			animation: lds-ellipsis2 0.6s infinite;
		}

		&:nth-child(4) {
			left: ${(props) => props.four};
			animation: lds-ellipsis3 0.6s infinite;
		}
	}
`;

export const Preloader: React.FC<IPreloader> = ({
	one = "20px",
	two = "20px",
	three = "44px",
	four = "68px",
}) => (
	<PreloaderWrapper>
		<PreloaderContent one={one} two={two} three={three} four={four}>
			<div />
			<div />
			<div />
			<div />
		</PreloaderContent>
	</PreloaderWrapper>
);

const Overlay = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
`;

export const PagePreloader: React.FC = () => (
	<Overlay>
		<Preloader />
	</Overlay>
);
