import axios, {
	AxiosError,
	AxiosInstance,
	AxiosInterceptorManager,
	AxiosRequestConfig,
	AxiosResponse,
} from "axios";

import {bindAll, isFunction, merge} from "lodash";

type RequestConfigOrNone = AxiosRequestConfig | undefined | null | false | void;

export type IRequestConfig = AxiosRequestConfig;

interface IHTTPClientConfig extends AxiosRequestConfig {
	readonly baseURL: string;
	readonly withCredentials?: boolean;
	readonly headers?: Record<string, string>;
	readonly paramsSerializer?: (params?: object) => string;

	/**
	 * Called before every request.
	 * This hook could be useful if you want perform additional work before request,
	 * or override request config.
	 */
	readonly onBeforeRequest?: (
		this: HTTPClient,
		requestConfig: AxiosRequestConfig
	) => RequestConfigOrNone | Promise<RequestConfigOrNone> | void;

	/**
	 * Called after every request.
	 * This hook could be useful if you want perform additional work after request,
	 * or override pure server response.
	 */
	readonly onAfterRequest?: (
		this: HTTPClient,
		response: AxiosResponse
	) => AxiosResponse | Promise<AxiosResponse> | void;

	/**
	 * Called after onAfterRequest hook.
	 * This hook could be useful if you want
	 * extract server response in some, specific way.
	 */
	readonly transformResponse?: (this: HTTPClient, response: AxiosResponse) => object | void;
	/**
	 * Called on network error.
	 * This hook could be useful if you want
	 * to catch and do smth with network error object.
	 */
	readonly onCatchNetworkError?: (this: HTTPClient, response: AxiosError) => object | void;
}

export class HTTPClient {
	private static getNormalizedNetworkError(networkError: AxiosError): {
		readonly code: number | undefined;
		readonly message: string;
		readonly statusText: string | undefined;
	} {
		const {message, response} = networkError;

		return {
			code: response ? response.status : 0,
			message,
			statusText: response ? response.statusText : "",
		};
	}

	private readonly config: IHTTPClientConfig;
	private readonly HttpClient: AxiosInstance;

	public get interceptors(): {
		readonly request: AxiosInterceptorManager<AxiosRequestConfig>;
		readonly response: AxiosInterceptorManager<AxiosResponse>;
	} {
		return this.HttpClient.interceptors;
	}

	constructor(config: IHTTPClientConfig) {
		this.config = config;

		bindAll(this, [
			"onBeforeRequest",
			"onAfterRequest",
			"transformResponse",
			"onCatchNetworkError",
		]);

		this.HttpClient = axios.create(this.config);
	}

	/**
	 * Override default config for current instance.
	 */
	public extendDefaults(config: AxiosRequestConfig): this {
		merge(this.HttpClient.defaults, config);

		return this;
	}

	/**
	 * Performs pure request without calling any hooks.
	 */
	public request<T = unknown>(requestConfig: AxiosRequestConfig): Promise<T> {
		return this.HttpClient.request(requestConfig);
	}

	/**
	 * Performs `get` http method with call of all existing hooks.
	 */
	public get<T = unknown>(
		url: string,
		params?: object,
		requestConfig?: AxiosRequestConfig
	): Promise<T> {
		return this.makeRequest({params, url, ...requestConfig});
	}

	/**
	 * Performs `delete` http method with call of all existing hooks.
	 */
	public delete<T = unknown>(
		url: string,
		params?: object,
		requestConfig?: AxiosRequestConfig
	): Promise<T> {
		return this.makeRequest({
			data: params,
			method: "delete",
			url,
			...requestConfig,
		});
	}

	/**
	 * Performs `post` http method with call of all existing hooks.
	 */
	public post<T = unknown>(
		url: string,
		params?: object,
		requestConfig?: AxiosRequestConfig
	): Promise<T> {
		return this.makeRequest({
			data: params,
			method: "post",
			url,
			...requestConfig,
		});
	}

	/**
	 * Performs `put` http method with call of all existing hooks.
	 */
	public put<T = unknown>(
		url: string,
		params?: object,
		requestConfig?: AxiosRequestConfig
	): Promise<T> {
		return this.makeRequest({
			data: params,
			method: "put",
			url,
			...requestConfig,
		});
	}

	/**
	 * Performs `put` http method with call of all existing hooks.
	 */
	public patch<T = unknown>(
		url: string,
		params?: object,
		requestConfig?: AxiosRequestConfig
	): Promise<T> {
		return this.makeRequest({
			data: params,
			method: "patch",
			url,
			...requestConfig,
		});
	}

	/**
	 * Performs request with call of all existing hooks.
	 */
	public async makeRequest<T = unknown>(requestConfig: AxiosRequestConfig): Promise<T> {
		const config = await this.onBeforeRequest(requestConfig);

		return (
			this.HttpClient.request({
				...requestConfig,
				...config,
			})
				// bound in constructor
				// eslint-disable-next-line @typescript-eslint/unbound-method
				.then(this.onAfterRequest)
				// eslint-disable-next-line @typescript-eslint/unbound-method
				.then(this.transformResponse)
				// eslint-disable-next-line @typescript-eslint/unbound-method
				.catch(this.onCatchNetworkError) as Promise<T>
		);
	}

	private async onBeforeRequest(requestConfig: AxiosRequestConfig): Promise<RequestConfigOrNone> {
		const {onBeforeRequest} = this.config;

		if (isFunction(onBeforeRequest)) {
			return onBeforeRequest.call(this, requestConfig);
		}
	}

	private async onAfterRequest(response: AxiosResponse): Promise<AxiosResponse> {
		const {onAfterRequest} = this.config;

		if (isFunction(onAfterRequest)) {
			return onAfterRequest.call(this, response) || response;
		}

		return response;
	}

	private transformResponse(response: AxiosResponse<unknown>) {
		const {transformResponse} = this.config;

		if (isFunction(transformResponse)) {
			return transformResponse.call(this, response) || response.data;
		}

		return response.data;
	}

	private onCatchNetworkError(networkError: AxiosError) {
		const error = HTTPClient.getNormalizedNetworkError(networkError);
		const {onCatchNetworkError} = this.config;

		if (isFunction(onCatchNetworkError)) {
			return onCatchNetworkError.call(this, networkError) || error;
		}

		return error;
	}
}
