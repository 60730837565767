import {IStore} from "modules/types/store";
import {createSelector} from "reselect";

export const getErrorState = (store: IStore) => store.error;
export const getErrorModalMessage = createSelector(getErrorState, ({message}) => message);

export const getModalStore = (store: IStore) => store.modals;
export const isInvitesModalVisible = createSelector(
	getModalStore,
	({inviteSuccessModal}) => inviteSuccessModal.isShow
);
export const isInvitesModalSuccess = createSelector(
	getModalStore,
	({inviteSuccessModal}) => inviteSuccessModal.success
);
export const getIsShowWelcomeModal = createSelector(
	getModalStore,
	({welcomeModal}) => welcomeModal.isShow
);
export const getIsOpenReactivateModal = createSelector(
	getModalStore,
	({showReactivateModal}) => showReactivateModal
);
export const getLastActiveElement = createSelector(
	getModalStore,
	({lastActiveElement}) => lastActiveElement
);
