import React, {Fragment, ReactNode} from "react";

interface IProps {
	children: ReactNode,
	when: boolean;
}

export const Exist: React.FC<IProps> = ({children, when}) => (
	<Fragment>{when ? children : null}</Fragment>
);
