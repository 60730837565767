import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

interface ITooltipWrap {
	label: string;
}

export const TooltipWrap: React.FC<ITooltipWrap> = ({label}) => (
	<Tooltip title={<Typography fontSize={14}>{label}</Typography>}>
		<InfoIcon fontSize="small" />
	</Tooltip>
);
