import media from "assets/css/media";
import React from "react";
import styled from "styled-components";

const SpeachBubbleOverlay = styled.div`
	height: 100vh;
	overflow-y: hidden;
	width: 100vw;
	background: rgba(0, 0, 0, 0.8);
	position: fixed;
	left: 0;
	top: 0;
	z-index: 19;
`;

const SpeachBubbleWrap = styled.div`
	position: absolute;
	background: #fff;
	border-radius: 0.4em;
	font-size: 14px;
	padding: 20px;
	width: 450px;
	margin-top: 44px;
	z-index: 20;
	right: -27px;
	@media (max-width: ${media.mobile}) {
		right: -15px;
		width: 320px;
	}
	h3 {
		font-size: 16px;
		font-weight: 500;
	}
	p {
		margin: 0 0 10px 0;
		line-height: 1.5em;
	}
	strong {
		font-weight: 500;
	}
	:after {
		content: "";
		position: absolute;
		top: 0;
		right: 6%;
		width: 0;
		height: 0;
		border: 11px solid transparent;
		border-bottom-color: #fff;
		border-top: 0;
		margin-left: -11px;
		margin-top: -11px;
	}
`;

interface ISpeechBubble {
	children: React.ReactNode;
	closeOverlay: () => void;
}

export const SpeechBubble: React.FC<ISpeechBubble> = ({children, closeOverlay}) => (
	<React.Fragment>
		<SpeachBubbleOverlay onClick={closeOverlay} />
		<SpeachBubbleWrap>{children}</SpeachBubbleWrap>
	</React.Fragment>
);
