import {createAction} from "redux-act";
import {LeaguePrivacy, LeagueStatus, LeagueType} from "modules/enums";
import {IError} from "modules/types";

export interface ICreateLeaguePayload {
	name: string;
	startRoundId?: number;
	privacy: LeaguePrivacy;
	description?: string;
}

export interface ILeague {
	code: string;
	id: number;
	isJoined: boolean;
	name: string;
	numTeams: number;
	privacy: LeaguePrivacy;
	startRoundId?: number;
	startRound?: number;
	status: LeagueStatus;
	type: LeagueType;
	userId: number;
	leagueManager?: string | null;
	overall_rank?: number | null;
}

export const createLeague = createAction<ICreateLeaguePayload>();
export const createLeagueSuccess = createAction<ILeague>();
export const createLeagueFailed = createAction<IError>();
export const clearTempLeague = createAction();

export type IUpdateLeaguePayload = Partial<ICreateLeaguePayload> & {
	id: number;
};

export const updateLeague = createAction<IUpdateLeaguePayload>();
export const updateLeagueSuccess = createAction<ILeague>();
export const updateLeagueFailed = createAction<IError>();
export const resetUpdateLeagueRequestState = createAction();

export interface IInviteToLeaguePayload {
	id: number;
	invites: {
		email: string;
	}[];
}

export interface IInviteToLeagueEmailPayload {
	league_id: number;
	invites: string[];
}

export const inviteToLeague = createAction<IInviteToLeaguePayload>();
export const inviteToLeagueSuccess = createAction();
export const inviteToLeagueFailed = createAction<IError>();
export const clearLeagueInvitesState = createAction();

export interface IShowBase {
	search?: string;
	page?: number;
	limit?: number;
	offset?: number;
}

export interface IShowLeaguesPayload extends IShowBase {
	eventId?: number;
}

export interface IShowJoinedPayload extends IShowBase {
	id: number;
}

export interface IMyLeague extends ILeague {
	rank: number | null;
	last_round_rank: number | null;
	is_joined?: boolean;
	is_sponsored?: boolean;
}

export const showMyLeagues = createAction<IShowLeaguesPayload>();
export const showMyLeaguesSuccess = createAction<{
	leagues: IMyLeague[];
	nextPage: boolean;
}>();
export const showMyLeaguesFailed = createAction<IError>();

export const showLeaguesForJoin = createAction<IShowLeaguesPayload>();
export const showLeaguesForJoinSuccess = createAction<{
	leagues: IMyLeague[];
	nextPage: boolean;
}>();
export const showLeaguesForJoinFailed = createAction<IError>();

export const loadMoreLeaguesForJoin = createAction<IShowLeaguesPayload>();
export const searchLeaguesForJoin = createAction<Pick<IShowLeaguesPayload, "search">>();

export const showJoined = createAction<IShowLeaguePayload>();
export const showJoinedSuccess = createAction<IShowJoinedLeagueResult>();
export const showJoinedFailed = createAction<IError>();
export const loadMoreShowJoined = createAction<IShowJoinedPayload>();

export const joinToLeague = createAction<string>();
export const joinToLeagueSuccess = createAction<IMyLeague>();
export const joinToLeagueFailed = createAction<IError>();
export const clearLeaguesForJoin = createAction();

export interface IShowLeaguePayload {
	id: number;
	page?: number;
}

export interface IRemoveUserFromLeaguePayload {
	id: number;
	user_id: number;
}

/*
export interface IShowLeagueResult {
	league: IMyLeague;
	isJoined: boolean;
}
*/

interface IUserInLeague {
	userId: number;
	firstName: string;
	lastName: string;
}
export interface IShowJoinedLeagueResult {
	users: IUserInLeague[];
	nextPage: boolean;
}

export const showLeague = createAction<IShowLeaguePayload>();
export const showLeagueSuccess = createAction<IMyLeague>();
export const showLeagueFailed = createAction<IError>();

export const leaveLeague = createAction<IShowLeaguePayload>();
export const leaveLeagueSuccess = createAction<number>();
export const leaveLeagueFailed = createAction<IError>();
export const resetLeaveLeagueRequestState = createAction();

export const removeFromLeague = createAction<IRemoveUserFromLeaguePayload>();
export const removeFromLeagueSuccess = createAction<number>();
export const removeFromLeagueFailed = createAction<IError>();
export const resetRemoveFromLeagueRequestState = createAction();

export interface ILeagueRankingsPayload {
	leagueID: number;
	roundID: number | string;
	page: number;
	/**
	 * Default value: 20
	 */
	limit?: number;
}

export interface IRankingsPayload {
	roundID: number | string;
	page: number;
	/**
	 * Default value: 20
	 */
	limit?: number;
}

export interface ILeagueRank {
	rank: number;
	userId: number;
	teamName: string;
	firstName: string;
	lastName: string;
	points: number;
	joining_event?: string;
	primary?: boolean;
}

export interface ILeagueRankingsResult {
	ranking: ILeagueRank[];
	nextPage: number | null;
	items: ILeagueRank[];
	currentUserRanking: ILeagueRank;
}

export interface IRankingsResult {
	nextPage: number | null;
	items: ILeagueRank[];
	currentUserRanking: ILeagueRank;
}

export const fetchLeagueRankings = createAction<ILeagueRankingsPayload>();
export const fetchMoreLeagueRankings = createAction<ILeagueRankingsPayload>();
export const fetchLeagueRankingsSuccess = createAction<ILeagueRankingsResult>();
export const fetchLeagueRankingsFailed = createAction<IError>();
export const clearLeagueRankings = createAction();

export const fetchRankings = createAction<IRankingsPayload>();
export const fetchMoreRankings = createAction<IRankingsPayload>();
export const fetchRankingsSuccess = createAction<IRankingsResult>();
export const fetchRankingsFailed = createAction<IError>();
export const clearRankings = createAction();

export const fetchLeagueTableRankings = createAction<ILeagueRankingsPayload>();
export const fetchMoreLeagueTableRankings = createAction<ILeagueRankingsPayload>();
export const fetchLeagueTableRankingsSuccess = createAction<ILeagueRankingsResult>();
export const fetchLeagueTableRankingsFailed = createAction<IError>();
export const clearLeagueTableRankings = createAction();

export interface ILeagueMonthlyRankingsPayload extends Omit<ILeagueRankingsPayload, "eventID"> {
	month: number;
}

export const fetchLeagueMonthlyRankings = createAction<ILeagueMonthlyRankingsPayload>();
export const fetchMoreLeagueMonthlyRankings = createAction<ILeagueMonthlyRankingsPayload>();
