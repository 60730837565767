import React, {Fragment} from "react";
import styled from "styled-components";
import {ButtonPrimary} from "components/Buttons";
import {Exist} from "components/Exist";
import {useMedia} from "react-use";
import {ShareType, SocialNetwork} from "modules";
import {share} from "modules/utils";
import {useSelector} from "react-redux";
import {getPlayersByID, getFlatPlayerIDs, getUser, getViewedRound} from "modules/selectors";
import {ReactComponent as Facebook} from "assets/img/icons/facebook.svg";
import {ReactComponent as Twitter} from "assets/img/icon-twitter.svg";
import media from "assets/css/media";

const ShareButtonsBox = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
	gap: 20px;
	@media (max-width: ${media.mobile}) {
		margin-right: 10px;
		gap: 15px;
	}
`;

const BaseShareButton = styled(ButtonPrimary)`
	--btnBackground: var(--textColor1);
	--btnHoverTextColor: var(--textColor1);
	--btnHoverBackground: var(--yellowTint);
	--btnDisabledBackground: var(--disabledGrey);
	svg {
		path {
			fill: var(--yellowTint);
		}
	}
	@media (max-width: ${media.mobile}) {
		width: 40px !important;
		height: 40px;
		padding: 10px;
		svg {
			height: 100%;
			width: 100%;
		}
	}
`;

const ButtonFB = styled(BaseShareButton)``;

const ButtonTW = styled(BaseShareButton)``;

const ButtonShare = styled(BaseShareButton)`
	width: 100%;
	max-width: 320px;
	display: flex;
	margin: 0 auto;
	@media (max-width: ${media.mobile}) {
		width: 80px !important;
	}
`;

const ShareOnText = styled.p`
	font-family: "RL2";
	font-size: 16px;
	color: var(--textColor1);
	font-weight: 500;
	margin-right: 10px;
`;

const StyledTwitter = styled(Twitter)`
	height: 18px;
	width: 18px;
`;

export const ShareButtons = () => {
	const lineup = useSelector(getFlatPlayerIDs);
	const isDisableShare = !lineup?.length;
	const playersByID = useSelector(getPlayersByID);
	const userID = useSelector(getUser)?.id || 0;
	const round = useSelector(getViewedRound);

	const getPlayerNameStrings = () => {
		let namesStr = "";
		const lineupLength = lineup.length;
		lineup.forEach((playerID, index) => {
			const player = playersByID[playerID];
			const isLastPlayer = lineupLength === index + 1 && lineupLength !== 1;
			const prefix = isLastPlayer ? "& " : "";
			const suffix = isLastPlayer ? "" : ", ";
			const nameString = `${prefix}${player.firstName} ${player.lastName}${suffix}`;
			namesStr += nameString;
		});
		return namesStr;
	};

	const isMobile = useMedia("(max-width: 800px)");
	const isSupportMobileShare = "share" in navigator;
	const isMobileShareVisible = isMobile && isSupportMobileShare;
	// const playerList = [];

	const shareMessage = `My Bundy Mixer Team for Round ${round.id}: ${getPlayerNameStrings()}`;
	const handleFBShare = React.useCallback(() => {
		share({
			socialNetwork: SocialNetwork.Facebook,
			type: ShareType.General,
			user_id: userID,
			message: shareMessage,
			round_id: round.id,
		});
	}, [userID, shareMessage, round.id]);

	const handleTWShare = React.useCallback(() => {
		share({
			socialNetwork: SocialNetwork.Twitter,
			type: ShareType.General,
			message: shareMessage,
			user_id: userID,
			round_id: round.id,
		});
	}, [shareMessage, userID, round.id]);

	const handleShare = React.useCallback(() => {
		share({
			socialNetwork: SocialNetwork.Mobile,
			type: ShareType.General,
			message: shareMessage,
			user_id: userID,
			round_id: round.id,
		});
	}, [shareMessage, userID, round.id]);

	return (
		<Fragment>
			<Exist when={!isMobileShareVisible}>
				<ShareButtonsBox>
					<Exist when={!isMobile}>
						<ShareOnText>SHARE ON</ShareOnText>
					</Exist>
					<ButtonFB onClick={handleFBShare} disabled={isDisableShare}>
						{isMobile ? <Facebook /> : "Facebook"}
					</ButtonFB>
					<ButtonTW onClick={handleTWShare} disabled={isDisableShare}>
						{/* {isMobile ? <StyledTwitter /> : "X"} */}
						<StyledTwitter />
					</ButtonTW>
				</ShareButtonsBox>
			</Exist>
			<Exist when={isMobileShareVisible}>
				<ButtonShare onClick={handleShare} disabled={isDisableShare}>
					Share
				</ButtonShare>
			</Exist>
		</Fragment>
	);
};
