import {IStore} from "modules/types/store";
import {createSelector} from "reselect";
import {keyBy} from "lodash";
import {LeaguePrivacy} from "modules/enums";

export const getLeaguesState = ({leagues}: IStore) => {
	return leagues;
};

export const getTempCreatedLeague = createSelector(
	getLeaguesState,
	({tempCreatedLeague}) => tempCreatedLeague
);

export const getLeagueInvitesRequestState = createSelector(
	getLeaguesState,
	({leagueInvitesRequestState}) => leagueInvitesRequestState
);

export const getMyLeagues = createSelector(getLeaguesState, ({myLeagues}) => myLeagues);
export const getMyPublicLeagues = createSelector(getLeaguesState, ({myLeagues}) => ({
	...myLeagues,
	list: myLeagues.list.filter((league) => league.privacy === LeaguePrivacy.Public),
}));
export const getMyPrivateLeagues = createSelector(getLeaguesState, ({myLeagues}) => ({
	...myLeagues,
	list: myLeagues.list.filter((league) => league.privacy === LeaguePrivacy.Private),
}));

export const getLeaguesForJoin = createSelector(
	getLeaguesState,
	({leaguesForJoin}) => leaguesForJoin
);

export const getLeagueRankings = createSelector(
	getLeaguesState,
	({leagueRankings}) => leagueRankings
);

export const getOverallRankings = createSelector(getLeaguesState, ({overallRankings}) => {
	return overallRankings;
});

export const getLeagueTableRankings = createSelector(
	getLeaguesState,
	({leagueTableRankings}) => leagueTableRankings
);

export const getMyLeaguesByID = createSelector(getMyLeagues, (leagues) =>
	keyBy(leagues.list, "id")
);

export const getLeagueByID = createSelector(
	getMyLeaguesByID,
	(leaguesById) =>
		(leagueID: number): typeof leaguesById[number] | undefined =>
			leaguesById[leagueID]
);

export const getIsJoinedToLeagueSelector = createSelector(
	getLeaguesState,
	({joinedLeaguesFlags}) =>
		(leagueID: number) =>
			joinedLeaguesFlags[leagueID]
);

export const getJoinedInLeague = createSelector(
	getLeaguesState,
	({joinedUsers}) => joinedUsers.users
);

export const getJoinedInLeagueRequestState = createSelector(
	getLeaguesState,
	({joinedUsers}) => joinedUsers.requestState
);

export const getRemoveFromLeagueRequestState = createSelector(
	getLeaguesState,
	({removeFromLeagueRequestState}) => removeFromLeagueRequestState
);

export const getJoinedInLeagueLoadMore = createSelector(
	getLeaguesState,
	({joinedUsers}) => joinedUsers.nextPage
);
