import React, {useEffect, ReactNode} from "react";
import {useDispatch, useSelector} from "react-redux";
import {subscribeToLiveScores, unsubscribeFromLiveScores} from "modules/actions";
import {getActualRound, isOneMatchStartingOrLiveSelector} from "modules/selectors";

interface IProps {
	children: ReactNode;
}

export const HOCLiveUpdates: React.FC<IProps> = ({children}) => {
	const dispatch = useDispatch();
	const actualRound = useSelector(getActualRound);
	const isMatchLive = useSelector(isOneMatchStartingOrLiveSelector)(actualRound);
	useEffect(() => {
		if (isMatchLive) {
			dispatch(subscribeToLiveScores());
		} else {
			dispatch(unsubscribeFromLiveScores());
		}

		return () => {
			dispatch(unsubscribeFromLiveScores());
		};
	}, [dispatch, isMatchLive]);

	return <React.Fragment>{children}</React.Fragment>;
};
