import {IStore} from "modules/types/store";
import {createSelector} from "reselect";
import {getSquadMatchStatus, getViewedRound} from "modules/selectors/rounds";
import {getPlayersByID} from "modules/selectors/jsons";
import {get, isEmpty} from "lodash";
import {BLANK_TEAM, getLineupIDs} from "modules/utils";
import {GenderType, MatchStatus, PlayerPositions} from "modules/enums";

export const getTeamState = ({team: {hasUnsavedChanges, requestState}}: IStore) => ({
	hasUnsavedChanges,
	requestState,
});

export const getOverallTeamNum = ({team: {total_registered_users}}: IStore) =>
	total_registered_users;

export const getSuccessTeamSaveFlag = ({team}: IStore) => team.showSuccessSaveModal;

export const getTeamsByRoundID = ({team}: IStore) => team.byRound;

export const getViewedTeam = createSelector(
	getTeamsByRoundID,
	getViewedRound,
	(teamsByRoundID, round): typeof teamsByRoundID[number] | null => {
		const roundID = Number(get(round, "id", 1));
		return teamsByRoundID[roundID] || null;
	}
);

export const getViewedTeamLineup = createSelector(getViewedTeam, (team) => {
	return team ? team.lineup : BLANK_TEAM;
});

const LINEUP_KEYS = [1, 2, 3, 4, 5, 6];

export const getFlatPlayerIDs = createSelector(getViewedTeamLineup, (lineup) => {
	const linupKeys = LINEUP_KEYS.map((item) => Number(item) as PlayerPositions);
	return linupKeys
		.map((key) => {
			return lineup[key][0];
		})
		.filter((value) => value !== 0);
});

export const isGenderAtMax = createSelector(
	getFlatPlayerIDs,
	getPlayersByID,
	(teamIDs, playersByID) => {
		let maleCount = 0;
		let femaleCount = 0;
		if (isEmpty(teamIDs) || isEmpty(playersByID)) {
			return null;
		}
		teamIDs.forEach((playerID) => {
			const player = playersByID[playerID];
			const isFemale = player?.gender === GenderType.FEMALE;
			if (isFemale) {
				femaleCount += 1;
			}
			if (!isFemale) {
				maleCount += 1;
			}
		});

		if (maleCount === 3 && femaleCount === 3) {
			return null;
		}

		if (maleCount === 3) {
			return GenderType.MALE;
		}
		if (femaleCount === 3) {
			return GenderType.FEMALE;
		}
		return null;
	}
);

export const getPickedPlayersSize = createSelector(getViewedTeam, (team) => {
	if (team?.lineup) {
		return getLineupIDs(team).length;
	}
	return 0;
});

export const getModalPlayerID = ({team}: IStore) => team.showDetailsForPlayerID;

export const getCaptainsFromRound = createSelector(getViewedTeam, (team) => {
	return {
		femaleCaptain: team?.captain_women,
		maleCaptain: team?.captain_men,
	};
});

export const getLockedPlayersFromTeam = createSelector(
	getFlatPlayerIDs,
	getPlayersByID,
	getSquadMatchStatus,
	getViewedRound,
	(teamIDs, playersByID, squadMatchStatus, round) => {
		const lockedPlayers = [] as number[];
		teamIDs.forEach((playerID: number) => {
			const player = playersByID[playerID];
			const matchStatus = squadMatchStatus(player?.squadId, round.id);
			if (matchStatus !== MatchStatus.Scheduled) {
				lockedPlayers.push(playerID);
			}
		});
		return lockedPlayers;
	}
);

export const getCreatedTeamRounds = ({team}: IStore) => team.roundsCreated;
