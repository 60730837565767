import React, {Fragment, useEffect, useRef} from "react";
import {CloseButton, Modal, ModalInner, Overlay} from "components/Modals/common";
import {Exist} from "components/Exist";

interface IBaseModal {
	handlerClose?: () => void;
	children: React.ReactNode;
	isSmall?: boolean;
	showCloseButton?: boolean;
}

export const BaseModal: React.FC<IBaseModal> = ({
	children,
	handlerClose,
	isSmall,
	showCloseButton = true,
}) => {
	const modalRef = useRef<HTMLElement>(null);

	useEffect(() => {
		const focusableModalElements = modalRef?.current?.querySelectorAll(
			'input[type="text"], button,a[href]'
		);

		const firstElement = focusableModalElements ? focusableModalElements[0] : null;
		const lastElement = focusableModalElements
			? focusableModalElements[focusableModalElements.length - 1]
			: null;

		if (firstElement && document.activeElement !== firstElement) {
			(firstElement as HTMLElement)?.focus();
		}

		const close = (ev: KeyboardEvent) => {
			if (ev.key === "Escape" || (ev.key === "Enter" && handlerClose)) {
				ev.stopPropagation();
				handlerClose && handlerClose();
			}
			if (ev.key === "Tab" && document.activeElement === lastElement) {
				(firstElement as HTMLElement)?.focus();
				ev.stopPropagation();
			}
		};

		window.addEventListener("keydown", close);
		return () => window.removeEventListener("keydown", close);
	}, [handlerClose]);

	return (
		<Fragment>
			<Overlay onClick={handlerClose} />
			<Modal
				isSmall={Boolean(isSmall)}
				ref={modalRef as React.RefObject<HTMLDivElement>}
				tabIndex={-1}
				role="dialog"
				aria-modal="true">
				<ModalInner tabIndex={0}>
					<Exist when={showCloseButton}>
						<CloseButton onClick={handlerClose} aria-label="Close" />
					</Exist>
					{children}
				</ModalInner>
			</Modal>
		</Fragment>
	);
};
