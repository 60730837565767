import React, {Fragment, useEffect, useRef} from "react";
import {CloseButton, WideModalWrap, WideModalInner, Overlay} from "components/Modals/common";

interface IBaseModal {
	handlerClose: () => void;
	children: React.ReactNode;
}

export const WideModal: React.FC<IBaseModal> = ({children, handlerClose}) => {
	const modalRef = useRef<HTMLElement>(null);

	useEffect(() => {
		const focusableModalElements = modalRef?.current?.querySelectorAll(
			'input[type="text"], button,a[href]'
		);

		const firstElement = focusableModalElements ? focusableModalElements[0] : null;
		const lastElement = focusableModalElements
			? focusableModalElements[focusableModalElements.length - 1]
			: null;

		if (firstElement && document.activeElement !== firstElement) {
			(firstElement as HTMLElement)?.focus();
		}

		const close = (ev: KeyboardEvent) => {
			if (ev.key === "Escape" || ev.key === " ") {
				ev.stopPropagation();
				handlerClose();
			}
			if (ev.key === "Tab" && document.activeElement === lastElement) {
				(firstElement as HTMLElement)?.focus();
				ev.stopPropagation();
			}
		};

		window.addEventListener("keydown", close);
		return () => window.removeEventListener("keydown", close);
	}, [handlerClose]);

	useEffect(() => {
		if (modalRef) {
			modalRef.current?.focus();
		}
	}, [modalRef]);

	return (
		<Fragment>
			<Overlay onClick={handlerClose} />
			<WideModalWrap
				ref={modalRef as React.RefObject<HTMLDivElement>}
				tabIndex={-1}
				role="dialog"
				aria-modal="true">
				<WideModalInner tabIndex={0}>
					<CloseButton onClick={handlerClose} aria-label="Close" />
					{children}
				</WideModalInner>
			</WideModalWrap>
		</Fragment>
	);
};
