import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";

import React, {Fragment, lazy, Suspense} from "react";
import {createRoot} from "react-dom/client";
import * as Sentry from "@sentry/react";
import reportWebVitals from "./reportWebVitals";
import {BASE_URL, IS_LEAGUE_MANAGE_DISABLED, IS_SECRET_PASSED} from "modules";
import {Provider} from "react-redux";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {
	ErrorModal,
	SessionGate,
	LandingLayout,
	MainLayout,
	NotAuthOnlyRoute,
	PrivateRoute,
	NoAdsLayout,
	NoAdsFullWidthLayout,
	NoAdsFullWidthLayoutSettings,
	HelpPagesLayout,
} from "components";
import {store} from "modules/store";
import {retryFailLoad} from "modules/utils";
import {ThemeProvider} from "@mui/material";
import {theme} from "assets/css/theme";

let errorCount = 0;
const MAX_ERRORS = 100;
const ignoreErrors = ["ResizeObserver loop limit exceeded", "Non-Error promise rejection captured"];
const IS_OFF_SEASON = (process.env.REACT_APP_IS_OFFSEASON || "") === "true";
const IS_GOD_MODE = localStorage.getItem("god_mode") === "true";
export const notOffSeasonMode = !IS_OFF_SEASON || IS_GOD_MODE;

Sentry.init({
	dsn: "https://9145387e13124e348cbb7874c6876c2a@o151969.ingest.sentry.io/6572234",
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	sampleRate: 0.1,
	allowUrls: [".f2p.media.geniussports.com", "mixer.nrl.com"],
	beforeSend: (event: Sentry.Event) => {
		errorCount++;
		if (errorCount > MAX_ERRORS) {
			return null;
		}
		if (event && event.message && ignoreErrors.includes(event.message)) {
			return null;
		}
		return event;
	},
	integrations: [
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	ignoreErrors,
	autoSessionTracking: true,
});

const Landing = lazy(retryFailLoad(() => import("pages/Landing")));
const LoginCallBack = lazy(retryFailLoad(() => import("pages/LoginCallBack")));
const Team = lazy(retryFailLoad(() => import("pages/Team")));
const NotFound = lazy(retryFailLoad(() => import("pages/NotFound")));
const SecreteGate = lazy(retryFailLoad(() => import("pages/SecreteGate")));
const Leaderboard = lazy(retryFailLoad(() => import("pages/Leaderboard")));
const Help = lazy(retryFailLoad(() => import("pages/Help")));
const Prizes = lazy(retryFailLoad(() => import("pages/Prizes")));
const Leagues = lazy(retryFailLoad(() => import("pages/Leagues")));
const AccountDetails = lazy(retryFailLoad(() => import("pages/AccountDetails")));
const CreateLeague = lazy(retryFailLoad(() => import("pages/leagues/CreateLeague")));
const JoinLeague = lazy(retryFailLoad(() => import("pages/leagues/JoinLeagues")));
const LeagueCore = lazy(retryFailLoad(() => import("pages/leagues/league/LeagueCore")));
const LeagueInvites = lazy(retryFailLoad(() => import("pages/leagues/league/LeagueInvites")));
const LeagueTable = lazy(retryFailLoad(() => import("pages/leagues/league/LeagueTable")));
const LeagueManage = lazy(retryFailLoad(() => import("pages/leagues/league/LeagueManage")));
const LeagueSettings = lazy(retryFailLoad(() => import("pages/leagues/league/LeagueSettings")));
const LeagueAbout = lazy(retryFailLoad(() => import("pages/leagues/league/LeagueAbout")));
const Offseason = lazy(retryFailLoad(() => import("pages/Offseason")));

const GameRoutes: React.FC = () => (
	<Routes>
		{notOffSeasonMode ? (
			<Fragment>
				<Route element={<NotAuthOnlyRoute />}>
					<Route element={<LandingLayout />}>
						<Route path="/" element={<Landing />} />
						<Route path="/login/callback" element={<LoginCallBack />} />
					</Route>
				</Route>
				<Route path="/*" element={<MainLayout />}>
					<Route element={<PrivateRoute />}>
						<Route path="team" element={<Team />} />
						<Route path="leagues">
							<Route index element={<Leagues />} />
							<Route path="join" element={<JoinLeague />} />
							<Route element={<NoAdsFullWidthLayout />}>
								<Route path="create" element={<CreateLeague />} />
							</Route>
							<Route element={<NoAdsLayout />}>
								<Route path=":id" element={<LeagueCore />}>
									<Route path="ladder" element={<LeagueTable />} />
									<Route path="invites" element={<LeagueInvites />} />
									{IS_LEAGUE_MANAGE_DISABLED ? null : (
										<Route path="manage" element={<LeagueManage />} />
									)}
									<Route path="about" element={<LeagueAbout />} />
								</Route>
							</Route>
							<Route element={<NoAdsFullWidthLayoutSettings />}>
								<Route path=":id" element={<LeagueCore />}>
									<Route path="settings" element={<LeagueSettings />} />
								</Route>
							</Route>
						</Route>
						<Route element={<NoAdsLayout />}>
							<Route path="leaderboard" element={<Leaderboard />} />
							<Route path="account" element={<AccountDetails />} />
						</Route>
						<Route path="*" element={<NotFound />} />
					</Route>
					<Route element={<HelpPagesLayout />}>
						<Route path="prizes" element={<Prizes />} />
						<Route path="help/*" element={<Help />} />
					</Route>
				</Route>
			</Fragment>
		) : (
			<Route element={<NotAuthOnlyRoute />}>
				<Route element={<LandingLayout />}>
					<Route path="/" element={<Offseason />} />
				</Route>
				<Route path="/*" element={<MainLayout />}>
					<Route element={<HelpPagesLayout />}>
						<Route path="prizes" element={<Prizes />} />
						<Route path="help/*" element={<Help />} />
					</Route>
					<Route path="*" element={<NotFound />} />
				</Route>
			</Route>
		)}
	</Routes>
);

const SecretGateRoutes: React.FC = () => (
	<Routes>
		<Route path="*" element={<SecreteGate />} />
	</Routes>
);

const container = document.getElementById("root") as Element;
const root = createRoot(container);

root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<SessionGate>
					<Router basename={BASE_URL}>
						<Suspense fallback={null}>
							{IS_SECRET_PASSED ? <GameRoutes /> : <SecretGateRoutes />}
						</Suspense>
					</Router>
				</SessionGate>
				<ErrorModal />
			</Provider>
		</ThemeProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
void reportWebVitals();
