import React from "react";
import styled from "styled-components";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {noop} from "lodash";
import {useMedia} from "react-use";

const SnackbarMessageWrap = styled.div`
	display: flex;
`;
const SnackbarMessageContent = styled.div`
	margin: 0 20px;
`;
const SnackbarMessageTitle = styled.div`
	font-weight: 700;
`;
const SnackbarMessageText = styled.div``;

interface ISnackbarWrap {
	open: boolean;
	setOpen: (val: boolean) => void;
	title: string;
	subTitle: string;
}

export const SnackbarWrap: React.FC<ISnackbarWrap> = ({title, subTitle, open, setOpen}) => {
	const isMobile = useMedia("(max-width: 768px)");
	const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
	};

	return (
		<React.Fragment>
			<Snackbar
				open={open}
				className="snackbar-wrapper"
				anchorOrigin={{vertical: "bottom", horizontal: "right"}}
				autoHideDuration={6000}
				style={isMobile ? {position: "sticky", bottom: "0px"} : {bottom: "175px"}}
				onClose={handleClose}>
				<SnackbarContent
					style={{
						backgroundColor: "#fff",
						color: "#000",
					}}
					message={
						<SnackbarMessageWrap>
							<IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={noop}>
								<CheckCircleIcon fontSize="small" />
							</IconButton>
							<SnackbarMessageContent>
								<SnackbarMessageTitle>{title}</SnackbarMessageTitle>
								<SnackbarMessageText>{subTitle}</SnackbarMessageText>
							</SnackbarMessageContent>
							<IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={handleClose}>
								<CloseIcon fontSize="small" />
							</IconButton>
						</SnackbarMessageWrap>
					}
				/>
			</Snackbar>
		</React.Fragment>
	);
};
