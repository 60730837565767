import React, {Fragment, useEffect, useRef} from "react";
import {Outlet} from "react-router-dom";
import styled from "styled-components";
import {Container as BaseContainer, ExternalLink, Main} from "components/Common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import logoLight from "assets/img/logoSquare.svg";
import {IS_SECRET_PASSED} from "modules/constants";
import {Footer} from "components/Footer";
import {Header, HeaderBlock, NavWrapper} from "components/Header";
import {AdRectangle, AdSquare} from "components";
import {PagePreloader} from "components/Preloader";
import {noop} from "lodash";
import {NotificationBar} from "components/NotificationBar";

const Wrapper = styled.div`
	box-sizing: border-box;
	padding: 60px 0;
	text-align: center;
`;

const Container = styled(BaseContainer)`
	display: flex;
	flex-flow: column;
	align-items: center;
`;

const Logo = styled.img`
	display: block;
	max-width: 100%;
	margin: 20px auto 50px;
	transform: translateX(-20px);
`;

const Title = styled.h1`
	max-width: 400px;
	font-size: 30px;
	margin: 0 auto 10px;
	color: #fff;
	line-height: 1.3;
`;

const BackLink = styled(ExternalLink)`
	position: absolute;
	left: 20px;
	top: 20px;
	display: inline-flex;
	align-items: center;

	span {
		display: inline-block;
		margin-left: 10px;
	}
`;

interface IProps {
	children?: React.ReactNode;
}

export const LandingCoreLayout: React.FC<IProps> = ({children}) => {
	const ref = useRef<HTMLSpanElement>(null);

	useEffect(() => {
		const element = ref.current;

		if (element && !IS_SECRET_PASSED) {
			let count = 0;

			element.addEventListener("click", () => {
				count++;

				if (count >= 10) {
					sessionStorage.setItem("isSecretPassed", "true");
					window.location.reload();
				}
			});
		}
	}, [ref]);

	return (
		<Wrapper>
			<BackLink target="_self" href="/">
				<FontAwesomeIcon icon="chevron-left" />
			</BackLink>
			<Container>
				<Logo src={logoLight} alt="" />
				<Title></Title>
				{children}
			</Container>
		</Wrapper>
	);
};

export const LandingLayout: React.FC = () => {
	return (
		<Fragment>
			<HeaderBlock isLanding={true} isMenuOpened={false} toggleMobileMenuState={noop} />
			<Outlet />
			<Footer />
		</Fragment>
	);
};

export const MainLayout: React.FC = () => {
	return (
		<Fragment>
			<Header />
			<Main>
				<Outlet />
			</Main>
			<Footer />
			<NotificationBar />
		</Fragment>
	);
};

export const MainLayoutSkeleton: React.FC = () => {
	return (
		<Fragment>
			<HeaderBlock isLanding={false} isMenuOpened={false} toggleMobileMenuState={noop} />
			<NavWrapper />
			<Main>
				<Container>
					<PagePreloader />
				</Container>
			</Main>
		</Fragment>
	);
};

const LayoutContent = styled.section`
	flex: 1;
	@media (max-width: 1000px) {
		width: 100%;
	}
`;

const Aside = styled.aside`
	width: 380px;
`;

const TwoColumnLayout = styled.div`
	display: flex;
	flex-flow: column;
	width: 100%;

	${Container} {
		flex-flow: row;
		align-items: flex-start;
		gap: 80px;
		margin-bottom: 20px;
	}

	@media (max-width: 1200px) {
		${Container} {
			gap: 20px;
		}
	}

	@media (max-width: 1000px) {
		${Container} {
			flex-direction: column;
		}
		${Aside} {
			margin: auto;
			width: 100%;
		}
	}
`;

export const HelpColumnLayout = styled.div`
	display: flex;
	flex-flow: column;
	width: 100%;

	${Container} {
		flex-flow: row;
		align-items: flex-start;
		gap: 80px;
		margin-bottom: 20px;
	}

	@media (max-width: 1200px) {
		${Container} {
			gap: 20px;
		}
	}

	@media (max-width: 1000px) {
		${Container} {
			flex-direction: column;
			padding: 0;
		}
		${Aside} {
			margin: auto;
			width: 100%;
		}
	}
`;

const AdLink = styled.a`
	img {
		width: 100%;
	}
`;
const shopURL = "/";

export const BannerAD: React.FC = () => (
	<AdRectangle>
		<AdLink target="_blank" href={shopURL + "_ads"}>
			<img src={"bannerAD"} alt="" role="banner" />
		</AdLink>
	</AdRectangle>
);

export const SquareAD: React.FC = () => (
	<AdSquare>
		<AdLink target="_blank" href={shopURL + "_GIF"}>
			<img src={"sideAD"} alt="" role="banner" />
		</AdLink>
	</AdSquare>
);

export const ThreeAdsLayout: React.FC = () => (
	<TwoColumnLayout>
		<BannerAD />
		<Container>
			<LayoutContent>
				<Outlet />
			</LayoutContent>
			<Aside>
				<SquareAD />
			</Aside>
		</Container>
		<BannerAD />
	</TwoColumnLayout>
);

export const TwoAdsLayout: React.FC = () => (
	<TwoColumnLayout>
		<BannerAD />
		<Container>
			<LayoutContent>
				<Outlet />
			</LayoutContent>
			<Aside>
				<SquareAD />
			</Aside>
		</Container>
	</TwoColumnLayout>
);

export const NoAdsLayout: React.FC = () => (
	<TwoColumnLayout>
		<Container>
			<LayoutContent>
				<Outlet />
			</LayoutContent>
			<Aside />
		</Container>
	</TwoColumnLayout>
);

export const HelpPagesLayout: React.FC = () => (
	<HelpColumnLayout>
		<Container>
			<LayoutContent>
				<Outlet />
			</LayoutContent>
			<Aside />
		</Container>
	</HelpColumnLayout>
);

export const NoAdsFullWidthLayout: React.FC = () => (
	<TwoColumnLayout>
		<Container>
			<LayoutContent>
				<Outlet />
			</LayoutContent>
		</Container>
	</TwoColumnLayout>
);

export const NoAdsFullWidthLayoutSettings: React.FC = () => (
	<TwoColumnLayout className="settings">
		<Container>
			<LayoutContent>
				<Outlet />
			</LayoutContent>
		</Container>
	</TwoColumnLayout>
);

interface ITwoColsLayoutWithContent {
	mainContent: React.ReactNode;
	rightContent: React.ReactNode;
}

export const TwoColsLayoutWithContent: React.FC<ITwoColsLayoutWithContent> = ({
	mainContent,
	rightContent,
}) => (
	<React.Fragment>
		<Container className="nopad">
			<TwoColumnLayout>
				<Container>
					<LayoutContent>{mainContent}</LayoutContent>
					<Aside>{rightContent}</Aside>
				</Container>
			</TwoColumnLayout>
		</Container>
	</React.Fragment>
);
