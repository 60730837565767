import {IStore} from "modules/types/store";
import {createSelector} from "reselect";
import {find, findIndex, first, get, isUndefined, keyBy, memoize} from "lodash";
import {IMatch, IRound} from "modules/actions";
import {MatchStatus, RoundStatus} from "modules/enums";
import {DateTime} from "luxon";

export const getRoundsState = ({rounds}: IStore) => {
	return rounds;
};

export const getSelectedRoundID = ({rounds}: IStore) => rounds.selectedRoundID;

export const getSelectedRankingRoundID = ({rounds}: IStore) => rounds.selectedRankingRoundID;

export const getSelectedLeagueRankingRoundID = ({rounds}: IStore) =>
	rounds.selectedLeagueRankingRoundID;

export const getRoundsArray = createSelector(getRoundsState, (rounds) => rounds.rounds);

export const getRoundsByID = createSelector(getRoundsArray, (rounds) => keyBy(rounds, "id"));

export const getSquadMatchStatus = createSelector(
	getRoundsByID,
	(roundsById) => (squadId: number, roundId: number) => {
		if (!roundsById[roundId]) {
			return MatchStatus.Scheduled;
		}
		const squadMatch = roundsById[roundId].units.find((match: IMatch) =>
			[match.homeSquadId, match.awaySquadId].includes(squadId)
		);
		if (squadMatch) {
			return squadMatch.status;
		}
		return MatchStatus.Scheduled;
	}
);

export const getRoundHasUnplayedMatches = createSelector(
	getRoundsByID,
	(roundsById) => (roundId: number) => {
		const unplayedMatches = roundsById[roundId].units.find((match: IMatch) =>
			[MatchStatus.Scheduled].includes(match.status)
		);
		return unplayedMatches ? true : false;
	}
);

export const getActualAndCompletedRounds = createSelector(getRoundsArray, (rounds) => {
	const firstScheduledOrActiveRound = rounds.find((round) =>
		[RoundStatus.Active, RoundStatus.Scheduled].includes(round.status)
	);
	const allCompletedRounds = rounds.filter((round) => round.status === RoundStatus.Complete);
	const returnArr = [...allCompletedRounds];
	if (firstScheduledOrActiveRound) {
		returnArr.push(firstScheduledOrActiveRound);
	}
	return returnArr;
});

export const getCompletedRounds = createSelector(getRoundsArray, (rounds) => {
	const allCompletedRounds = rounds.filter((round) => round.status === RoundStatus.Complete);
	return [...allCompletedRounds];
});

export const getUpcomingRounds = createSelector(getRoundsArray, (rounds) =>
	rounds.filter((round: IRound) => round.status === RoundStatus.Scheduled)
);

export const getActualRound = createSelector(getRoundsArray, (rounds) =>
	rounds.find(
		(round: IRound) =>
			round.status === RoundStatus.Active ||
			round.status === RoundStatus.Scheduled ||
			round.status === RoundStatus.Complete
	)
);
export const getFirstScheduledRound = createSelector(getUpcomingRounds, (rounds) => first(rounds));

export const getFirstNoMatchPlayedRound = createSelector(getRoundsArray, (rounds) => {
	const playingOrScheduledrounds = rounds.filter(
		(round: IRound) =>
			round.status === RoundStatus.Scheduled || round.status === RoundStatus.Active
	);
	const firstRound = first(playingOrScheduledrounds);
	if (firstRound?.units[0].status === MatchStatus.Scheduled) {
		return firstRound;
	}
	return playingOrScheduledrounds[1];
});

export const getFirstRoundWithAllUnplayed = createSelector(
	getRoundsArray,
	getFirstScheduledRound,
	(rounds, firstScheduledRound) => {
		const activeRound = rounds.find((round) => round.status === RoundStatus.Active);
		if (activeRound) {
			const hasAStartedMatch = activeRound.units.find((match) =>
				[MatchStatus.Scheduled, MatchStatus.Active].includes(match.status)
			);
			if (hasAStartedMatch) {
				return activeRound;
			}
			return firstScheduledRound;
		}
		return firstScheduledRound;
	}
);

export const getUpcomingRoundsIncActiveWithNoGame = createSelector(
	getRoundsArray,
	getFirstRoundWithAllUnplayed,
	(rounds, firstRoundWithAllUnplayed) => {
		const activeRoundID = get(firstRoundWithAllUnplayed, "id", 0);
		return rounds.filter((round) => round.id >= activeRoundID);
	}
);

// eg if round is active/playing and no matches are scheduled then it should be locked.
// 11:47
// But when round is marked as complete then look for the next one
export const getTradesDeadlineTime = createSelector(
	getRoundsArray,
	getFirstScheduledRound,
	(rounds, firstScheduledRound) => {
		const activeRound = rounds.find((round) => round.status === RoundStatus.Active);

		if (activeRound) {
			const firstScheduledMatch = activeRound.units.find(
				(match) => match.status === MatchStatus.Scheduled
			);
			if (firstScheduledMatch) {
				return new Date(firstScheduledMatch.date).valueOf();
			}
			return "locked";
		}

		if (!firstScheduledRound) {
			return "season end";
		}
		const firstScheduledMatch = firstScheduledRound.units.find(
			(match) => match.status === MatchStatus.Scheduled
		);

		if (firstScheduledMatch) {
			return new Date(firstScheduledMatch.date).valueOf();
		}
		return "locked";

		// const firstMatch = round.units.find((match) => match.status === "scheduled");

		// if (!firstMatch) {
		// 	return;
		// }

		// return new Date(firstMatch.date).valueOf();
	}
);

export const getViewedRound = createSelector(
	getRoundsArray,
	getActualRound,
	getSelectedRoundID,
	(rounds, actualRound, selectedRoundId) => {
		const viewedRoundId = selectedRoundId || get(actualRound, "id", 1);
		const viewedRoundIndex = findIndex(rounds, ({id}) => id === viewedRoundId);
		const viewedRound = rounds[viewedRoundIndex];

		if (!viewedRound) {
			return rounds[0];
		}

		return {
			...viewedRound,
			prevRound: rounds[viewedRoundIndex - 1] || null,
			nextRound: rounds[viewedRoundIndex + 1] || null,
		};
	}
);

export const getViewedRankingRound = createSelector(
	getRoundsArray,
	getActualRound,
	getSelectedRankingRoundID,
	(rounds, actualRound, selectedRankingRoundId) => {
		const overallOption = {id: "Overall", status: "complete"};
		const overallRankingRounds = [overallOption, ...rounds];
		const viewedRankingRoundId = selectedRankingRoundId || "Overall";
		const viewedRankingRoundIndex = findIndex(
			overallRankingRounds,
			({id}) => id === viewedRankingRoundId
		);
		const viewedRankingRound = overallRankingRounds[viewedRankingRoundIndex];

		if (!viewedRankingRound) {
			return overallOption;
		}

		return {
			...viewedRankingRound,
		};
	}
);

export const getViewedLeagueRankingRound = createSelector(
	getRoundsArray,
	getActualRound,
	getSelectedLeagueRankingRoundID,
	(rounds, actualRound, selectedLeagueRankingRoundId) => {
		const overallOption = {id: "Overall", status: "complete"};
		const overallRankingRounds = [overallOption, ...rounds];
		const viewedLeagueRankingRoundId = selectedLeagueRankingRoundId || "Overall";
		const viewedLeagueRankingRoundIndex = findIndex(
			overallRankingRounds,
			({id}) => id === viewedLeagueRankingRoundId
		);
		const viewedLeagueRankingRound = overallRankingRounds[viewedLeagueRankingRoundIndex];

		if (!viewedLeagueRankingRound) {
			return overallOption;
		}

		return {
			...viewedLeagueRankingRound,
		};
	}
);

export const isOneMatchStartingOrLiveSelector = () =>
	memoize((round?: IRound) => {
		if (!round) {
			return false;
		}
		const now = DateTime.now();
		const roundStart = DateTime.fromISO(round.startDate);
		const isRoundShouldStart = now.diff(roundStart, "minutes").minutes < 1;
		if (round.status === RoundStatus.Active || isRoundShouldStart) {
			return true;
		}
		const playingMatch = find(round.units, (tournament) => {
			const matchStart = DateTime.fromISO(tournament.date);
			return now.diff(matchStart, "minutes").minutes < 1;
		});

		return !isUndefined(playingMatch);
	});
