import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {requestCurrentUser} from "modules/actions";
import {isSessionChecked} from "modules/selectors";
import {MainLayoutSkeleton} from "components/Layouts";

interface IProps {
	children: React.ReactNode;
}

export const SessionGate: React.FC<IProps> = ({children}) => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(requestCurrentUser());
	}, [dispatch]);

	return useSelector(isSessionChecked) ? <Fragment>{children}</Fragment> : <MainLayoutSkeleton />;
};
