import styled, {css} from "styled-components";
import {NavLink} from "react-router-dom";
import React, {useCallback, useState} from "react";
import {BaseButton} from "components/Buttons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {IFaqItem} from "modules/actions";
import media from "assets/css/media";

export const Container = styled.div`
	max-width: 1200px;
	width: 100%;
	padding: 0;
	margin: 0 auto;
	box-sizing: border-box;

	@media (max-width: 1200px) {
		padding: 0 20px;
	}
`;

export const Main = styled.main`
	background: #f2f2f2;
	display: flex;
	flex: 1;
	padding-top: 20px;
`;

export const LandingContainer = styled(Container)`
	display: flex;
	justify-content: space-between;
	padding: 30px;
	flex: 1;
	text-align: center;

	@media (max-width: 840px) {
		flex-flow: column;
		padding: 30px 20px;
	}
`;

export const LandingContentBox = styled.div`
	max-width: 380px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
	justify-content: center;

	@media (max-width: 840px) {
		margin: 0 auto;
		justify-content: flex-end;
	}
`;

export const LandingParagraph = styled.p`
	font-size: 16px;
	line-height: 20px;
	font-weight: 300;

	&:not(:last-child) {
		margin-bottom: 30px;
	}
`;

export const LandingLogoLink = styled.div`
	align-self: flex-start;

	img {
		width: 160px;
	}

	@media (max-width: 580px) {
		img {
			width: 110px;
		}
	}
`;

export const GameLogoLink = styled.div`
	img {
		width: 300px;
		transform: translateX(-5px);
	}

	@media (max-width: ${media.tablet}) {
		img {
			margin-top: 5px;
		}
	}
`;

export const linkStyles = css`
	color: var(--textColor1);
	text-decoration: none;
	background-image: linear-gradient(currentColor, currentColor);
	background-position: 0 100%;
	background-repeat: no-repeat;
	transition: background-size 0.5s;
	background-size: 100% 1px;
	cursor: pointer;

	&:hover,
	&:focus {
		background-size: 0 1px;
	}
`;

export const ExternalLink = styled.a`
	${linkStyles};
`;

export const ExternalLinkSameWindow = styled.a`
	${linkStyles};
`;

ExternalLink.defaultProps = {
	target: "_blank",
	rel: "noreferrer noopener",
};

export const InternalLink = styled(NavLink)`
	${linkStyles};
`;

export const ContentNavItem = styled(NavLink)`
	color: #636366;
	width: 33%;
	font-size: 16px;
	font-family: var(--fontFamilyGotham);
	font-weight: 500;
	height: 40px;
	line-height: 40px;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&.active {
		color: var(--secondaryRed);
		border-bottom: 2px solid var(--secondaryRed);
	}

	@media (max-width: ${media.tablet}) {
		padding: 7px 12px;
		font-size: 14px;
		line-height: 16px;
	}
`;

export const PageTitle = styled.h2`
	font-size: 20px;
	line-height: 32px;
	font-family: var(--fontFamilyGotham);
	font-weight: 500;
	margin-bottom: 17px;

	@media (max-width: ${media.tablet}) {
		font-size: 20px;
		line-height: 22px;
	}
`;

export const HelpTitle = styled.h3`
	font-size: 18px;
	font-family: var(--fontFamilyGotham);
	line-height: 2em;
	margin-bottom: 0.625em;
	color: currentColor;
	font-weight: normal;

	@media (max-width: ${media.tablet}) {
		font-size: 1.125em;
		line-height: 1.25em;
	}
`;

export const HelpContent = styled.div`
	color: #0f0f19;
	margin: 15px 0;
	font-size: 100%;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: currentColor;
	}

	h4 {
		font-size: 1.125em;
		line-height: 1.75em;
	}

	h3 {
		font-size: 1.25em;
		line-height: 2em;
		margin-bottom: 0.625em;
	}

	li {
		line-height: 18px;
	}

	p,
	table,
	ul,
	ol {
		font-size: 14px;
		margin-bottom: 1em;

		@media (min-width: 640px) {
			margin-bottom: 2em;
		}
	}

	ol,
	ul {
		margin-left: 1em;
	}

	ul {
		list-style-type: circle;
	}

	ol {
		list-style-type: decimal;
	}

	i {
		font-style: italic;
	}

	b,
	strong {
		font-weight: 500;
	}

	a {
		${linkStyles};
	}

	p {
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 10px;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;

		@media (max-width: 639px) {
			width: 100%;
		}

		td {
			text-align: right;
			padding: 10px;
			border: 1px solid #eaeaea;
			background-color: #ffffff;

			&:first-child {
				text-align: left;
				text-transform: uppercase;
				font-weight: 700;
				border: none;
				background: none;
			}
		}
	}
`;

export const FaqTitle = styled(BaseButton)`
	color: currentColor;
	font-weight: 500;
	width: 100%;
	text-align: left;
	font-size: 16px;
	line-height: 32px;
	position: relative;
	padding-right: 50px;
	text-transform: capitalize;
	font-family: var(--fontFamilyGotham);
	letter-spacing: normal;
	.MuiSvgIcon-root {
		font-size: 30px;
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		transition: transform 0.5s;
	}

	@media (max-width: ${media.tablet}) {
		line-height: 24px;
	}
`;

export const FaqAccordion = styled.div`
	&:not(:last-child) {
		margin-bottom: 20px;
	}

	${HelpContent} {
		display: none;
		margin: 10px 0 0 0;
	}

	&.active {
		${HelpContent} {
			display: block;
		}

		.MuiSvgIcon-root {
			transform: translateY(-50%) rotate(180deg);
		}
	}
`;

export const FaqItem: React.FC<IFaqItem> = ({title, text}) => {
	const [isActive, setIsActive] = useState(false);
	const accordionClassName = isActive ? "active" : "";
	const toggleActiveState = useCallback(() => setIsActive(!isActive), [isActive]);

	return (
		<FaqAccordion className={accordionClassName}>
			<FaqTitle onClick={toggleActiveState}>
				{title}
				<KeyboardArrowDownIcon />
			</FaqTitle>
			{/*eslint-disable-next-line react/no-danger */}
			<HelpContent dangerouslySetInnerHTML={{__html: text}} />
		</FaqAccordion>
	);
};
