import React from "react";
import styled from "styled-components";

interface IPrivacyLabel {
	label: string;
}

const PrivacyLabelBase = styled.div`
	height: 24px;
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	display: inline-block;
	border-radius: 1px;
	width: 55px;
	text-align: center;
	margin: 0;
	text-transform: capitalize;
`;

const PrivateLabel = styled(PrivacyLabelBase)`
	background-color: var(--secondaryRed);
	color: var(--textColor2);
`;

const PublicLabel = styled(PrivacyLabelBase)`
	background-color: var(--baseBlack);
	color: var(--yellowTint);
`;

export const PrivacyLabel: React.FC<IPrivacyLabel> = ({label}) =>
	label === "private" ? <PrivateLabel>{label}</PrivateLabel> : <PublicLabel>{label}</PublicLabel>;
