import {createReducer} from "redux-act";
import {
	clearLeagueInvitesState,
	clearLeagueRankings,
	clearLeaguesForJoin,
	clearLeagueTableRankings,
	clearRankings,
	clearTempLeague,
	createLeague,
	createLeagueFailed,
	createLeagueSuccess,
	fetchLeagueMonthlyRankings,
	fetchLeagueRankings,
	fetchLeagueRankingsFailed,
	fetchLeagueRankingsSuccess,
	fetchLeagueTableRankings,
	fetchLeagueTableRankingsFailed,
	fetchLeagueTableRankingsSuccess,
	fetchMoreLeagueMonthlyRankings,
	fetchMoreLeagueRankings,
	fetchMoreLeagueTableRankings,
	fetchMoreRankings,
	fetchRankings,
	fetchRankingsFailed,
	fetchRankingsSuccess,
	ILeague,
	ILeagueRankingsResult,
	IMyLeague,
	inviteToLeague,
	inviteToLeagueFailed,
	inviteToLeagueSuccess,
	IRankingsResult,
	IShowJoinedLeagueResult,
	joinToLeague,
	joinToLeagueFailed,
	joinToLeagueSuccess,
	leaveLeague,
	leaveLeagueFailed,
	leaveLeagueSuccess,
	removeFromLeague,
	removeFromLeagueFailed,
	removeFromLeagueSuccess,
	resetLeaveLeagueRequestState,
	resetRemoveFromLeagueRequestState,
	resetUpdateLeagueRequestState,
	showJoined,
	showJoinedFailed,
	showJoinedSuccess,
	showLeague,
	showLeagueFailed,
	showLeaguesForJoin,
	showLeaguesForJoinFailed,
	showLeaguesForJoinSuccess,
	showLeagueSuccess,
	showMyLeagues,
	showMyLeaguesFailed,
	showMyLeaguesSuccess,
	updateLeague,
	updateLeagueFailed,
	updateLeagueSuccess,
} from "modules/actions";
import {RequestState} from "modules/enums";
import {isEqual} from "lodash";

export interface ILeaguesList {
	list: IMyLeague[];
	requestState: RequestState;
	nextPage: boolean;
}

interface IReducer {
	joinedLeaguesFlags: Record<number, boolean>;
	myLeagues: ILeaguesList;
	joinedUsers: IShowJoinedLeagueResult & {
		requestState: RequestState;
	};
	leaguesForJoin: ILeaguesList & {
		joinState: RequestState;
		navigateToLeagueID: number | null;
		leagueCode: string;
	};
	leagueRankings: ILeagueRankingsResult & {
		requestState: RequestState;
	};
	overallRankings: IRankingsResult & {
		requestState: RequestState;
	};
	leagueTableRankings: ILeagueRankingsResult & {
		requestState: RequestState;
	};
	leagueInvitesRequestState: RequestState;
	updateSettingsRequestState: RequestState;
	leaveLeagueRequestState: RequestState;
	removeFromLeagueRequestState: RequestState;
	tempCreatedLeague: Partial<ILeague> & {
		requestState: RequestState;
	};
}

const defaultState: IReducer = {
	leagueRankings: {
		requestState: RequestState.IDLE,
		ranking: [],
		nextPage: null,
		items: [],
		currentUserRanking: {
			rank: 0,
			userId: 0,
			teamName: "",
			firstName: "",
			lastName: "",
			points: 0,
		},
	},
	overallRankings: {
		requestState: RequestState.IDLE,
		nextPage: null,
		items: [],
		currentUserRanking: {
			rank: 0,
			userId: 0,
			teamName: "",
			firstName: "",
			lastName: "",
			points: 0,
		},
	},
	leagueTableRankings: {
		requestState: RequestState.IDLE,
		ranking: [],
		nextPage: null,
		items: [],
		currentUserRanking: {
			rank: 0,
			userId: 0,
			teamName: "",
			firstName: "",
			lastName: "",
			points: 0,
		},
	},
	joinedLeaguesFlags: {},
	joinedUsers: {
		users: [],
		nextPage: false,
		requestState: RequestState.IDLE,
	},
	myLeagues: {
		list: [],
		requestState: RequestState.IDLE,
		nextPage: false,
	},
	leaguesForJoin: {
		list: [],
		requestState: RequestState.IDLE,
		joinState: RequestState.IDLE,
		nextPage: false,
		navigateToLeagueID: null,
		leagueCode: "",
	},
	leagueInvitesRequestState: RequestState.IDLE,
	updateSettingsRequestState: RequestState.IDLE,
	leaveLeagueRequestState: RequestState.IDLE,
	removeFromLeagueRequestState: RequestState.IDLE,
	tempCreatedLeague: {
		requestState: RequestState.IDLE,
	},
};

const onLoadLeagueRequestStart = (state: IReducer) => ({
	...state,
	leaguesForJoin: {
		...state.leaguesForJoin,
		requestState: RequestState.Requested,
	},
});

const onJoinLeagueRequestStart = (state: IReducer, payload: string) => ({
	...state,
	leaguesForJoin: {
		...state.leaguesForJoin,
		joinState: RequestState.Requested,
		leagueCode: payload,
	},
});

const onJoinLeagueRequestEnd = (state: IReducer) => ({
	...state,
	leaguesForJoin: {
		...state.leaguesForJoin,
		joinState: RequestState.Received,
		leagueCode: "",
	},
});

const onRequestLeagueStart = (state: IReducer) => ({
	...state,
	myLeagues: {
		...state.myLeagues,
		requestState: RequestState.Requested,
	},
});

const onRequestLeagueEnd = (state: IReducer) => ({
	...state,
	myLeagues: {
		...state.myLeagues,
		requestState: RequestState.Received,
	},
});

const onRequestJoinedLeagueStart = (state: IReducer) => ({
	...state,
	joinedUsers: {
		...state.joinedUsers,
		requestState: RequestState.Requested,
	},
});

const onRequestJoinedLeagueEnd = (state: IReducer) => ({
	...state,
	joinedUsers: {
		...state.joinedUsers,
		requestState: RequestState.Received,
	},
});

const onRequstLeagueRankingsStart = (state: IReducer) => {
	state.leagueRankings.items = [];
	return {
		...state,
		leagueRankings: {
			...state.leagueRankings,
			requestState: RequestState.Requested,
		},
	};
};

const onRequstOverallRankingsStart = (state: IReducer) => ({
	...state,
	overallRankings: {
		...state.overallRankings,
		requestState: RequestState.Requested,
	},
});

const onRequstLeagueTableRankingsStart = (state: IReducer) => {
	return {
		...state,
		leagueTableRankings: {
			...state.leagueTableRankings,
			requestState: RequestState.Requested,
		},
	};
};

const mergeLeagues = (oldLeague: IMyLeague, newLeague: IMyLeague | ILeague): IMyLeague =>
	isEqual(oldLeague.id, newLeague.id)
		? {
				...oldLeague,
				...newLeague,
		  }
		: oldLeague;

export const leagues = createReducer<typeof defaultState>({}, defaultState)
	.on(clearTempLeague, (state) => ({
		...state,
		tempCreatedLeague: defaultState.tempCreatedLeague,
	}))
	.on(createLeague, (state) => ({
		...state,
		tempCreatedLeague: {
			...state.tempCreatedLeague,
			requestState: RequestState.Requested,
		},
	}))
	.on(createLeagueFailed, (state) => ({
		...state,
		tempCreatedLeague: {
			...state.tempCreatedLeague,
			requestState: RequestState.Received,
		},
	}))
	.on(createLeagueSuccess, (state, league) => ({
		...state,
		myLeagues: {
			...state.myLeagues,
			list: [
				{
					...league,
					rank: null,
					last_round_rank: null,
					leagueManager: null,
				},
				...state.myLeagues.list,
			],
		},
		tempCreatedLeague: {
			...state.tempCreatedLeague,
			requestState: RequestState.Received,
			...league,
		},
	}))
	.on(inviteToLeague, (state) => ({
		...state,
		leagueInvitesRequestState: RequestState.Requested,
	}))
	.on(inviteToLeagueSuccess, (state) => ({
		...state,
		leagueInvitesRequestState: RequestState.Received,
	}))
	.on(inviteToLeagueFailed, (state) => ({
		...state,
		leagueInvitesRequestState: RequestState.IDLE,
	}))
	.on(clearLeagueInvitesState, (state) => ({
		...state,
		leagueInvitesRequestState: RequestState.IDLE,
	}))
	.on(showMyLeagues, onRequestLeagueStart)
	.on(showMyLeaguesSuccess, (state, {leagues, nextPage}) => ({
		...state,
		myLeagues: {
			...state.myLeagues,
			list: leagues,
			nextPage,
			requestState: RequestState.Received,
		},
	}))
	.on(showMyLeaguesFailed, onRequestLeagueEnd)
	.on(showLeaguesForJoin, onLoadLeagueRequestStart)
	.on(showLeaguesForJoinSuccess, (state, {leagues, nextPage}) => ({
		...state,
		leaguesForJoin: {
			...state.leaguesForJoin,
			list: leagues,
			nextPage,
			requestState: RequestState.Received,
		},
	}))
	.on(showLeaguesForJoinFailed, onJoinLeagueRequestEnd)
	.on(joinToLeague, onJoinLeagueRequestStart)
	.on(joinToLeagueSuccess, (state, league) => ({
		...state,
		joinedLeaguesFlags: {
			...state.joinedLeaguesFlags,
			[league.id]: true,
		},
		myLeagues: {
			...state.myLeagues,
			list: [league, ...state.myLeagues.list],
		},
		leaguesForJoin: {
			...state.leaguesForJoin,
			navigateToLeagueID: league.id,
			joinState: RequestState.Received,
			leagueCode: "",
		},
	}))
	.on(joinToLeagueFailed, onJoinLeagueRequestEnd)
	.on(clearLeaguesForJoin, (state) => ({
		...state,
		leaguesForJoin: defaultState.leaguesForJoin,
	}))
	.on(showLeague, onRequestLeagueStart)
	.on(showLeagueSuccess, (state, league) => {
		const hasLeague = state.myLeagues.list.find(({id}) => id === league.id);
		const list = hasLeague
			? state.myLeagues.list.map((oldLeague) => mergeLeagues(oldLeague, league))
			: [league, ...state.myLeagues.list];
		return {
			...state,
			joinedLeaguesFlags: {
				...state.joinedLeaguesFlags,
				[league.id]: league.isJoined,
			},
			myLeagues: {
				...state.myLeagues,
				list,
				requestState: RequestState.Received,
			},
		};
	})
	.on(showLeagueFailed, onRequestLeagueEnd)
	.on(showJoined, onRequestJoinedLeagueStart)
	.on(showJoinedSuccess, (state, payload) => {
		return {
			...state,
			joinedUsers: {
				...state.joinedUsers,
				...payload,
				requestState: RequestState.Received,
			},
		};
	})
	.on(showJoinedFailed, onRequestJoinedLeagueEnd)
	.on(updateLeague, (state) => ({
		...state,
		updateSettingsRequestState: RequestState.Requested,
	}))
	.on(updateLeagueSuccess, (state, league) => ({
		...state,
		updateSettingsRequestState: RequestState.Received,
		myLeagues: {
			...state.myLeagues,
			list: state.myLeagues.list.map((oldLeague) => mergeLeagues(oldLeague, league)),
		},
	}))
	.on(updateLeagueFailed, (state) => ({
		...state,
		updateSettingsRequestState: RequestState.Received,
	}))
	.on(resetUpdateLeagueRequestState, (state) => ({
		...state,
		updateSettingsRequestState: RequestState.IDLE,
	}))
	.on(leaveLeague, (state) => ({
		...state,
		leaveLeagueRequestState: RequestState.Requested,
	}))
	.on(leaveLeagueSuccess, (state, leagueID) => ({
		...state,
		leaveLeagueRequestState: RequestState.Received,
		myLeagues: {
			...state.myLeagues,
			list: state.myLeagues.list.filter((league) => league.id !== leagueID),
		},
	}))
	.on(leaveLeagueFailed, (state) => ({
		...state,
		leaveLeagueRequestState: RequestState.IDLE,
	}))
	.on(resetLeaveLeagueRequestState, (state) => ({
		...state,
		leaveLeagueRequestState: RequestState.IDLE,
	}))
	.on(removeFromLeague, (state) => ({
		...state,
		removeFromLeagueRequestState: RequestState.Requested,
	}))
	.on(removeFromLeagueSuccess, (state, user_id) => ({
		...state,
		removeFromLeagueRequestState: RequestState.Received,
		joinedUsers: {
			...state.joinedUsers,
			users: state.joinedUsers.users.filter((user) => user.userId !== user_id),
		},
	}))
	.on(removeFromLeagueFailed, (state) => ({
		...state,
		removeFromLeagueRequestState: RequestState.IDLE,
	}))
	.on(resetRemoveFromLeagueRequestState, (state) => ({
		...state,
		removeFromLeagueRequestState: RequestState.IDLE,
	}))
	.on(fetchLeagueMonthlyRankings, onRequstLeagueRankingsStart)
	.on(fetchMoreLeagueMonthlyRankings, onRequstLeagueRankingsStart)
	.on(fetchLeagueRankings, onRequstLeagueRankingsStart)
	.on(fetchMoreLeagueRankings, onRequstLeagueRankingsStart)
	.on(fetchLeagueRankingsSuccess, (state, rankings) => ({
		...state,
		leagueRankings: {
			...state.leagueRankings,
			requestState: RequestState.Received,
			...rankings,
		},
	}))
	.on(fetchLeagueRankingsFailed, (state) => ({
		...state,
		leagueRankings: {
			...state.leagueRankings,
			requestState: RequestState.Received,
		},
	}))
	.on(clearLeagueRankings, (state) => ({
		...state,
		leagueRankings: defaultState.leagueRankings,
	}))
	.on(fetchRankings, onRequstOverallRankingsStart)
	.on(fetchMoreRankings, onRequstOverallRankingsStart)
	.on(fetchRankingsSuccess, (state, overallRankings) => ({
		...state,
		overallRankings: {
			...state.overallRankings,
			requestState: RequestState.Received,
			...overallRankings,
		},
	}))
	.on(fetchRankingsFailed, (state) => ({
		...state,
		overallRankings: {
			...state.overallRankings,
			requestState: RequestState.Received,
		},
	}))
	.on(clearRankings, (state) => ({
		...state,
		overallRankings: defaultState.overallRankings,
	}))
	.on(fetchLeagueTableRankings, onRequstLeagueTableRankingsStart)
	.on(fetchMoreLeagueTableRankings, onRequstLeagueTableRankingsStart)
	.on(fetchLeagueTableRankingsSuccess, (state, leagueTableRankings) => {
		return {
			...state,
			leagueTableRankings: {
				...state.leagueTableRankings,
				requestState: RequestState.Received,
				...leagueTableRankings,
			},
		};
	})
	.on(fetchLeagueTableRankingsFailed, (state) => ({
		...state,
		leagueTableRankings: {
			...state.leagueTableRankings,
			requestState: RequestState.Received,
		},
	}))
	.on(clearLeagueTableRankings, (state) => ({
		...state,
		leagueTableRankings: defaultState.leagueTableRankings,
	}));
