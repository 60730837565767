import {
	hideReactivateModal,
	markUserInfoAsUpdated,
	reactivateCurrentUserFailed,
	requestCurrentUserFailed,
	requestCurrentUserSuccess,
	showGlobalError,
	userAuthCodeFailed,
	userAuthCodeLogin,
	userAuthCodeSuccess,
	userDeactivateFailed,
	userDeactivateSuccess,
	userInfoUpdate,
	userLogoutFailed,
	userLogoutSuccess,
	userPreregister,
	userPreregisterFailed,
	userPreregisterSuccess,
	userRegister,
	userRegisterFailed,
	userRegisterSuccess,
	userTeamName,
	userTeamNameSuccess,
	userUpdate,
	userUpdateSuccess,
} from "modules/actions";
import {REACT_APP_LOGOUT_LINK} from "modules/constants";
import {Api, ApiError} from "modules/utils";
import {call, put} from "typed-redux-saga";

export const authCodeSaga = function* ({payload}: ReturnType<typeof userAuthCodeLogin>) {
	try {
		const response = yield* call(Api.Auth.authCodeLogin, payload);
		yield* put(userAuthCodeSuccess(response?.success?.user));
	} catch (err) {
		yield* put(userAuthCodeFailed(err as ApiError));
	}
};

export const registerSaga = function* ({payload}: ReturnType<typeof userRegister>) {
	try {
		const response = yield* call(Api.Auth.register, payload);
		const user = response.success.user;

		yield* put(userRegisterSuccess(user));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(userRegisterFailed(err));
		}
	}
};

export const userReactivateSaga = function* ({payload}: ReturnType<typeof hideReactivateModal>) {
	try {
		const response = yield* call(Api.User.reactivate, payload);
		const user = response.success.user;

		yield* put(userRegisterSuccess(user));
	} catch (err) {
		yield* put(reactivateCurrentUserFailed());
	}
};

export const deactivateUserSaga = function* () {
	try {
		yield* call(Api.Auth.deactivate);
		yield* put(userDeactivateSuccess());
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(userDeactivateFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const updateUserSaga = function* ({payload}: ReturnType<typeof userUpdate>) {
	try {
		const response = yield* call(Api.User.update, payload);
		yield* put(userUpdateSuccess(response.success.user));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
			yield* put(userRegisterFailed(err));
		}
	}
};

export const updateUserDetailsSaga = function* ({payload}: ReturnType<typeof userInfoUpdate>) {
	yield* call(updateUserSaga, userUpdate(payload));
	yield* put(markUserInfoAsUpdated());
};

export const logoutSaga = function* () {
	try {
		yield* call(Api.Auth.logout);
		yield* put(userLogoutSuccess());
		window.location.assign(REACT_APP_LOGOUT_LINK);
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(userLogoutFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const getCurrentUserSaga = function* () {
	try {
		const response = yield* call(Api.User.user);
		yield* put(requestCurrentUserSuccess(response.success.user));
	} catch (err) {
		yield* put(requestCurrentUserFailed());
	}
};

export const checkTeamNamerSaga = function* ({payload}: ReturnType<typeof userTeamName>) {
	try {
		const response = yield* call(Api.User.userTeamName, payload);
		yield* put(userTeamNameSuccess(response.success));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(userRegisterFailed(err));
		}
	}
};

export const preregisterSaga = function* ({payload}: ReturnType<typeof userPreregister>) {
	try {
		const response = yield* call(Api.Auth.preregister, payload);
		const {success} = response;
		if (success) {
			yield* put(userPreregisterSuccess());
		}
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(userPreregisterFailed(err));
		}
	}
};
