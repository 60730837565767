import styled from "styled-components";

const BaseButtonStyles = `
border: 0;
background: transparent;
appearance: none;
padding: 0;
margin: 0;

font-size: 18px;
text-transform: uppercase;
letter-spacing: 3.5px;
font-size: 14px;`;

export const BaseButton = styled.button`
	${BaseButtonStyles}
`;

const LinkButtonAltStyles = `
--btnBackground: var(--primaryYellow);
--btnHoverBackground: var(--yellowTint);
--disabledGrey: #989898;
color: var(--baseBlack);`;

const ButtonPrimaryStyles = `--btnTextColor: var(--yellowTint);
--btnHoverTextColor: var(--textColor1);
--btnDisabledTextColor: var(--textColor2);

--btnBackground: var(--baseBlack);
--btnHoverBackground: var(--yellowTint);
--btnDisabledBackground: var(--disabledGrey);

--btnBorderColor: var(--btnBackground);
--btnHoverBorderColor: var(--btnHoverBackground);
--btnDisabledBorderColor: var(--btnDisabledBackground);

--btnDisableOpacity: 0.5;

line-height: 18px;
background: var(--btnBackground);
border: 1px solid var(--btnBorderColor);
color: var(--btnTextColor);
padding: 0 15px;
display: inline-flex;
align-items: center;
justify-content: center;
overflow: hidden;
text-overflow: ellipsis;
height: 44px;
border-radius: 4px;
box-sizing: border-box;
transition: all ease 0.4s;
font-weight: 500;

&:hover:not(:disabled) {
	background: var(--btnHoverBackground);
	border-color: var(--btnHoverBorderColor);
	color: var(--btnHoverTextColor);
}

&:disabled {
	background: var(--btnDisabledBackground);
	border-color: var(--btnDisabledBorderColor);
	color: var(--btnDisabledTextColor);
	cursor: default;
	opacity: var(--btnDisableOpacity);
}`;

export const ButtonPrimary = styled(BaseButton)`
	${ButtonPrimaryStyles};
`;

export const LinkButton = styled.a`
	${BaseButtonStyles};
	${ButtonPrimaryStyles};
`;

export const LinkButtonAlt = styled.a`
	${BaseButtonStyles};
	${ButtonPrimaryStyles};
	${LinkButtonAltStyles};
`;

export const ExternalLinkButton = styled.a`
	${BaseButtonStyles};
	${ButtonPrimaryStyles};
`;

ExternalLinkButton.defaultProps = {
	target: "_blank",
	rel: "noreferrer noopener",
};

export const ButtonPrimaryAlt = styled(ButtonPrimary)`
	${LinkButtonAltStyles}
`;

export const ButtonSecondary = styled(ButtonPrimary)`
	--btnTextColor: var(--baseBlack);
	--btnHoverTextColor: var(--yellowTint);
	--btnDisabledTextColor: var(--disabledGrey);
	--btnBackground: transparent;
	--btnHoverBackground: transparent;
	--btnHoverBorderColor: var(--yellowTint);
	--btnDisabledBackground: transparent;
	--btnBorderColor: var(--primaryYellow);
	--btnDisableOpacity: 0.25;
	font-size: 14px;
	font-weight: 500;
`;

export const ButtonSecondaryAlt = styled(ButtonSecondary)`
	--btnTextColor: #3230be;
	--btnHoverTextColor: #fff;
	--btnHoverBackground: #3230be;
	--btnDisabledBackground: transparent;
	--btnBorderColor: #3230be;
	--btnDisableOpacity: 0.4;
	--btnDisabledBorderColor: #3230be;
	--btnDisabledTextColor: #3230be;
`;

export const ButtonApproval = styled(ButtonPrimary)`
	--btnBackground: #00e68c;
	--btnHoverBackground: #41eba8;
	--btnDisabledBackground: var(--btnHoverBackground);
	--btnDisableOpacity: 0.4;
`;

export const ButtonWarning = styled(ButtonPrimary)`
	color: #fff;
	--btnBackground: #d0011b;
	--btnHoverTextColor: #d0011b;
	--btnHoverBorderColor: #d0011b;
	--btnHoverBackground: #fff;
	--btnDisabledBackground: var(--btnHoverBackground);
	--btnDisableOpacity: 0.4;
`;
