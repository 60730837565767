import {
	fetchFaqFailed,
	fetchFaqSuccess,
	fetchGameRulesFailed,
	fetchGameRulesSuccess,
	fetchNotificationBarFailed,
	fetchNotificationBarSuccess,
	fetchPlayersFailed,
	fetchPlayersPreseasonFailed,
	fetchPlayersPreseasonSuccess,
	fetchPlayersSuccess,
	fetchPlayerStatsFailed,
	fetchPlayerStatsSuccess,
	fetchPrizesFailed,
	fetchPrizesSuccess,
	fetchRoundsFailed,
	fetchRoundsSuccess,
	fetchScheduleFailed,
	fetchScheduleSuccess,
	fetchSquadsFailed,
	fetchSquadsSuccess,
	fetchTermsFailed,
	fetchTermsSuccess,
} from "modules/actions";
import {call, put, select} from "typed-redux-saga";
import {Api} from "modules/utils";
import {getFaq, getGameRules, getPrizes, getSchedule, getTerms} from "modules/selectors";
import {fetchChecksumsFailed, fetchChecksumsSuccess} from "modules/actions/checksums";

export const requestTermsJSONSaga = function* () {
	try {
		const {value} = yield* select(getTerms);

		if (value) {
			yield* put(fetchTermsSuccess(value));
			return;
		}

		const result = yield* call(Api.JSON.terms);

		yield* put(fetchTermsSuccess(result));
	} catch (err) {
		yield* put(fetchTermsFailed());
	}
};

export const requestScheduleJSONSaga = function* () {
	try {
		const {value} = yield* select(getSchedule);

		if (value) {
			yield* put(fetchScheduleSuccess(value));
			return;
		}

		const result = yield* call(Api.JSON.schedule);

		yield* put(fetchScheduleSuccess(result));
	} catch (err) {
		yield* put(fetchScheduleFailed());
	}
};

export const requestGameRulesJSONSaga = function* () {
	try {
		const {value} = yield* select(getGameRules);

		if (value) {
			yield* put(fetchGameRulesSuccess(value));
			return;
		}

		const result = yield* call(Api.JSON.gameRules);

		yield* put(fetchGameRulesSuccess(result));
	} catch (err) {
		yield* put(fetchGameRulesFailed());
	}
};

export const requestFaqJSONSaga = function* () {
	try {
		const {value} = yield* select(getFaq);

		if (value) {
			yield* put(fetchFaqSuccess(value));
			return;
		}

		const result = yield* call(Api.JSON.faq);

		yield* put(fetchFaqSuccess(result));
	} catch (err) {
		yield* put(fetchFaqFailed());
	}
};

export const requestPrizesJSONSaga = function* () {
	try {
		const {value} = yield* select(getPrizes);

		if (value) {
			yield* put(fetchPrizesSuccess(value));
			return;
		}

		const result = yield* call(Api.JSON.prizes);

		yield* put(fetchPrizesSuccess(result));
	} catch (err) {
		yield* put(fetchPrizesFailed());
	}
};

export const requestSquadsJSONSaga = function* () {
	try {
		const result = yield* call(Api.JSON.squads);
		yield* put(fetchSquadsSuccess(result));
	} catch (err) {
		yield* put(fetchSquadsFailed());
	}
};

export const requestPlayersJSONSaga = function* () {
	try {
		const result = yield* call(Api.JSON.players);
		yield* put(fetchPlayersSuccess(result));
	} catch (err) {
		yield* put(fetchPlayersFailed());
	}
};

export const requestPlayersPreseasonJSONSaga = function* () {
	try {
		const result = yield* call(Api.JSON.playersPreseason);
		yield* put(fetchPlayersPreseasonSuccess(result));
	} catch (err) {
		yield* put(fetchPlayersPreseasonFailed());
	}
};

export const requestPlayerStatsJSONSaga = function* () {
	try {
		const result = yield* call(Api.JSON.playerStats);
		yield* put(fetchPlayerStatsSuccess(result));
	} catch (err) {
		yield* put(fetchPlayerStatsFailed());
	}
};

export const fetchNotificationBarSaga = function* () {
	try {
		const result = yield* call(Api.JSON.notification_bar);
		yield* put(fetchNotificationBarSuccess(result));
	} catch (err) {
		yield* put(fetchNotificationBarFailed(err));
	}
};

export const requestRoundsJSONSaga = function* () {
	try {
		const result = yield* call(Api.JSON.rounds);
		yield* put(fetchRoundsSuccess(result));
	} catch (err) {
		yield* put(fetchRoundsFailed());
	}
};

export const fetchChecksumsSaga = function* () {
	try {
		const response = yield* call(Api.JSON.checksums);
		yield put(fetchChecksumsSuccess(response));
	} catch (e) {
		yield put(fetchChecksumsFailed());
	}
};
