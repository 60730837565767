import {createReducer} from "redux-act";
import {
	fetchRoundsSuccess,
	IRound,
	setViewedLeagueRankingRoundID,
	setViewedRankingRoundID,
	setViewedRoundID,
} from "modules/actions";
import {RoundStatus} from "modules/enums";
import {get} from "lodash";

interface IReducer {
	rounds: IRound[];
	selectedRoundID: null | number;
	selectedRankingRoundID: null | number | string;
	selectedLeagueRankingRoundID: null | number | string;
}

const defaultState: IReducer = {
	rounds: [],
	selectedRoundID: null,
	selectedRankingRoundID: null,
	selectedLeagueRankingRoundID: null,
};

export const rounds = createReducer<typeof defaultState>({}, defaultState)
	.on(setViewedRoundID, (state, selectedRoundID) => ({
		...state,
		selectedRoundID,
	}))
	.on(setViewedRankingRoundID, (state, selectedRankingRoundID) => {
		return {
			...state,
			selectedRankingRoundID,
		};
	})
	.on(setViewedLeagueRankingRoundID, (state, selectedLeagueRankingRoundID) => {
		return {
			...state,
			selectedLeagueRankingRoundID,
		};
	})
	.on(fetchRoundsSuccess, (state, payload) => {
		const actualRound = payload.find(
			(round) => round.status === RoundStatus.Active || round.status === RoundStatus.Scheduled
		);
		const initialSelected = get(actualRound, "id", null);
		return {
			...state,
			rounds: payload,
			selectedRoundID: initialSelected,
		};
	});
