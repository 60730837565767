import React, {useCallback} from "react";
import {ModalWindow} from "components/Modals/common";
import {useDispatch, useSelector} from "react-redux";
import {clearGlobalError} from "modules/actions";
import {getErrorModalMessage} from "modules/selectors";
import iconWarning from "assets/img/icon-warning.svg";
import iconBoosterRed from "assets/img/icon-booster-red.svg";

const BOOSTER_ERROR =
	"Please select one NRL & one NRLW player as booster to complete saving your team.";

export const ErrorModal: React.FC = () => {
	const dispatch = useDispatch();
	const errorMessage = useSelector(getErrorModalMessage);

	const isBoosterError = errorMessage === BOOSTER_ERROR;

	const errorTitle = isBoosterError ? "We do not want you to miss out on x2 points" : "Error";

	const errorIcon = isBoosterError ? iconBoosterRed : iconWarning;

	const handleClose = useCallback(() => {
		dispatch(clearGlobalError());
	}, [dispatch]);

	return (
		<ModalWindow
			title={errorTitle}
			onClose={handleClose}
			message={errorMessage}
			iconSrc={errorIcon}
		/>
	);
};
