export * from "./players";
export * from "./squads";

export const isAllTrue = (arr: boolean[]) => {
	let isTrue = true;
	arr.forEach((value) => {
		if (!value) {
			isTrue = false;
		}
	});
	return isTrue;
};

export const isAnyTrue = (arr: boolean[]) => {
	let isTrue = false;
	arr.forEach((value) => {
		if (value) {
			isTrue = true;
		}
	});
	return isTrue;
};

export const formatDecimal = (num: number, length: number) => {
	if (isNaN(num)) {
		return num;
	}
	return num.toFixed(length);
};
