import {identity, set} from "lodash";
import {SocialNetwork, ShareType} from "modules/enums";
import {SHARE_URL} from "modules/constants";

interface IGeneralShare {
	socialNetwork: SocialNetwork;
	type: ShareType.General;
	message?: string;
	user_id?: number;
	round_id?: number;
	userId?: number;
	roundId?: number;
}

interface ILeagueShare extends Omit<IGeneralShare, "type" | "userId"> {
	type: ShareType.League;
	round_id?: number;
	leagueId: number;
}

type IShare = IGeneralShare | ILeagueShare;

const getTimestamp = () => Date.now().toString().substring(9);

const getEncodedJson = (object: Partial<IShare> & {t: string}) =>
	window.btoa(JSON.stringify(object));

export const getLinkForShare = (data: IShare) => {
	const params = {t: getTimestamp()};

	if (data.type === ShareType.League) {
		set(params, "leagueId", data.leagueId);
	}
	if (data.type === ShareType.General) {
		return `${SHARE_URL}team/${getEncodedJson({
			...params,
			user_id: data.user_id,
			round_id: data.round_id,
		})}`;
	}

	return `${SHARE_URL}${data.type}/${getEncodedJson(params)}`;
};

const shareFB = (data: IShare) => {
	const isInvitesToLeague = data.type === ShareType.League;

	window.FB?.ui(
		{
			method: isInvitesToLeague ? "send" : "share",
			display: "popup",
			[isInvitesToLeague ? "link" : "href"]: getLinkForShare(data),
		},
		identity
	);
};

const shareTW = ({message = "", ...params}: IShare) => {
	window.open(
		"https://x.com/share?url=" +
			encodeURIComponent(getLinkForShare(params)) +
			"&text=" +
			encodeURIComponent(message),
		"twitter-share-dialog",
		"width=626,height=436"
	);
};

const shareMobile = ({message = "", ...params}: IShare) => {
	try {
		const title =
			params.type === ShareType.General
				? "I've picked my Bundy Mixer Team, have you?"
				: "You have been invited to join a league in the NRL Bundy Mixer";
		void navigator.share({
			url: getLinkForShare(params),
			text: message,
			title: title,
		});
	} catch (err) {
		console.log(err);
	}
};

const callbacks = {
	[SocialNetwork.Twitter]: shareTW,
	[SocialNetwork.Facebook]: shareFB,
	[SocialNetwork.Mobile]: shareMobile,
} as const;

export const share = (data: IShare) => callbacks[data.socialNetwork](data);
